import { callApi } from 'utils/service.util';

const PRODUCT_API_ENDPOINT = 'v1/offer/v1';

export const getOffers = async (params?: any) => {
  return callApi({
    method: 'get',
    url: PRODUCT_API_ENDPOINT,
    params,
  });
};

export const addOffer = async (_id: string, data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/productId/${_id}`,
    data,
  });
};

export const updateOffer = async (_id: string, data: any) => {
  return callApi({
    method: 'put',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
    data,
  });
};

export const getOffer = async (_id: any) => {
  return callApi({
    method: 'get',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
  });
};

export const deleteOffer = async (_id: any) => {
  return callApi({
    method: 'delete',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
  });
};
