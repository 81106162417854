import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  approveProduct,
  approveSectionProduct,
  deleteProduct,
  getProduct,
} from 'services/product.service';
import { getOffer } from 'services/product-offer.service';
import {
  pushErrorMessage,
  pushSuccessMessage,
} from 'store/modules/snackbar/snackbar.action';
import { PRODUCT_STATUS } from 'constants/common';

import {
  closeUpdatePopup,
  completeDelete,
  setData,
  updateData,
} from './product-item.action';
import {
  BeginDeleteDataAction,
  LoadDataAction,
  Types,
  UpdateSectionStatusAction,
  UpdateStatusAction,
} from './product-item.constant';
import { dataSelector } from './product-item.selector';

import { scanImages } from 'utils/form-data.util';

export function* watchGetProduct(productId: string) {
  // @ts-ignore
  return yield call(getProduct, productId);
}

export function* watchGetOffer(offerId?: string) {
  if (!offerId) {
    return {};
  }
  // @ts-ignore
  return yield call(getOffer, offerId);
}

function* watchLoadData(action: LoadDataAction) {
  try {
    // @ts-ignore
    const [data, offer]: any = yield all([
      call(watchGetProduct, action.payload.productId),
      call(watchGetOffer, action.payload.offerId),
    ]);

    scanImages({
      data,
      keys: [
        'productImages',
        'packaging_images',
        'sketching',
        'design_patent_documents',
        'product_marketing.product_marketing_image',
        'product_marketing.documents',
      ],
    });

    yield put(setData(data, offer));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData(null, null));
  }
}

function* watchDeleteData(action: BeginDeleteDataAction) {
  try {
    yield call(deleteProduct, action.payload.productId);

    yield put(pushSuccessMessage('Success!'));
    yield put(completeDelete());
    if (action.payload.fnCallback) {
      action.payload.fnCallback();
    }
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(completeDelete());
  }
}

function* watchUpdate(action: UpdateStatusAction) {
  try {
    const { data } = yield select(dataSelector);
    const { status, reason } = action.payload;
    let newData: any;
    if (status === PRODUCT_STATUS.Approve) {
      // @ts-ignore
      newData = yield call(
        approveProduct,
        data._id,
        PRODUCT_STATUS.Approve,
        reason
      );
    } else {
      // @ts-ignore
      newData = yield call(approveProduct, data._id, status);
    }

    scanImages({
      newData,
      keys: [
        'productImages',
        'packaging_images',
        'sketching',
        'design_patent_documents',
        'product_marketing.product_marketing_image',
        'product_marketing.documents',
      ],
    });

    yield put(updateData(newData));

    yield put(pushSuccessMessage('Success!'));
  } catch (error) {
    yield put(pushErrorMessage(error));
  }
  yield put(closeUpdatePopup());
}

function* watchUpdateSection(action: UpdateSectionStatusAction) {
  try {
    const { popupData, data } = yield select(dataSelector);
    const { status } = action.payload;

    // @ts-ignore
    const newData: any = yield call(approveSectionProduct, data._id, {
      [`status_${popupData.key}`]: status,
    });

    scanImages({
      newData,
      keys: [
        'productImages',
        'packaging_images',
        'sketching',
        'design_patent_documents',
        'product_marketing.product_marketing_image',
        'product_marketing.documents',
      ],
    });

    yield put(updateData(newData));

    yield put(pushSuccessMessage('Success!'));
  } catch (error) {
    yield put(pushErrorMessage(error));
  }
  yield put(closeUpdatePopup());
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.BEGIN_DELETE_DATA, watchDeleteData);
  yield takeLatest(Types.UPDATE_STATUS, watchUpdate);
  yield takeLatest(Types.UPDATE_SECTION_STATUS, watchUpdateSection);
}
