import { callApi } from 'utils/service.util';

const PRODUCT_API_ENDPOINT = 'v1/product-critical-report/v1';
const headers = {
  'Content-Type': 'multipart/form-data',
};

export const importExcel = async (
  data: any,
  controller: any,
  onUploadProgress: any
) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/import-excel`,
    data,
    signal: controller && controller.signal,
    onUploadProgress,
    headers,
  });
};

export const getReports = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/get-reports`,
    data,
  });
};

export const getStatisticSummary = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/get-statistic-summary`,
    data,
  });
};

export const getStatistic = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/get-statistic`,
    data,
  });
};

export const deleteReports = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/delete-reports`,
    data,
  });
};
