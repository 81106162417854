import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { IFeedbackListState } from './feedback-list.constant';

export const feedbackListSelector = (state: IState) => {
  return state.feedbackList;
};

export const paramsSelector = createSelector(
  feedbackListSelector,
  (feedbackList: IFeedbackListState): any => feedbackList.params
);

export const deletePopupSelector = createSelector(
  feedbackListSelector,
  (feedbackList: IFeedbackListState): any => ({
    deleting: feedbackList.deleting,
    data: feedbackList.selectedData,
    open: feedbackList.showDeletePopup,
  })
);
