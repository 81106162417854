import { combineReducers } from 'redux';

import auth from './modules/auth/auth.reducer';
import snackbars from './modules/snackbar/snackbar.reducer';
import layout from './modules/layout/layout.reducer';

import notification from './modules/notification/notification.reducer';
import branchList from './modules/branch-list/branch-list.reducer';
import productItem from './modules/product-item/product-item.reducer';
import productFile from './modules/product-file/product-file.reducer';
import productShare from './modules/product-share/product-share.reducer';
import productOfferList from './modules/product-offer-list/product-offer-list.reducer';
import productList from './modules/product-list/product-list.reducer';
import productCreate from './modules/product-create/product-create.reducer';
import productOffer from './modules/product-offer/product-offer.reducer';
import productMarketing from './modules/product-marketing/product-marketing.reducer';
import productMarketingList from './modules/product-marketing-list/product-marketing-list.reducer';
import productResearch from './modules/product-research/product-research.reducer';
import productResearchList from './modules/product-research-list/product-research-list.reducer';
import productRestriction from './modules/product-restriction/product-restriction.reducer';
import productRestrictionList from './modules/product-restriction-list/product-restriction-list.reducer';
import feedbackCreate from './modules/feedback-create/feedback-create.reducer';
import feedbackList from './modules/feedback-list/feedback-list.reducer';
import feedbackDetail from './modules/feedback-detail/feedback-detail.reducer';
import userList from './modules/user-list/user-list.reducer';
import categoryList from './modules/category-list/category-list.reducer';
import customerService from './modules/customer-service/customer-service.reducer';
import customerServiceList from './modules/customer-service-list/customer-service-list.reducer';
import productSkuList from './modules/product-sku-list/product-sku-list.reducer';
import productTypeList from './modules/product-type-list/product-type-list.reducer';
import productCriticalReport from './modules/product-critical-report/product-critical-report.reducer';
import affiliate from './modules/affiliate/affiliate.reducer';

const common = combineReducers({ snackbars, layout });

const root = {
  auth,
  common,
  notification,
  productItem,
  productFile,
  productShare,
  productList,
  productCreate,
  productMarketing,
  productMarketingList,
  productResearch,
  productResearchList,
  productRestriction,
  productRestrictionList,
  feedbackCreate,
  feedbackList,
  feedbackDetail,
  userList,
  categoryList,
  productOffer,
  customerService,
  customerServiceList,
  productOfferList,
  branchList,
  productSkuList,
  productTypeList,
  productCriticalReport,
  affiliate,
};

export default root;
