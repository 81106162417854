import {
  Types,
  LoadMoreAction,
  SetDataAction,
  LoadDataAction,
  ShowDataAction,
  HideDataAction,
  ChangeParamsAction,
  UpdateNotificationAction,
  ReadNotificationAction,
} from './notification.constant';

export function setData(data: any): SetDataAction {
  return { type: Types.SET_DATA, payload: { data } };
}

export function loadMore(data: any): LoadMoreAction {
  return { type: Types.LOAD_MORE, payload: { data } };
}

export function loadData(): LoadDataAction {
  return { type: Types.LOAD_DATA };
}

export function showData(): ShowDataAction {
  return { type: Types.SHOW_DATA };
}

export function hideData(): HideDataAction {
  return { type: Types.HIDE_DATA };
}

export function changeParams(params: any): ChangeParamsAction {
  return { type: Types.CHANGE_PARAMS, payload: { params } };
}

export function readNotification(
  ids: any,
  fnCallback: any
): ReadNotificationAction {
  return { type: Types.READ_NOTIFICATION, payload: { ids, fnCallback } };
}

export function updateNotification(ids: any): UpdateNotificationAction {
  return { type: Types.UPDATE_NOTIFICATION, payload: { ids } };
}
