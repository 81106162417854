import produce from 'immer';

import { Actions, IProductListState, Types } from './product-list.constant';
import {
  MY_PRODUCT,
  PRODUCT_MARKETABLE,
  PRODUCT_STATUS,
  AVAILABLE_REGIONS,
} from '../../../constants/common';

const initialState: IProductListState = {
  count: 0,
  data: [],
  firstLoad: true,
  loading: true,
  params: {
    page: 0,
    limit: 20,
    search: '',
    alphabet: null,
    status: {
      [PRODUCT_STATUS.Draft]: false,
      [PRODUCT_STATUS.Approve]: false,
      [PRODUCT_STATUS.Selling]: false,
      [PRODUCT_STATUS.Reject]: false,
    },
    isOwner: { [MY_PRODUCT.YES]: false, [MY_PRODUCT.NO]: false },
    marketable: {
      [PRODUCT_MARKETABLE.YES]: false,
      [PRODUCT_MARKETABLE.NO]: false,
      [PRODUCT_MARKETABLE.MAYBE]: false,
    },
    saleRegion: AVAILABLE_REGIONS.reduce((acc: any, item) => {
      acc[item] = false;
      return acc;
    }, {}),
    marketingName: null,
    sort: { updatedAt: -1 },
  },
  branch: {
    data: [],
    firstLoad: true,
    loading: false,
  },
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.LOAD_DATA:
    case Types.CHANGE_PARAMS:
      draft.loading = true;
      draft.params = { ...draft.params, ...action.payload.params };
      break;
    case Types.SET_LOADING:
      draft.loading = action.payload.loading;
      break;
    case Types.SET_DATA:
      draft.data = action.payload.data;
      draft.count = Math.ceil(action.payload.count / draft.params.limit);
      draft.loading = false;
      draft.firstLoad = false;
      break;
    case Types.LOAD_BRANCH_LIST:
      draft.branch.loading = true;
      break;
    case Types.SET_BRANCH_LIST:
      draft.branch.firstLoad = false;
      draft.branch.loading = false;
      draft.branch.data = action.payload.data;
      break;
    case Types.CLEAR_STATE:
      draft.loading = true;
      draft.firstLoad = true;
      break;
    case Types.CLEAR_BRANCH_PARAM:
      draft.params.marketingName = null;
      break;
    case Types.CLEAR_PARAMS:
      draft.params = {
        page: 0,
        limit: 20,
        search: '',
        status: {
          [PRODUCT_STATUS.Draft]: false,
          [PRODUCT_STATUS.Approve]: false,
          [PRODUCT_STATUS.Selling]: false,
          [PRODUCT_STATUS.Reject]: false,
        },
        isOwner: { [MY_PRODUCT.YES]: false, [MY_PRODUCT.NO]: false },
        marketable: {
          [PRODUCT_MARKETABLE.YES]: false,
          [PRODUCT_MARKETABLE.NO]: false,
          [PRODUCT_MARKETABLE.MAYBE]: false,
        },
        saleRegion: AVAILABLE_REGIONS.reduce((acc: any, item) => {
          acc[item] = false;
          return acc;
        }, {}),
        alphabet: null,
        marketingName: null,
        sort: { updatedAt: -1 },
      };
      break;
    // @ts-ignore
    case '[AUTH] SET_USER':
      return initialState;
  }
}, initialState);

export { initialState, reducer as default };
