import { IList } from 'constants/common';

import { IUserModel } from 'models';

export interface IUserListState extends IList {
  selectedData: any;
  openDeleteDialog: boolean;
  deleting: boolean;
  updating: boolean;
  params: {
    page: number;
    limit: number;
    search: string;
    status: any;
    headCells: any;
    sort: any;
    order: any;
  };
}

export enum Types {
  CHANGE_PARAMS = '[USER LIST] CHANGE PARAMS',
  SET_DATA = '[USER LIST] SET_DATA',
  LOAD_DATA = '[USER LIST] LOAD_DATA',
  RESET_DATA = '[USER LIST] RESET_DATA',
  SET_LOADING = '[USER LIST] SET_LOADING',
  OPEN_DELETE_DIALOG = '[USER LIST] OPEN_DELETE_DIALOG',
  HIDE_DELETE_DIALOG = '[USER LIST] HIDE_DELETE_DIALOG',
  DELETE_DATA = '[USER LIST] DELETE_DATA',
  BEGIN_DELETE_DATA = '[USER LIST] BEGIN_DELETE_DATA',
  END_DELETE_DATA = '[USER LIST] END_DELETE_DATA',
  UPDATE_DATA = '[USER LIST] UPDATE_DATA',
}

export interface BeginDeleteDataAction {
  type: typeof Types.BEGIN_DELETE_DATA;
  payload: {
    data: any;
  };
}

export interface EndDeleteDataAction {
  type: typeof Types.END_DELETE_DATA;
}

export interface ResetDataAction {
  type: typeof Types.RESET_DATA;
}

export interface OpenDeleteDialogAction {
  type: typeof Types.OPEN_DELETE_DIALOG;
  payload: {
    data: any;
  };
}

export interface UpdateRowAction {
  type: typeof Types.UPDATE_DATA;
  payload: {
    data: any;
  };
}

export interface HideDeleteDialogAction {
  type: typeof Types.HIDE_DELETE_DIALOG;
}

export interface ChangeParamsAction {
  type: typeof Types.CHANGE_PARAMS;
  payload: {
    params: any;
  };
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    loading: boolean;
  };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: IUserModel[]; count: number };
}

export type Actions =
  | UpdateRowAction
  | EndDeleteDataAction
  | BeginDeleteDataAction
  | ResetDataAction
  | SetDataAction
  | LoadDataAction
  | ChangeParamsAction
  | SetLoadingAction
  | OpenDeleteDialogAction
  | HideDeleteDialogAction;
