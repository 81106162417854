import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { size, get } from 'lodash';

import {
  addProduct,
  getProduct,
  updateProduct,
} from 'services/product.service';
import { updateImage, upload } from 'services/image.service';
import { getCategoryList } from 'services/category.service';
import { pushErrorMessage } from 'store/modules/snackbar/snackbar.action';
import { permissionsSelector } from 'store/modules/auth/auth.selector';
import { scanImages } from 'utils/form-data.util';

import { PRODUCT_STATUS, SIZE_100MB } from 'constants/common';

import {
  LoadDataAction,
  SaveDataAction,
  Types,
} from './product-create.constant';
import { saveDone, setData, initFormData } from './product-create.action';

function* loadProduct(_id: string) {
  let data;

  if (_id) {
    // @ts-ignore
    data = yield call(getProduct, _id);
    data.category = get(data, 'category._id', '');
    scanImages({
      data,
      keys: [
        'productImages',
        'packaging_images',
        'sketching',
        'design_patent_documents',
        'product_certification',
      ],
    });

    const tm = [];
    if (data.trademark_country) {
      for (let i = 0; i < data.trademark_country.length; i++) {
        tm.push({ name: data.trademark_country[i] });
      }

      data.trademark_country = tm;
    }

    const potentialPatentsCountry = [];
    if (data.potentialPatentsCountry) {
      for (let i = 0; i < data.potentialPatentsCountry.length; i++) {
        potentialPatentsCountry.push({ name: data.potentialPatentsCountry[i] });
      }

      data.potentialPatentsCountry = potentialPatentsCountry;
    }
  } else {
    yield delay(200);
  }

  return data;
}

function* watchLoadData(action: LoadDataAction) {
  const { _id } = action.payload;

  try {
    // @ts-ignore
    const [data, { data: categories }]: any = yield all([
      call(loadProduct, _id),
      // @ts-ignore
      yield call(getCategoryList, { page: 0, limit: 100 }),
    ]);

    yield put(initFormData(data, categories));
  } catch (error) {
    yield put(pushErrorMessage(error));
  }
}

export const formatBody = (data: any, isUpdate: Boolean, fields: any) => {
  const formData: any = new FormData();

  const fileSaveds: any = {
    productImages: [],
    sketching: [],
    design_patent_documents: [],
    product_certification: [],
    packaging_images: [],
  };

  const fileLarges: any = {
    productImages: [],
    sketching: [],
    design_patent_documents: [],
    packaging_images: [],
    product_certification: [],
  };

  Object.keys(data).forEach(function(key) {
    if (
      key === '_id' ||
      key === 'status' ||
      key === 'createdAt' ||
      key === 'updatedAt' ||
      key === 'product_legal' ||
      key === 'product_marketing' ||
      key === 'product_customer_service' ||
      key === 'product_market_research' ||
      (fields && fields.length > 0 && !fields.includes(key))
    ) {
      return;
    }

    if (
      key === 'marketable' &&
      (data.status === PRODUCT_STATUS.Approve ||
        data.status === PRODUCT_STATUS.Selling)
    ) {
      return;
    }

    if (
      ![
        'productImages',
        'packaging_images',
        'design_patent_documents',
        'sketching',
        'product_certification',
      ].includes(key)
    ) {
      const value = data[key];

      if (Array.isArray(value)) {
        if (
          [
            'mus_v2',
            'app_information_v2',
            'creative_brief_v2',
            'quick_start_link_v2',
            'warranty_card_link_v2',
            'warranty_domain_link_v2',
            'competitorProductURLs_v2',
            'research_link_v2',
            'other_research_link_v2',
            'customer_service_feedback_v2',
            'safety_link_v2',
            'brand_storefront_urls_v2',
            'tm_geos_registered',
          ].includes(key)
        ) {
          let jsonData = value || [];
          if (Array.isArray(jsonData)) {
            jsonData = jsonData.filter(item => item.key || item.value);
          }
          formData.append(key, JSON.stringify(jsonData));
        } else if (value.length < 1) {
          formData.append(key, '[]');
        } else {
          value.forEach((e: string) => {
            let v = e || '';

            if (v.length > 0) {
              formData.append(key, v);
            }
          });
        }
      } else {
        if (key === 'category') {
          formData.append(key, value || 'null');
        } else {
          formData.append(key, value);
        }
      }
    } else {
      data[key].forEach((e: any) => {
        if (e._id) {
          fileSaveds[key].push(e);
          return;
        }

        if (e.file.size > SIZE_100MB) {
          fileLarges[key].push(e);
        } else {
          if (isUpdate) {
            formData.append(`${key}[]`, e.file);
          } else {
            formData.append(key, e.file);
          }
        }
      });
    }
  });

  return { formData, fileSaveds, fileLarges };
};

function* saveImageToCloud(fileLarges: any) {
  const {
    productImages,
    sketching,
    design_patent_documents,
    product_certification,
  } = fileLarges;

  // Create a unique name for the container by
  // appending the current time to the file name
  const timeId = '_' + new Date().getTime();

  // @ts-ignore
  const azure = yield call(upload, 'images-original');

  /* @ts-ignore */
  // prettier-ignore
  const [productUrls, sketchingUrls, designPatentUrls, product_certificationUrls]: any = yield all([
    call(updateImage, productImages, timeId, azure),
    call(updateImage, sketching, timeId, azure),
    call(updateImage, design_patent_documents, timeId, azure),
    call(updateImage, product_certification, timeId, azure),
  ]);
  //

  return {
    productUrls,
    sketchingUrls,
    designPatentUrls,
    product_certificationUrls,
  };
}

function* watchSaveData(action: SaveDataAction) {
  let completed = true;
  try {
    // @ts-ignore
    const {
      product: { fields },
    } = yield select(permissionsSelector);

    const { data } = action.payload;
    const isUpdate = data._id ? true : false;

    const { formData, fileSaveds, fileLarges } = formatBody(
      data,
      isUpdate,
      fields
    );

    let productImages;
    let design_patent_documents;
    let sketching;
    let product_certification;
    if (size(fileLarges) > 0) {
      const {
        productUrls,
        sketchingUrls,
        designPatentUrls,
        product_certificationUrls,
      } = yield call(saveImageToCloud, fileLarges);

      productImages = [...fileSaveds.productImages, ...productUrls];
      sketching = [...fileSaveds.sketching, ...sketchingUrls];
      design_patent_documents = [
        ...fileSaveds.design_patent_documents,
        ...designPatentUrls,
      ];
      product_certification = [
        ...fileSaveds.product_certification,
        ...product_certificationUrls,
      ];
    } else {
      productImages = [...fileSaveds.productImages];
      sketching = [...fileSaveds.sketching];
      design_patent_documents = [...fileSaveds.design_patent_documents];
      product_certification = [...fileSaveds.product_certification];
    }

    if (size(fields) < 1 || fields.includes('productImages')) {
      formData.append('productImages', JSON.stringify(productImages));
    }
    if (size(fields) < 1 || fields.includes('sketching')) {
      formData.append('sketching', JSON.stringify(sketching));
    }
    if (size(fields) < 1 || fields.includes('design_patent_documents')) {
      formData.append(
        'design_patent_documents',
        JSON.stringify(design_patent_documents)
      );
    }
    if (size(fields) < 1 || fields.includes('product_certification')) {
      formData.append(
        'product_certification',
        JSON.stringify(product_certification)
      );
    }

    if (isUpdate) {
      // @ts-ignore
      const newData = yield call(updateProduct, data._id, formData);
      scanImages({
        data: newData,
        keys: [
          'productImages',
          'sketching',
          'design_patent_documents',
          'product_certification',
        ],
      });

      yield put(setData(newData));
    } else {
      yield call(addProduct, formData);
    }
  } catch (error) {
    yield put(pushErrorMessage(error));

    completed = false;
  }

  yield put(saveDone(completed));
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.SAVE_DATA, watchSaveData);
}
