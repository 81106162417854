import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { IUserListState } from './user-list.constant';

export const userListSelector = (state: IState) => {
  return state.userList;
};

export const paramsSelector = createSelector(
  userListSelector,
  (userList: IUserListState): any => userList.params
);

export const firstLoadSelector = createSelector(
  userListSelector,
  (userList: IUserListState): any => userList.firstLoad
);

export const deletePopupSelector = createSelector(
  userListSelector,
  (userList: IUserListState): any => ({
    data: userList.selectedData,
    open: userList.openDeleteDialog,
    deleting: userList.deleting,
  })
);
