import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { IProductListState } from './product-list.constant';

export const productListSelector = (state: IState) => {
  return state.productList;
};

export const paramsSelector = createSelector(
  productListSelector,
  (productList: IProductListState): any => productList.params
);

export const loadingSelector = createSelector(
  productListSelector,
  (productList: IProductListState): any => productList.loading
);

export const firstLoadSelector = createSelector(
  productListSelector,
  (productList: IProductListState): any => productList.firstLoad
);

export const searchUISelector = createSelector(
  productListSelector,
  (productList: IProductListState): any => {
    return {
      count: productList.count,
      value: productList.params.search,
      isOwner: productList.params.isOwner,
      loading: productList.loading,
    };
  }
);

export const listUISelector = createSelector(
  productListSelector,
  (productList: IProductListState): any => {
    return {
      count: productList.count,
      params: productList.params,
      loading: productList.loading,
      data: productList.data,
      firstLoad: productList.firstLoad,
    };
  }
);

export const branchSelector = createSelector(
  productListSelector,
  (productList: IProductListState): any => {
    return {
      branch: productList.branch,
    };
  }
);
