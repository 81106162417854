import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getProductSkuList } from 'services/product.service';

import { setData, setLoading } from './product-sku-list.action';
import { Types } from './product-sku-list.constant';
import { paramsSelector } from './product-sku-list.selector';

import { pushErrorMessage } from '../snackbar/snackbar.action';

function* watchLoadData() {
  try {
    yield put(setLoading(true));

    // @ts-ignore
    const { alphabet, ...params } = yield select(paramsSelector);

    params.where = {};
    if (alphabet) {
      params.where = {
        $or: [{ sku: { $regex: `^${alphabet}`, $options: 'i' } }],
      };
    }

    // @ts-ignore
    const { count, data } = yield call(getProductSkuList, params);
    yield put(setData(count, data));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData(0, []));
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.CHANGE_PARAMS, watchLoadData);
}
