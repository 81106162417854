const en = {
  common: {
    view_all_products: 'View All Products',
    product_type: 'Product Type',
    product_brand: 'Product Brand',
    marketing_operations: 'Marketing Operations',
    product_name_sku: 'Product Name & SKU',
    brand_name: 'Brand Name',
    marketable: 'Marketable',
    alphabet: 'Alphabet',
    latest: 'Latest',
    last_created: 'Last Created',
    first_created: 'First Created',
    created: 'Created',
    created_by: 'Created by',
    last_updated: 'Last updated',
    updated_by: 'Updated by',
    created_date: 'Created Date',
    updated_date: 'Updated Date',
    last_update: 'Last Update',
    sort_by: 'Sort by',
    order_by: 'Order by',
    or: 'or',
    log_in_with_google: 'Log in with Google',
    or_log_in_with_email_and_password: 'or Log in with Email and Password',
    sku: 'SKU',
    branch_name: 'Brand Name',
    login_with: 'Login with',
    login_with_other_email: 'Login with Other Email',
    default: 'Default',
    view_all: 'View All',
    group_by: 'Group By',
    my_products: 'My Products',
    filter: 'Filter',
    add_filter: 'Add Filter',
    selling: 'Selling',
    change_status: 'Change Status',
    pending: 'Pending',
    page: 'Page',
    to: 'To',
    subject: 'Subject',
    send_url_via_email: 'Send URL via Email',
    title: 'Title',
    value: 'Value',
    content: 'Content',
    department: 'Department',
    share: 'Share',
    ADMINISTRATION: 'Administration',
    BER: 'BER',
    BUSINESS_DEVELOPMENT: 'Business Development',
    CUSTOMER_SERVICE: 'Customer Service (Non - DFO)',
    FINANCE_ACCOUNTING: 'Finance/Accounting',
    FRONT_END_AND_WEB_DEVELOPMENT: 'Front End and Web Development',
    HUMAN_RESOURCES: 'Human Resources',
    INFORMATION_TECHNOLOGY: 'Information Technology',
    INTERNAL_COMPLIANCE: 'Internal Compliance',
    LEGAL: 'Legal',
    LOGISTICS: 'Logistics',
    MARKETING_OPERATIONS: 'Marketing Operations',
    MEDIA_OPERATIONS: 'Media Operations',
    PROCUREMENT: 'Procurement',
    PRODUCT_DEVELOPMENT: 'Product development',
    SALES_OPERATIONS: 'Sales Operations',
    SOFTWARE_PRODUCT_DEVELOPMENT: 'Software Product Development',
    SPECIAL_TACTICS: 'Special Tactics',
    STAFF: 'Staff',
    AFFILIATES: 'Affiliates (Non - DFO)',
    AMAZON: 'Amazon',
    EXECUTIVES: 'Executives',
    are_you_sure_update_product: 'Are you sure update product?',
    confirm_updating_this_product: 'Confirm Updating This Product?',
    email_not_exists:
      "We're sorry. The login email does not already exist, please register again",
    email_error_dfo: 'Please use DFO email to log in!',
    not_dfo_email: 'Please use DFO email to log in!',
    user_not_active:
      'You have not activated your account yet. Please, check your inbox and confirm your account',
    account_deleted:
      'Your account has been deleted, please try register with other email address',
    email_exists: 'This email address is already registered',
    forgot_title:
      "Enter your email address that you used to register. We'll send you an email with a link your password.",
    wrong_pwd: 'Incorrect username/ password. Please try again',
    wrong_old_password: 'Incorrect password. Please try again',
    new_password_must_different_than_last_three_pwd:
      'New password must different than last three password',
    new_password_must_be_different_from_old_password:
      'New password must be different from old password',
    reset_password: 'Reset password',
    forgot_password: 'Forgot password',
    change_password: 'Change password',
    export_product: 'Export Product',
    current_password: 'Current password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    password_change_1: 'At least 8 characters',
    password_change_2: 'At least one uppercase and one lower case letter',
    password_change_3: 'At least one number',
    password_change_4: 'New password does not repeat of previous password',
    comments: 'Comments',
    password_strength: 'Password strength',
    add_new: 'Add new',
    approve: 'Approve',
    reject: 'Reject',
    draft: 'Draft',
    add_new_product_marketing: 'Add new product marketing',
    add_customer_service: 'Add Customer Service',
    add_restrictions_rules: 'Add Legal',
    add_market_research: 'Add Market Research',
    add_product_marketing: 'Add Product Marketing',
    you_have: 'You have',
    unread_notifications: 'unread notifications',
    administration: 'Administration',
    administration_new: 'Administration',
    marketing_others: 'Marketing & Others',
    product_handbook: 'Product Handbook Online',
    product: 'Product',
    product_critical_feedback: 'Product Critical Feedback',
    guideline: 'Guideline',
    live: 'Live',
    country: 'Country',
    offer: 'Offer',
    dashboard: 'Dashboard',
    upcoming: 'Up Coming',
    projects: 'Projects',
    dark_theme: 'Dark Theme',
    dark_theme_tip_1:
      'Dark theme turns the light surfaces of the page dark, creating an experience ideal for night. Try it out!',
    dark_theme_tip_2:
      'Your Dark theme setting will apply to this browser only.',
    note_upload: 'Only files smaller than 100 MB can be uploaded',
    product_request: 'Product Requested',
    product_hand_book: 'Product Handbook Online',
    search_hint: 'Search (At least 3 character)',
    search_product_type_hint: 'Search Product Type',
    search_sku_hint: 'Search SKU',
    search_brand_name_hint: 'Search Brand Name',
    search_all_products_hint: 'Search Product',
    welcome_to: 'Welcome to',
    owner: 'Owner',
    reason: 'Reason',
    back_to_login: 'Back to Login',
    all: 'All',
    registration_completed_successfully: 'CONGRATULATION',
    registration_completed_successfully_confirm:
      'Please check your email and active your account!',
    forgot_password_completed_successfully_confirm:
      'Check Your Email! We have sent a password recovery instruction to your email.',
    SUPERADMIN: 'Super Admin',
    login_with_dfo_email: 'Login with DFO Email',
    login_with_none_dfo_email: 'Login with None DFO Email',
    dont_have_an_account: "Don't have an account?",
    already_have_an_account: 'Already have an account?',
    create_new_account: 'Create new account',
    confirmation: 'Confirmation',
    in_review: 'In Review',
    approved: 'Approved',
    rejected: 'Rejected',
    register_new_account: 'Register New Account',
    reenter_password: 'Re-enter Password',
    done: 'Done',
    product_not_found: 'Not found',
    update: 'Update',
    close: 'Close',
    cancel: 'Cancel',
    delete: 'Delete',
    view_more: 'View more',
    detail: 'Detail',
    data_is_saving: 'Data is saving...',
    data_is_loading: 'Data is loading...',
    create_product: 'Create Product',
    product_summary: 'Product Summary',
    products_marketing: 'Product Marketing',
    products_offer: 'Product Offers',
    feedback_list: 'Feedback List',
    customer_service: 'Customer Service',
    trademarks: 'Trademarks',
    critical_feedback: 'Critical Feedback',
    user_list: 'User Management',
    category_list: 'Category Management',
    not_found: 'Not Found',
    not_found_tip: 'Try rewording your search or entering a new keyword',
    none: 'None',
    create_date: 'Create date',
    costing: 'Costing',
    loading: 'Loading',
    saving: 'Saving',
    status: 'Status',
    clear_all: 'Clear All',
    back_to_home: 'Back to Home',
    back_to_detail: 'Back to Detail',
    change_password_completed_successfully:
      'New password is changed successfully!',
    login_failed: 'Login failed',
    last_name: 'Last Name',
    first_name: 'First Name',
    please_wait: 'Please wait',
    profile: 'Profile',
    search: 'Search',
    prev: 'Prev',
    next: 'Next',
    hi: 'Hi',
    email: 'Email',
    clear: 'Clear',
    password: 'Password',
    login: 'Log in',
    create_new_product: 'Create New Product',
    list_my_products: 'Product List',
    notifications: 'Notifications',
    pricing: 'Pricing',
    packaging: 'Packaging',
    moq: 'MOQ',
    go_back: 'Go back',
    lead_time: 'Lead Time',
    quantity_in_stock: 'Quantity in Stock',
    add_more: 'Add more',
    no: 'No',
    yes: 'Yes',
    submit: 'Submit',
    upload: 'Upload',
    log_in: 'Log in',
    remember_me: 'Remember me',
    request: 'Request',
    feedback: 'Feedback',
    home: 'Home',
    products: 'Products',
    logout: 'Logout',
    settings: 'Settings',
    deleting: 'Deleting',
    name: 'Name',
    role: 'Role',
    actions: 'Actions',
    active: 'Active',
    in_active: 'Inactive',
    market_research: 'Market Research',
    restrictions_rules: 'Legal',
    url: 'URL',
    source_name: 'Source Name',
    timezone: 'Timezone',
    are_you_sure_update_profile: 'Are you sure update your profile?',
    n_a: 'N/A',
    copied: 'The text has been copied to the clipboard.',
  },
  error: {
    password_new_confirm_invalid:
      'New password contains at least 8 characters, one mixed-case letter, one number and one special character.',
    not_match_password: 'Password and Re-enter password does not match!',
    this_field_is_required: 'This field is required',
    this_value_is_not_a_valid_url: 'This value is not a valid URL',
    this_field_can_not_be_negative: 'This field must be numeric and positive',
    this_field_must_be_a_valid_email: 'This field must be a valid email',
  },
  product: {
    create_new_customer_service: 'Create New Customer Service',
    title: 'Create New Product',
    product_details: 'Product Details',
    development: 'Development',
    marketing: 'Marketing',
    packaging: 'Packaging',
    research: 'Research',
    customer_service: 'Customer Service',
    intellectual_property: 'Intellectual Property',
    amazon: 'Amazon',
    legal_compliance: 'Legal/Compliance',
    production: 'Production',
    creative_checklist: 'Creative Checklist',
    go_live_approval: 'Go LIVE Approval',
    affiliates: 'Affiliates',
    file_management: 'File Management',
    tm_name: 'TM Name',
    tm_country: 'Jurisdiction',
    amazon_brand_registry: 'Amazon Brand Registry',
    app_information_hint: 'Please inset AppStore/PlayStore/AmazonStore here',
    update_product: 'Update Product of {{name}}',

    delete_product: 'Are you sure want to delete?',
    send_another_request: 'Create Another Product',
    not_found: 'Not Found',
    new_product: 'New product',
    add_new_product: 'Add new product',
    marketable: 'Marketable',
    approve: 'Approve',
    reason: 'Reason',
    reject: 'Reject',
    selling: 'Selling',
    pending: 'Pending',
    sourced: 'Source',
    built: 'Built',
    from: 'from',
    to: 'to',
    yes: 'Yes',
    no: 'No',
    maybe: 'Maybe',
    unknown: 'Unknown',
    market_research: 'Market Research',
    edit_restriction: 'Edit Legal',
    create_new_restriction: 'Create new Legal',
    amazon_restriction: 'Amazon (Issues/ Batteries)',
    traffic_source: 'Geo/ Wording/ Traffics source',
    social_platform_restriction: 'Social Platform Restrictions',
    bank_restriction: 'Bank Restrictions',
    advertising: 'Advertising',
    charge_back_rate: 'Charge Back Rate',
    utility_patent: 'Utility Patent',
    trademark: 'Trademark',
    trademark_name: 'Trademark Name',
    trademark_country: 'Trademark Country',
    design_patent: 'Design Patent',
    app_information: 'App Information',
    mvp: 'MVP',
    both: 'Both',
    feedback_urls: 'Feedback URLs',
    app: 'App',
    done: 'Done',
    need_attention: 'Need Attention',
    not_needed: 'Not Needed',
    in_process: 'In-Progress',
    packaging_graphics: 'Packaging Graphics (HCM)',
    upsell: 'Upsell',
    mvp_upsell: 'MVP / Upsell',
    edit_product: 'Edit Product',
    edit_market_research: 'Edit Market Research',
    create_new_market_research: 'Create new Market Research',
    name_as_generally_known: 'Product Name',
    marketing_name_if_known: 'Brand Name',
    is_tm_filed: 'Is TM filed?',
    general_product_description: 'Product Description',
    requested_features: 'Requested Features',
    description: 'Description',
    useful_links: 'Useful Links',
    mus_im: 'MUS & IM',
    research_summary: 'Research Summary',
    research_summary_placeholder: 'Research Summary',
    research_detail: 'Research Detail',
    research_detail_placeholder: 'Research Detail',
    list_product_features: 'List Product Features',
    please_describe_how_to_use_the_product_is_used:
      'Please describe how to use the product is used',
    product_description: 'Product Description',
    product_requested_function: 'Product Requested Function',
    your_request_has_been_sent: 'Your product is created successfully!',
    your_request_has_been_updated: 'Your product has been updated!',
    product_usage: 'Product Usage',
    general_category: 'Category',
    product_key_word: 'Product Key Words',
    product_features: 'Product Features',
    product_images: 'Product Images',
    tags: 'Tags',
    tags_hint: 'Press Enter to separate',
    product_url_social_media: 'Product URL(s) Social Media',
    do_you_want_samples: 'Do you want Samples',
    where_do_samples_go: 'Where do samples go',
    drawings_sketchings: 'Drawings/sketchings',
    potential_patents: 'Group Patents',
    potential_marketing_names: 'Potential Marketing Names',
    competitor: 'Competitor',
    offer_urls: 'Offer Urls',
    competitor_urls: 'Competitor Urls',
    amazon_urls: 'Amazon Urls',
    competitors: 'Competitors',
    feedback_brief: 'Feedback Brief',
    drawings: 'Drawings',
    link_to_mus_or_other_useful_document:
      'Link to MUS or other useful document',
    creative_brief: 'Creative Brief',
    creative_brief_urls: 'Creative Brief URLs',
    sale_region: 'Sale Region',
    sale_region_remarks: 'Sale Region Remarks',
    sale_region_ex: 'Ex: US, CA, UK, EU, ...',
    available_regions: 'Available Regions',
    tm_filed: 'TM Filed',
    competitor_products_urls: 'Competitor products URLs',
    appstore_playstore_links: 'Appstore & Playstore links',
    registration: 'Registration',
    copy_and_paste_jira_ticket_here: 'Copy and paste Jira ticket here',
    remarks_notes: 'Remarks / Notes',
    jira_ticket: 'JIRA Ticket',
    product_sourced_or_to_be_custom_built:
      'Product Sourced or to be Custom Built',
    do_you_want_to_delete_this_product:
      "Enter product's id (<0>{{name}}</0>) below to confirm you want to permanently delete it:",
    do_you_want_to_selling_this_product: 'Do you want to selling this product?',
    do_you_want_to_pending_this_product: 'Do you want to pending this product?',
    do_you_want_to_approve_this_product: 'Do you want to approve this product?',
    do_you_want_to_reject_this_product: 'Do you want to reject this product?',
    approve_for: 'Approve for {{team}}',
    disapprove_for: 'Disapprove for {{team}}',
    do_you_want_to_go_live: 'Do you want to go live?',
    put_to_live_by: 'Put to LIVE by',
    my_products: 'My Products',
    specification: 'Specification',
    language: 'Language',
    product_name: 'Product Name',
    brand_name: 'Brand Name',
    sku: 'SKU',
    links_to_offers: 'Links to Offers',
    images: 'Images',
    seasonality: 'Seasonality',
    season_name: 'Season Name',
    lead_time: 'Lead Time',
    product_costing: 'Product Costing',
    product_3pl_land_cogs: '3PL Land COGS',
    product_azm_fba_land_cogs: 'AZM FBA land COGS',
    unit_price_usd: 'Cost',
    cargo_ready_date: 'Cargo Ready Date',
    daily_capacity: 'Daily Capacity',
    edit_marketing_information: 'Edit Marketing Information',
    edit_customer_service: 'Edit Customer Service',
    product_information: 'Product Information',
    marketing_information: 'Marketing Information',
    product_marketing_cost: 'Product cost',
    product_marketing_name: 'Marketing name',
    product_marketing_images: 'Marketing images',
    documents: 'Documents',
    // analytic_data: "Google Analytic Data",
    // consumer_study: "Published Consumer Studies",
    links_to: 'Link to:',
    delete_market_research: 'Are you sure want to delete product?',
    delete_customer_service: 'Are you sure want to delete product?',
    delete_product_marketing: 'Are you sure want to delete product?',
    delete_product_restriction: 'Are you sure want to delete product?',
    consumer_feedback: 'Consumer Feedback',
    results_consolidate: 'Results Consolidate',
    review_sources: 'Review Sources',
    weight: 'Weight',
    net_weight: 'Net Weight',
    gross_weight: 'Gross Weight',
    package_weight: 'Package Weight',
    product_weight: 'Product Weight',
    country: 'Country',
    general_dimension: 'Dimensions mm',
    dimensions: 'Product Dimensions',
    package_dimensions: 'Package Dimensions',
    dimension_unit: '(H x W x L) mm',
    dimension_height: 'H',
    dimension_width: 'W',
    dimension_length: 'L',
    Weight: 'weight',
    mus_useful_links: 'MUS/Useful Links',
    unit_price: 'Unit Price',
    type_of_plug: 'Type of Plug',
    product_version: 'Product Version',
    product_certification: 'Product Certification',
    packaging_version: 'Packaging Version',
    packaging_dimension: 'Packaging Dimension',
    cogs: 'COGS',
    feedback: 'Feedback',
    customer_feedback: 'Customer Feedback',
    customer_service_product_cs_guide: 'Product CS Guide',
    customer_service_product_cs_guide_placeholder: 'Product CS Guide',
    tm_status: 'TM Status',
    product_status: 'Product Status',
    funnel: 'Funnel',
    barcode: 'Barcode',
    amazon_page_assets: 'Amazon Page Assets',
    amazon_page_assets_placeholder: 'Amazon Page Assets',
    amazon_seo_research: 'Amazon SEO research',
    amazon_seo_research_placeholder: 'Amazon SEO research',
    amazon_compliance: 'Amazon Compliance',
    amazon_compliance_placeholder: 'Amazon Compliance',
    brand_storefront_urls_v2: 'Brand Storefront URLs',
    brand_storefront_urls_v2_placeholder: 'Brand Storefront URLs',
    inventory: 'Inventory',
    remarks: 'Remarks',
    production_type: 'Production Type',
    moq: 'MOQ',
    tooling_sign_off: 'Tooling Sign-off',
    cost_sign_off: 'Cost Sign-off',
    order_date: 'Order Date',
    first_order_date: 'First Order Date',
    est_cargo_ready_date: 'Est. Cargo Ready Date',
    compliance_booklet: 'Compliance Booklet',
    compliance_booklet_placeholder: 'Compliance Booklet',
    compliance_slide: 'Compliance Slide',
    compliance_slide_placeholder: 'Compliance Slide',
    disclaimer: 'Disclaimer',
    disclaimer_placeholder: 'Disclaimer',
    legal_notes: 'Legal Notes',
    legal_notes_placeholder: 'Legal Notes',
    compliance_notes: 'Compliance Notes',
    compliance_notes_placeholder: 'Compliance Notes',
    safety: 'Safety',
    creative_product_intro: '1. Product Intro',
    creative_product_intro_placeholder: 'Product Intro',
    creative_market_position: '2. Market Position - Consumer Need',
    creative_market_position_placeholder: 'Market Position - Consumer Need',
    creative_sample_pitch: '3. Sample Pitch',
    creative_sample_pitch_placeholder: 'Sample Pitch',
    creative_mus: '4. MUS',
    creative_mus_placeholder: 'MUS',
    creative_product_design: '5. Product Design & How to use',
    creative_product_design_placeholder: 'Product Design',
    creative_top_key_selling: '6. Top Key Selling Points',
    creative_top_key_selling_placeholder: 'Top Key Selling Points',
    creative_target_demographic: '7. Target Demographic',
    creative_target_demographic_placeholder: 'Target Demographic',
    creative_competitor_analysis: '8. Competitor Analysis',
    creative_competitor_analysis_placeholder: 'Competitor Analysis',
    creative_potential_presale_angle: '9. Potential Presale Angle',
    creative_potential_presale_angle_placeholder: 'Potential Presale Angle',
    creative_market_trends: '10. Market Trends',
    creative_market_trends_placeholder: 'Market Trends',
    creative_compliance_guidelines: '11. Compliance Guidelines',
    creative_compliance_guidelines_placeholder: 'Compliance Guidelines',
    creative_notes: '12. Notes',
    creative_notes_placeholder: 'Notes',
    creative_other_remarks: 'Other Remarks',
    creative_other_remarks_placeholder: 'Other Remarks',
    creative_status: 'Status',
    links_to_amazon: 'Links to Amazon',
    customer_service_link: 'Customer Service Link',
    warranty_card: 'Warranty Card',
    warranty_domain: 'Warranty Domain',
    quick_start: 'Quick Start',
    warranty_slip: 'Warranty Slip',
    packaging_graphics_hcm: 'Packaging Graphics',
    packaging_link: 'Packaging',
    warranty_link: 'Warranty',
    instruction_manual: 'Instruction Manual',
    samples_delivered: 'Samples Delivered',
    request_samples: 'Request Samples',
    delivery_office: 'Delivery Office',
    tracking_sample: 'Tracking Sample',
    mainsale_or_upsell: 'MainSale or Upsell',
    create_brief: 'Create Brief',
    product_type: 'Product Type',
    ctrwow_status: 'CTRWow Status',
    registered_owner: 'Registered Owner',
    registration_no: 'Registration No',
    registered_date: 'Registered Date',
    class_and_goods: 'Class and Goods',
    tm_registered_eta: 'TM registered ETA',
    tm_geos_registered: 'TM Geos Registered',
    tm_geos_registered_key: 'Region',
    last_updated: 'Last Updated',
    geography: 'Geography',
    urls: 'URLs',
    test_urls: 'Test Urls',
    live_urls: 'Live Urls',
    approval_status: 'Approval Status',
    ready_to_go_live: 'Ready To Go Live',
    not_ready_to_go_live: 'Not Ready To Go Live',
    team: 'Team',
    contact: 'Contact',
    status: 'Status',
    status_latest_update: 'Updated by',
    team_product_development: 'Product Development',
    team_legal_compliance: 'Legal/Compliance',
    team_amazon: 'Amazon',
    team_traffic_marketing: 'Traffic/Marketing',
    change: 'Change',
    go_live: 'Go Live',
    disapprove: 'Disapprove',
    aff_offer: 'Aff. Offer',
    affiliate_mode: 'Show To Affiliate',
    affiliate_homepage: 'Affiliate Homepage',
    affiliate_url: 'Affiliate URL',
    affiliate_offer_name: 'Offer Name',
    affiliate_offer_description: 'Offer Description',
  },
  feedback: {
    not_found: 'Not Found',
    send_another_feedback: 'Create Another Feedback',
    your_feedback_has_been_sent: 'Your feedback is created successfully!',
    your_feedback_has_been_updated: 'Your feedback has been updated!',
    update_feedback: 'Update Feedback of {{name}}',
    do_you_want_to_delete_this_feedback:
      "Enter feedback's id (<0>{{name}}</0>) below to confirm you want to permanently delete it:",
    title: 'Send your comment',
    title_create: 'Send your comment of {{name}}',
    product_found: 'Product Found',
    feedback_details: 'Feedback Details',
    competitor_url_found: 'Competitor URL(s) found',
    how_many_factories_companies: 'How many factories companies',
    delete_feedback: 'Are you sure want to delete?',
    upload: 'Upload',
    name: 'Name',
    address: 'Address',
    telephone: 'Telephone',
    qq: 'QQ',
    social_media: 'Social Media',
    website: 'Website',
    product_moq: 'Product MOQ',
    currency: 'Currency',
    amount: 'Amount',
    pricing_by_unit: 'Pricing by unit',
    product_pricing_in_usd: 'Product Pricing in USD',
    select_where_factory_found: 'Select where factory found',
    indicate_url_where_found: 'Indicate URL where found',
    request_sample_purchase_approval: 'Request sample purchase approval',
    estimated_courier: 'Estimated courier',
    how_much_are_samples: 'How much are samples',
    enter_sku_of_product: 'Enter Sku of product',
    pd_feedback: 'PD Comment',
    drawings: 'Drawings',
    estimated_costing: 'Estimated costing',
    competitors: 'Competitors',
    comments: 'Note',
    make_jira_ticket: 'Make Jira ticket',
    accounting_details_for_products: 'Accounting Details for products',
    company_ordering: 'Company ordering',
    po_date: 'PO Date',
    business_Segment: 'Business Segment',
    item_master: 'Item Master',
    vendor_info: 'Vendor Info',
    payment_info: 'Payment info',
    payment_terms: 'Payment Terms',
    delivery_terms: 'Delivery Terms',
    packaging_pricing: 'Packaging Pricing',
    my_feedback: 'My Feedback',
    product_comment: 'Product Comment',
    product_pricing: 'Product Pricing',
    note: 'Note',
    owner: 'Owner',
    date: 'Date',
    edit: 'Edit',
    view: 'View',
    delete: 'Delete',
  },
  theme: {
    logout: 'Logout',
    primary: 'Primary',
    secondary: 'Secondary',
    pallete: 'Pallete',
    dark: 'On',
    light: 'Off',
    dark_theme: 'Dark Theme',
    pallete_tip_1:
      'A primary color is the color displayed most frequently across your app’s screens and components.',
    pallete_tip_2:
      'A secondary color provides more ways to accent and distinguish your product. Having a secondary color is optional, and should be applied sparingly to accent select parts of your UI.',
    dark_theme_tip_1:
      'Dark theme turns the light surfaces of the page dark, creating an experience ideal for night. Try it out!',
    dark_theme_tip_2:
      'Your Dark theme setting will apply to this browser only.',
  },
  color: {
    red: 'Red',
    pink: 'Pink',
    purple: 'Purple',
    deepPurple: 'Deep Purple',
    indigo: 'Indigo',
    blue: 'Blue',
    lightBlue: 'Light Blue',
    cyan: 'Cyan',
    teal: 'Teal',
    green: 'Green',
    lightGreen: 'Light Green',
    lime: 'Lime',
    yellow: 'Yellow',
    amber: 'Amber',
    orange: 'Orange',
    deepOrange: 'Deep Orange',
  },
  home: {
    greeting: 'Welcome to Product Handbook Online',
  },
  product_item: {
    title: 'Product Details',
    title_detail: '{{name}}',
  },
  product_file: {
    confirmation: 'Confirmation',
    upload_file_to: 'Upload Files to folder',
    ok: 'OK',
    cancel: 'Cancel',
    cancel_uploading: 'Cancel Uploading',
    upload: 'Upload',
    select_files: 'Select Files',
    drag_files_here: 'Drag Files Here',
    uploading: 'Uploading',
    uploading_almost_done: 'Almost done. Please wait...',
    editing: 'Editing',
    update: 'Update',
    save: 'Save',
    name: 'Name',
    file_name: 'File Name',
    folder_name: 'Folder Name',
    description: 'Description',
    create_folder: 'Create Folder',
    new_folder: 'New Folder',
    create: 'Create',
    delete: 'Delete',
    download: 'Download',
    creator: 'Creator',
    updated_by: 'Updated By',
    download_all: 'Download All',
    public_share: 'Public Share',
    downloaded_times: 'Downloaded {{times}} times',
    public_shared_by_people: 'Public shared by {{people}} people',
    time: 'Time',
    edit: 'Edit',
    share: 'Share',
    shared_by: 'Shared By',
    is_sharing: 'Is Sharing',
    not_sharing: 'Not Sharing',
    add_more_files: 'Add More Files',
    view_more: 'View More',
    stop_sharing: 'Stop Sharing',
    downloads: 'Downloads',
    ip: 'IP',
    from: 'From',
  },
  critical_report: {
    product_critical_report: 'Product Critical Report',
    import_new: 'Import New',
    import_success: 'Import Successfully!',
    uploading: 'Uploading',
    confirmation: 'Confirmation',
    ok: 'OK',
    cancel: 'Cancel',
  },
  user: {
    delete_user: 'Remove User',
    delete_user_note: 'Do you really want to remove this user?',
    not_found: 'Not Found',
    personal_setting: 'Personal Setting',
    success: 'Confirmation Successfully',
  },
  category: {
    not_found: 'Not Found',
    delete_category_note: 'Do you really want to remove this category?',
    new_category: 'Add Category',
    update_category: 'Update Category',
    delete_category: 'Remove Category',
    add_new: 'Add new',
  },
  offer: {
    product: 'Product',
    launch_date: 'Launch Date',
    offer_name: 'Offer Name',
    status: 'Status',
    pricing_grid: 'Price Grid',
    geography: 'Geography',
    performance: 'Performance',
    creative_asset: 'Sales Funnel',
    title: 'Create New Offer',
    update_offer: 'Update Offer',
    new_offer: 'Add New Offer',
    not_found: 'Not Found',
    delete_product_offer: 'Are you sure want to delete product?',
    add_new_offer: 'Add New Offer',
    product_is_required: 'Please select the product marketing to create offer!',
  },
  product_marketing: {
    create_new_product_marketing: 'Create New Product Marketing',
  },
  dashboard: {
    total_products: 'Total Products',
    draft_products: 'Draft Products',
    approved_products: 'Approved Products',
    feedbacks: 'Feedbacks',
    recent_new_products: 'Recent New Products (Top 10)',
    product_offers: 'Product Offers',
  },
  unassigned: {
    title: 'WELCOME TO PHO',
    content: `You are currently not assigned to any team.
Please contact your manager to set your role in PHO.`,
  },
  title: {
    create_product: 'Product Handbook Online - New Product',
    update_product: 'Product Handbook Online - Update Product',
    product_summary: 'Product Handbook Online - Product Summary',
    product_marketing: 'Product Handbook Online - Product Marketing',
    product_offers: 'Product Handbook Online - Product Offers',
    market_research: 'Product Handbook Online - Market Research',
    customer_service: 'Product Handbook Online - Customer Service',
    legal: 'product Handbook Online - Legal',
    user_management: 'Product Handbook Online - User Management',
    category_management: 'Product Handbook Online - Category Management',
    feedback: 'Product Handbook Online - Feedback',
    settings: 'Product Handbook Online - Settings',
    product_critical_feedback: 'Product Handbook Online - Critical Feedback',
    unassigned: 'Product Handbook Online - Unassigned',
  },
  registration: {
    success: 'Confirmation Successfully',
    fail: 'Confirmation fails',
    login: 'Go to Login',
    timezone: 'Timezone',
  },
};

export default en;
