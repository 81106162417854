import { callApi } from 'utils/service.util';

const PRODUCT_API_ENDPOINT = 'v1/product/v1';
const headers = {
  'Content-Type': 'multipart/form-data',
};

export const getAffiliateProducts = async (params?: any) => {
  return callApi({
    method: 'get',
    url: `${PRODUCT_API_ENDPOINT}/affiliate`,
    headers: {
      Affiliate: window.localStorage.getItem('affiliate-email') || '',
    },
    params,
  });
};

export const getProducts = async (params?: any) => {
  return callApi({
    method: 'get',
    url: PRODUCT_API_ENDPOINT,
    params,
  });
};

export const addProduct = async (data: any) => {
  return callApi({
    method: 'post',
    url: PRODUCT_API_ENDPOINT,
    data,
    headers,
  });
};

export const updateProduct = async (_id: string, data: any) => {
  return callApi({
    method: 'put',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
    data,
    headers,
  });
};

export const approveProduct = async (
  _id: string,
  status: number,
  reason?: string
) => {
  return callApi({
    method: 'put',
    url: `${PRODUCT_API_ENDPOINT}/approve/${_id}`,
    data: { status, reason },
  });
};

export const approveSectionProduct = async (_id: string, data: any) => {
  return callApi({
    method: 'put',
    url: `${PRODUCT_API_ENDPOINT}/approve-section/${_id}`,
    data,
  });
};

export const getProduct = async (_id: any) => {
  return callApi({
    method: 'get',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
  });
};

export const deleteProduct = async (_id: any) => {
  return callApi({
    method: 'delete',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
  });
};

export const getBranchList = async (params?: any) => {
  return callApi({
    method: 'get',
    url: '/v1/product/v1/distinct_branch',
    params,
  });
};

export const getProductSkuList = async (params?: any) => {
  return callApi({
    method: 'get',
    url: '/v1/product/v1/distinct_sku',
    params,
  });
};

export const getProductTypeList = async (params?: any) => {
  return callApi({
    method: 'get',
    url: '/v1/product/v1/distinct_type',
    params,
  });
};
