import { call, put, select, takeLatest } from 'redux-saga/effects';
import _get from 'lodash/get';

import { getProducts, getBranchList } from 'services/product.service';

import { setData, setLoading, setBranchList } from './product-list.action';
import { Types } from './product-list.constant';
import { Types as TypeItems } from '../product-item/product-item.constant';
import { paramsSelector } from './product-list.selector';
import { userSelector } from '../auth/auth.selector';

import { pushErrorMessage } from '../snackbar/snackbar.action';
import { MY_PRODUCT } from '../../../constants/common';
import { navigate } from '@reach/router';

function* watchLoadData() {
  try {
    yield put(setLoading(true));

    // @ts-ignore
    const {
      isOwner,
      status,
      marketingName,
      generalName,
      marketable,
      saleRegion,
      alphabet,
      ...params
    } = yield select(paramsSelector);
    let { sku, type } = params;
    // @ts-ignore
    const user = yield select(userSelector);

    params.where = {};
    const $or = [];

    if (status && typeof status === 'object') {
      const inList = Object.entries(status).reduce((acc: any, [k, v]) => {
        if (v) {
          acc.push(parseInt(k, 10));
        }
        return acc;
      }, []);
      if (inList.length) {
        params.where.status = { $in: inList };
      }
    } else if (status > 0) {
      params.where.status = status;
    }

    if (marketable && typeof marketable === 'object') {
      const inList = Object.entries(marketable).reduce((acc: any, [k, v]) => {
        if (v) {
          acc.push(parseInt(k, 10));
        }
        return acc;
      }, []);
      if (inList.length) {
        params.where.marketable = { $in: inList };
      }
    } else if (marketable > -1) {
      params.where.marketable = marketable;
    }

    if (isOwner && typeof isOwner === 'object') {
      if (isOwner[MY_PRODUCT.YES] && !isOwner[MY_PRODUCT.NO]) {
        params.where.user = { $eq: user._id };
      }
      if (isOwner[MY_PRODUCT.NO] && !isOwner[MY_PRODUCT.YES]) {
        params.where.user = { $ne: user._id };
      }
    } else if (isOwner) {
      params.where.user = user._id;
    }

    if (saleRegion && typeof saleRegion === 'object') {
      const inList = Object.entries(saleRegion).reduce((acc: any, [k, v]) => {
        if (v) {
          acc.push(k);
        }
        return acc;
      }, []);
      if (inList.length) {
        params.where.sale_region = {
          $regex: `(^|,)(${inList.join('|')})`,
          $options: 'i',
        };
      }
    }

    if (marketingName) {
      params.where.marketingName = marketingName;
    }
    if (generalName) {
      params.where.generalName = generalName;
    }
    params.where.sku = sku;

    params.where.type = type;

    if (alphabet) {
      $or.push({ generalName: { $regex: `^${alphabet}`, $options: 'i' } });
    }

    if ($or.length) {
      params.where.$or = $or;
    }

    const { count, data } = yield call(getProducts, params);

    if (data) {
      data.forEach((d: any) => {
        d.owner =
          _get(d, 'user.firstName', '') + ' ' + _get(d, 'user.lastName', '');
      });
    }

    yield put(setData(count, data));

    if (
      window.location.pathname.match(/^\/products\/product-sku\/.+/) &&
      count === 1 &&
      data[0]
    ) {
      console.log(data[0]);
      navigate(`/products/${data[0]._id}/detail${window.location.search}`, {
        replace: true,
      }).catch(console.error);
    }
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData(0, []));
  }
}

function* watchLoadBranchList() {
  try {
    const params = {};
    // @ts-ignore
    const data = yield call(getBranchList, params);
    yield put(setBranchList(data));
  } catch (e) {
    yield put(pushErrorMessage(e));
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.CHANGE_PARAMS, watchLoadData);
  yield takeLatest(Types.LOAD_BRANCH_LIST, watchLoadBranchList);
  yield takeLatest(TypeItems.END_DELETE_DATA, watchLoadData);
}
