import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import styles from './text-field.module.scss';

import { FormLabel } from '../text-field';

function TextareaAutosizeUI({
  error,
  helperText,
  className,
  label,
  endAdornment,
  required,
  ...props
}: any) {
  return (
    <>
      <FormLabel required={required} label={label} />
      <div className={styles.container}>
        <TextareaAutosize className={styles.input} {...props} />
      </div>
      {error && <div className={styles.error}>{helperText}</div>}
    </>
  );
}

TextareaAutosizeUI.defaultProps = {
  maxLength: 2000,
};

export { TextareaAutosizeUI as TextareaAutosize };
