import { createSelector } from 'reselect';
import { get } from 'lodash';
import { IState } from 'store/constants';
import { IProductItemState } from './product-item.constant';

export const productItemSelector = (state: IState) => {
  return state.productItem;
};

export const statusItemSelector = (state: IState) => {
  const { loading, deleting } = state.productItem;
  return { loading, deleting };
};

export const dataSelector = createSelector(
  productItemSelector,
  (productItem: IProductItemState): any => ({
    popupData: productItem.popupData,
    data: {
      ...productItem.data,
      user: {
        // @ts-ignore
        _id: productItem?.data?.user,
      },
      updater: {
        // @ts-ignore
        _id: productItem?.data?.updater,
      },
    },
    offer: productItem.offer,
  })
);

export const dataGeneralNameSelector = createSelector(
  productItemSelector,
  (productItem: IProductItemState): any =>
    get(productItem, 'data.generalName', null)
);

export const loadingSelector = createSelector(
  productItemSelector,
  (productItem: IProductItemState): any => productItem.loading
);

export const deletePopupSelector = createSelector(
  productItemSelector,
  (productItem: IProductItemState): any => ({
    deleting: productItem.deleting,
    data: productItem.data,
    open: productItem.showDeletePopup,
  })
);

export const approvePopupSelector = createSelector(
  productItemSelector,
  (productItem: IProductItemState): any => ({
    updating: productItem.updating,
    data: productItem.data,
    open: productItem.showApprovePopup,
  })
);

export const rejectPopupSelector = createSelector(
  productItemSelector,
  (productItem: IProductItemState): any => ({
    updating: productItem.updating,
    data: productItem.data,
    open: productItem.showRejectPopup,
  })
);

export const pendingPopupSelector = createSelector(
  productItemSelector,
  (productItem: IProductItemState): any => ({
    pending: productItem.updating,
    data: productItem.data,
    open: productItem.showPendingPopup,
  })
);

export const sellingPopupSelector = createSelector(
  productItemSelector,
  (productItem: IProductItemState): any => ({
    selling: productItem.updating,
    data: productItem.data,
    open: productItem.showSellingPopup,
  })
);

export const approveSectionPopupSelector = createSelector(
  productItemSelector,
  (productItem: IProductItemState): any => ({
    updating: productItem.updating,
    popupData: productItem.popupData,
    open: productItem.showApproveSectionPopup,
  })
);

export const disapproveSectionPopupSelector = createSelector(
  productItemSelector,
  (productItem: IProductItemState): any => ({
    updating: productItem.updating,
    popupData: productItem.popupData,
    open: productItem.showDisapproveSectionPopup,
  })
);

export const goLivePopupSelector = createSelector(
  productItemSelector,
  (productItem: IProductItemState): any => ({
    updating: productItem.updating,
    popupData: productItem.popupData,
    open: productItem.showGoLivePopup,
  })
);
