import produce from 'immer';

import { Actions, Types } from './product-restriction-list.constant';

const initialState: any = {
  count: 0,
  data: [],
  firstLoad: true,
  loading: true,
  deleting: false,
  selectedData: null,
  params: {
    page: 0,
    limit: 20,
    search: '',
    sort: { createdAt: -1 },
  },
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.LOAD_DATA:
    case Types.CHANGE_PARAMS:
      draft.loading = true;
      draft.params = { ...draft.params, ...action.payload.params };
      break;
    case Types.SET_LOADING:
      draft.loading = action.payload.loading;
      break;
    case Types.SET_DATA:
      draft.data = action.payload.data;
      draft.count = Math.ceil(action.payload.count / draft.params.limit);
      draft.loading = false;
      draft.firstLoad = false;
      break;
    case Types.BEGIN_DELETE_DATA:
      draft.deleting = true;
      break;
    case Types.END_DELETE_DATA:
      draft.selectedData = null;
      draft.deleting = false;
      draft.showDeletePopup = false;
      break;
    case Types.SHOW_DELETE_POPUP:
      draft.showDeletePopup = true;
      draft.selectedData = action.payload.data;
      break;
    case Types.HIDE_DELETE_POPUP:
      draft.showDeletePopup = false;
      break;
    case Types.CLEAR_STATE:
      draft.firstLoad = true;
      break;
    // @ts-ignore
    case '[AUTH] SET_USER':
      return initialState;
  }
}, initialState);

export { initialState, reducer as default };
