import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 10px;

  .page-text {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }

  .page-select {
    .MuiSelect-root {
      color: #333333;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
      text-align: left;
    }
    .MuiInputBase-root {
      box-sizing: border-box;
      height: 32px;
      min-width: 70px;
      border: 1px solid #c0c6cc;
      border-radius: 4px;
      background-color: #edf2f7;
      overflow-y: hidden;

      fieldset {
        border: none;
      }
    }
  }

  .MuiPaginationItem-root {
    height: 32px;
    min-width: 32px;
    border-radius: 5px;
    background-color: #ffffff;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    margin: 0 4px;

    &:hover {
      background-color: #2b8d7d;
      color: #ffffff;
    }

    &.Mui-selected {
      background-color: #30bfa7;
      color: #ffffff;
    }
  }

  .MuiPaginationItem-ellipsis {
    line-height: 32px;
  }

  .pagination {
    margin: 0 -4px;
  }
`;
