import {
  Types,
  LoadDataAction,
  SaveDataAction,
  SaveDoneAction,
  InitFormAction,
  SetCompletedAction,
  ClearStateAction,
  HideConfirmPopupAction,
  ShowConfirmPopupAction,
} from './product-marketing.constant';

export function saveData(data: any): SaveDataAction {
  return { type: Types.SAVE_DATA, payload: { data } };
}

export function saveDone(completed: boolean): SaveDoneAction {
  return { type: Types.SAVE_DONE, payload: { completed } };
}

export function loadData(_id: string): LoadDataAction {
  return { type: Types.LOAD_DATA, payload: { _id } };
}

export function initFormData(data?: any): InitFormAction {
  return { type: Types.INIT_FORM, payload: { data } };
}

export function setCompleted(completed?: any): SetCompletedAction {
  return { type: Types.SET_COMPLETED, payload: { completed } };
}

export function clearState(): ClearStateAction {
  return { type: Types.CLEAR_STATE };
}

export function showConfirmPopup(data: any): ShowConfirmPopupAction {
  return { type: Types.SHOW_CONFIRM_POPUP, payload: { data } };
}

export function hideConfirmPopup(): HideConfirmPopupAction {
  return { type: Types.HIDE_CONFIRM_POPUP };
}
