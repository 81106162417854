import { callApi } from 'utils/service.util';

const PRODUCT_API_ENDPOINT = 'v1/product-file/v1';
const headers = {
  'Content-Type': 'multipart/form-data',
};

export const getFiles = async (id: any) => {
  return callApi({
    method: 'get',
    url: `${PRODUCT_API_ENDPOINT}/files/${id}`,
  });
};

export const uploadFiles = async (
  data: any,
  controller: any,
  onUploadProgress: any
) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/upload-files`,
    data,
    signal: controller && controller.signal,
    onUploadProgress,
    headers,
  });
};

export const updateFiles = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/update-files`,
    data,
  });
};

export const createFolders = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/create-folders`,
    data,
  });
};

export const deleteFiles = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/delete-files`,
    data,
  });
};

export const downloadFiles = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/download-files`,
    data,
  });
};

export const getShareFiles = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/get-share-files`,
    data,
  });
};

export const shareFiles = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/share-files`,
    data,
  });
};

export const stopShareFiles = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/stop-share-files`,
    data,
  });
};

export const getFileAccess = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/get-file-access`,
    data,
  });
};

export const getAffiliateFiles = async (id: any) => {
  return callApi({
    method: 'get',
    url: `${PRODUCT_API_ENDPOINT}/affiliate/files/${id}`,
    headers: {
      Affiliate: window.localStorage.getItem('affiliate-email') || '',
    },
  });
};

export const downloadAffiliateFiles = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/affiliate/download-files`,
    headers: {
      Affiliate: window.localStorage.getItem('affiliate-email') || '',
    },
    data,
  });
};
