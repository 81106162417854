import { IFeedbackModel } from '../feedback-create/feedback-create.constant';
import {
  Types,
  SetDataAction,
  LoadDataAction,
  BeginDeleteDataAction,
  EndDeleteDataAction,
  HideDeletePopupAction,
  ShowDeletePopupAction,
} from './feedback-detail.constant';

export function showDeletePopup(data?: IFeedbackModel): ShowDeletePopupAction {
  return { type: Types.SHOW_DELETE_POPUP, payload: { data } };
}

export function hideDeletePopup(): HideDeletePopupAction {
  return { type: Types.HIDE_DELETE_POPUP };
}

export function setData(data: IFeedbackModel): SetDataAction {
  return { type: Types.SET_DATA, payload: { data } };
}

export function loadData(feedbackId: string): LoadDataAction {
  return { type: Types.LOAD_DATA, payload: { feedbackId } };
}

export function deleteData(feedbackId: string): BeginDeleteDataAction {
  return { type: Types.BEGIN_DELETE_DATA, payload: { feedbackId } };
}

export function completeDelete(): EndDeleteDataAction {
  return { type: Types.END_DELETE_DATA };
}
