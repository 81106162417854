export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBchb41h3UHS6zcGERbC4Y-w62jQlWuOGE',
  authDomain: 'product-request-3e82a.firebaseapp.com',
  databaseURL: 'https://product-request-3e82a.firebaseio.com',
  projectId: 'product-request-3e82a',
  storageBucket: 'product-request-3e82a.appspot.com',
  messagingSenderId: '768479551729',
  appId: '1:768479551729:web:926b694fb7d318e8a7c950',
  measurementId: 'G-QMF1M1FHMV',
};
