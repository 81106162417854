import produce from 'immer';
import _get from 'lodash/get';

import { Actions, INotificationState, Types } from './notification.constant';

const initialState: INotificationState = {
  count: 0,
  data: [],
  firstLoad: true,
  loading: true,
  showData: false,
  params: {
    page: 0,
    limit: 100,
  },
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.CHANGE_PARAMS:
      draft.loading = true;
      draft.params = { ...draft.params, ...action.payload.params };
      break;
    case Types.LOAD_DATA:
      draft.loading = true;
      break;
    case Types.SET_DATA:
      draft.data = _get(action, 'payload.data.data', []);
      draft.count = _get(action, 'payload.data.totalNew', 0);
      draft.loading = false;
      draft.firstLoad = false;
      break;
    case Types.LOAD_MORE:
      let data = _get(action, 'payload.data.data', []);
      data.forEach((d: any) => {
        draft.data.push(d);
      });
      draft.loading = false;
      break;
    case Types.SHOW_DATA:
      draft.showData = true;
      break;
    case Types.HIDE_DATA:
      draft.showData = false;
      break;
    case Types.UPDATE_NOTIFICATION:
      const ids = action.payload.ids;
      let newArr: any = draft.data.map((e: any) => {
        if (ids.includes(e._id)) {
          e.isRead = true;
        }
        return e;
      });
      draft.data = newArr;
      break;
  }
}, initialState);

export { initialState, reducer as default };
