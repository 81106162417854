import produce from 'immer';

import cloneDeep from 'lodash/cloneDeep';

import { IProductModel } from 'store/modules/product-item/product-item.constant';
import { getDestinations } from 'services/destination.service';

import { Actions, Types, IProductCreateState } from './product-create.constant';

const dataEmpty: IProductModel = {
  productBuildType: 'Source',
  generalName: '',
  marketingName: 'TBD',
  productImages: [],
  sku: '',
  product_costing: '',
  generalProductDescription: '',
  productFeatures: '',
  productUsage: '',
  packaging_dimension: '',
  packaging_weight: '',
  packaging_length: 0,
  packaging_width: 0,
  packaging_height: 0,
  product_weight: '',
  product_length: 0,
  product_width: 0,
  product_height: 0,
  category: '',
  mus: [''],
  requestedFeatures: '',
  jiraTicket: '',
  app_information: [''],
  live_urls: [],
  sample_delivered: false,
  wantSamples: true,
  samplesDestination: [],
  mvp_upsell: 'MVP',
  creative_brief: [''],
  packaging_graphic: 'Need Attention',
  packaging_link: '',
  packaging_images: [],
  packaging_languages: [],
  quick_start_link: [''],
  warranty_link: '',
  warranty_card_link: [''],
  warranty_domain_link: [''],
  warranty_slip: 'Need Attention',
  instruction_manual: 'Need Attention',
  im_link: '',
  competitorProductURLs: [''],
  research_link: [''],
  customer_service_link: 'Need Attention',
  tm_status: 'Need Attention',
  customer_service_feedback: [''],
  isTMFiled: true,
  trademark_status: 'Pending Application',
  trademark_country: [],
  design_patent: false,
  sketching: [],
  amazon_link: '',
  barcode: '',
  amazon_brand_registry: false,
  brand_storefront_urls_v2: [{ key: '', value: '' }],
  tm_geos_registered: [{ key: '', value: '' }],
  safety_link: [''],
  productRequestDescription: '',
  lead_time: '',
  daily_capacity: '',
  moq: 0,
  order_date: null,
  cargo_ready_date: null,
  inventory: 0,
  tooling_sign_off: 'Need Attention',
  cost_sign_off: 'Need Attention',
  remarks: '',
  product_3pl_land_cogs: '',
  product_azm_fba_land_cogs: '',
};

const initState: IProductCreateState = {
  data: cloneDeep(dataEmpty),
  steps: [1, 2, 3, 4],
  activeStep: 0,
  loading: true,
  showConfirmPopup: false,
  saving: false,
  completed: false,
  categories: [],
  destinations: getDestinations(),
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.MAKE_A_NEW:
      draft.completed = false;
      break;
    case Types.LOAD_DATA:
      draft.completed = false;
      draft.loading = true;
      break;
    case Types.INIT_FORM:
      draft.loading = false;
      if (action.payload.data) {
        draft.data = action.payload.data;
      } else {
        draft.data = cloneDeep(dataEmpty);
      }
      draft.categories = action.payload.categories || [];
      break;
    case Types.SET_DATA:
      draft.loading = false;
      if (action.payload.data) {
        draft.data = action.payload.data;
      } else {
        draft.data = cloneDeep(dataEmpty);
      }
      break;
    case Types.SAVE_DATA:
      draft.saving = true;
      draft.data = action.payload.data;
      draft.showConfirmPopup = false;
      break;
    case Types.SAVE_DONE:
      draft.completed = action.payload.completed;
      draft.saving = false;
      if (!draft.data._id && action.payload.completed) {
        draft.data = cloneDeep(dataEmpty);
      }
      draft.activeStep = 0;

      break;
    case Types.GO_NEXT_TAB:
      draft.activeStep += 1;
      break;
    case Types.GO_BACK_TAB:
      draft.activeStep -= 1;
      break;
    case Types.SHOW_CONFIRM_POPUP:
      draft.showConfirmPopup = true;
      draft.data = action.payload.data;
      break;
    case Types.HIDE_CONFIRM_POPUP:
      draft.showConfirmPopup = false;
      break;
    case Types.CLEAR_STATE:
      return initState;
  }
}, initState);

export { initState, reducer as default };
