import produce from 'immer';

import { Actions, IProductFileState, Types } from './product-file.constant';

const initialState: IProductFileState = {
  affiliate: !!window.location.pathname.match(/^\/affiliates\/detail\//),
  loading: {},
  path: '/',
  data: [],
  popup: {},
  selectedFiles: [],
  fileExtraMap: {},
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.SET_LOADING:
      Object.assign(draft.loading, action.payload.data);
      break;
    case Types.SET_DATA:
      const { data } = action.payload;
      draft.data = data;
      break;
    case Types.SET_PATH:
      draft.path = action.payload.data;
      break;
    case Types.SET_POPUP:
      Object.assign(draft.popup, action.payload.data);
      break;
    case Types.SELECT_FILES:
      draft.selectedFiles = action.payload.data.selectedFiles;
      break;
    case Types.SET_FILE_EXTRA_MAP:
      Object.assign(draft.fileExtraMap, action.payload.data);
      break;
    case Types.RESET_STATE:
      Object.entries(JSON.parse(JSON.stringify(initialState))).forEach(
        ([k, v]) => {
          draft[k] = v;
        },
        []
      );
      break;
  }
}, initialState);

export { initialState, reducer as default };
