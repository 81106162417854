import {
  Types,
  SetParamsAction,
  SetDataAction,
  LoadDataAction,
  SetLoadingAction,
  SetEmailAction,
  ClearStateAction,
} from './affiliate.constant';

export function setParams(payload: any): SetParamsAction {
  return { type: Types.SET_PARAMS, payload };
}
export function setData(payload: any): SetDataAction {
  return { type: Types.SET_DATA, payload };
}
export function loadData(payload?: any): LoadDataAction {
  return { type: Types.LOAD_DATA, payload };
}
export function setLoading(payload: any): SetLoadingAction {
  return { type: Types.SET_LOADING, payload };
}
export function setEmail(payload: any): SetEmailAction {
  return { type: Types.SET_EMAIL, payload };
}
export function clearState(payload: any): ClearStateAction {
  return { type: Types.CLEAR_STATE, payload };
}
