import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { INotificationState } from './notification.constant';

export const notificationSelector = (state: IState) => {
  return state.notification;
};

export const firstLoadSelector = (state: IState) => {
  return state.notification.firstLoad;
};

export const paramsSelector = createSelector(
  notificationSelector,
  (notification: INotificationState): any => notification.params
);

export const countSelector = createSelector(
  notificationSelector,
  (notification: INotificationState): any => notification.count
);

export const countUnreadSelector = createSelector(
  notificationSelector,
  (notification: INotificationState): any => {
    let count = 0;
    notification.data.forEach((n: any) => {
      if (!n.isRead) {
        count++;
      }
    });

    return count;
  }
);
