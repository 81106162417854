import { IProductModel } from 'store/modules/product-item/product-item.constant';

export interface IProductListState {
  count: number;
  data: any[];
  firstLoad: boolean;
  loading: boolean;
  params: any;
  branch: any;
}

export enum Types {
  LOAD_BRANCH_LIST = '[PRODUCT LIST] LOAD_BRANCH_LIST',
  SET_BRANCH_LIST = '[PRODUCT LIST] SET_BRANCH_LIST',
  CHANGE_PARAMS = '[PRODUCT LIST] CHANGE PARAMS',
  SET_DATA = '[PRODUCT LIST] SET_DATA',
  LOAD_DATA = '[PRODUCT LIST] LOAD_DATA',
  SET_LOADING = '[PRODUCT LIST] SET_LOADING',
  CLEAR_STATE = '[PRODUCT LIST] CLEAR_STATE',
  CLEAR_PARAMS = '[PRODUCT LIST] CLEAR_PARAMS',
  CLEAR_BRANCH_PARAM = '[PRODUCT LIST] CLEAR_BRANCH_PARAM',
}

export interface ClearStateAction {
  type: typeof Types.CLEAR_STATE;
}

export interface ClearParamsAction {
  type: typeof Types.CLEAR_PARAMS;
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    loading: boolean;
  };
}

export interface ChangeParamsAction {
  type: typeof Types.CHANGE_PARAMS;
  payload: {
    params: any;
  };
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: {
    params: any;
  };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: IProductModel[]; count: number };
}

export interface LoadBranchListAction {
  type: typeof Types.LOAD_BRANCH_LIST;
}

export interface ClearBranchParamAction {
  type: typeof Types.CLEAR_BRANCH_PARAM;
}

export interface SetBranchListAction {
  type: typeof Types.SET_BRANCH_LIST;
  payload: { data: any };
}

export type Actions =
  | LoadBranchListAction
  | SetBranchListAction
  | ClearStateAction
  | SetDataAction
  | LoadDataAction
  | ChangeParamsAction
  | ClearBranchParamAction
  | ClearParamsAction
  | SetLoadingAction;
