import { IProductModel } from 'store/modules/product-item/product-item.constant';

export interface IProductCreateState {
  data: IProductModel;
  steps: any;
  activeStep: number;
  loading: boolean;
  saving: boolean;
  completed: boolean;
  showConfirmPopup: boolean;
  categories: any;
  destinations: any;
}

export enum Types {
  SAVE_DATA = '[PRODUCT ITEM] SAVE DATA',
  INIT_FORM = '[PRODUCT ITEM] INIT FORM',
  SET_DATA = '[PRODUCT ITEM] SET DATA',
  SAVE_DONE = '[PRODUCT ITEM] SAVE DONE',
  MAKE_A_NEW = '[PRODUCT ITEM] MAKE A NEW',
  LOAD_DATA = '[PRODUCT ITEM] LOAD DATA',
  GO_NEXT_TAB = '[PRODUCT ITEM] GO NEXT TAB',
  GO_BACK_TAB = '[PRODUCT ITEM] BACK NEXT TAB',
  CLEAR_STATE = '[PRODUCT ITEM] CLEAR STATE',
  SHOW_CONFIRM_POPUP = '[PRODUCT ITEM] SHOW_CONFIRM_POPUP',
  HIDE_CONFIRM_POPUP = '[PRODUCT ITEM] HIDE_CONFIRM_POPUP',
}

export interface InitFormAction {
  type: typeof Types.INIT_FORM;
  payload: { data?: any; categories: any };
}

export interface SaveDataAction {
  type: typeof Types.SAVE_DATA;
  payload: { data: any };
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: { _id: any };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data?: IProductModel };
}

export interface MakeNewRequestAction {
  type: typeof Types.MAKE_A_NEW;
}

export interface GoNextTabAction {
  type: typeof Types.GO_NEXT_TAB;
}

export interface GoBackTabAction {
  type: typeof Types.GO_BACK_TAB;
}

export interface SaveDoneAction {
  type: typeof Types.SAVE_DONE;
  payload: { completed: boolean };
}

export interface ShowConfirmPopupAction {
  type: typeof Types.SHOW_CONFIRM_POPUP;
  payload: { data?: any };
}

export interface HideConfirmPopupAction {
  type: typeof Types.HIDE_CONFIRM_POPUP;
}

export interface ClearStateAction {
  type: typeof Types.CLEAR_STATE;
}

export type Actions =
  | InitFormAction
  | SaveDataAction
  | SaveDoneAction
  | MakeNewRequestAction
  | SetDataAction
  | LoadDataAction
  | GoNextTabAction
  | GoBackTabAction
  | ClearStateAction
  | ShowConfirmPopupAction
  | HideConfirmPopupAction;
