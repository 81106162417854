import React from 'react';
import classNames from 'clsx';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';

import styles from './text-field.module.scss';

export const FormLabel = ({
  required,
  label,
  className,
  gutterBottom,
}: any) => {
  return (
    <div
      className={clsx(styles.label, className, {
        [styles['label--gutterBottom']]: gutterBottom,
      })}
    >
      {label}

      {required && <div className={styles.require}>*</div>}
    </div>
  );
};

export function NumberField(props: any) {
  const {
    required,
    error,
    helperText,
    className,
    label,
    endAdornment,
    value,
    onChange,
    thousandSeparator,
    ...other
  } = props;

  return (
    <>
      {label && <FormLabel required={required} label={label} />}
      <div className={styles.container}>
        <NumberFormat
          {...other}
          value={value < 1 ? '' : value}
          className={classNames(className, styles.input, {
            [styles['input--icon']]: !!endAdornment,
            [styles['input--error']]: !!error,
            [styles['input--label']]: !!label,
          })}
          onValueChange={(values: any) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          allowNegative={false}
          thousandSeparator={thousandSeparator}
          isNumericString
        />
        {endAdornment && <div className={styles.icon}>{endAdornment}</div>}
      </div>
      {error && <div className={styles.error}>{helperText}</div>}
    </>
  );
}

NumberField.defaultProps = {
  thousandSeparator: true,
};

export function TextField({
  required,
  error,
  helperText,
  className,
  label,
  endAdornment,
  value,
  ...props
}: any) {
  return (
    <>
      {label && <FormLabel required={required} label={label} />}
      <div className={styles.container}>
        <input
          {...props}
          value={value || ''}
          className={classNames(className, styles.input, {
            [styles['input--icon']]: !!endAdornment,
            [styles['input--error']]: !!error,
            [styles['input--label']]: !!label,
          })}
        />
        {endAdornment && <div className={styles.icon}>{endAdornment}</div>}
      </div>
      {error && <div className={styles.error}>{helperText}</div>}
    </>
  );
}

TextField.defaultProps = {
  maxLength: 255,
};
