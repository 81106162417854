import {
  ChangeParamsAction,
  ClearStateAction,
  LoadDataAction,
  SetDataAction,
  SetLoadingAction,
  BeginDeleteDataAction,
  EndDeleteDataAction,
  HideDeletePopupAction,
  ShowDeletePopupAction,
  Types,
} from './product-research-list.constant';

export function setData(count: number, data: any): SetDataAction {
  return { type: Types.SET_DATA, payload: { count, data } };
}

export function loadData(params: any = {}): LoadDataAction {
  return { type: Types.LOAD_DATA, payload: { params } };
}

export function changeParams(params: any): ChangeParamsAction {
  return { type: Types.CHANGE_PARAMS, payload: { params } };
}

export function setLoading(loading: boolean): SetLoadingAction {
  return { type: Types.SET_LOADING, payload: { loading } };
}

export function clearState(): ClearStateAction {
  return { type: Types.CLEAR_STATE };
}

export function showDeletePopup(data: any): ShowDeletePopupAction {
  return { type: Types.SHOW_DELETE_POPUP, payload: { data } };
}

export function hideDeletePopup(): HideDeletePopupAction {
  return { type: Types.HIDE_DELETE_POPUP };
}

export function deleteData(
  itemId: string,
  fnCallback?: any
): BeginDeleteDataAction {
  return { type: Types.BEGIN_DELETE_DATA, payload: { itemId, fnCallback } };
}

export function completeDelete(): EndDeleteDataAction {
  return { type: Types.END_DELETE_DATA };
}
