import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { IProductSkuListState } from './product-sku-list.constant';

export const productSkuListSelector = (state: IState) => {
  return state.productSkuList;
};

export const paramsSelector = createSelector(
  productSkuListSelector,
  (productSkuList: IProductSkuListState): any => productSkuList.params
);

export const loadingSelector = createSelector(
  productSkuListSelector,
  (productSkuList: IProductSkuListState): any => productSkuList.loading
);

export const firstLoadSelector = createSelector(
  productSkuListSelector,
  (productSkuList: IProductSkuListState): any => productSkuList.firstLoad
);

export const searchUISelector = createSelector(
  productSkuListSelector,
  (productSkuList: IProductSkuListState): any => {
    return {
      count: productSkuList.count,
      value: productSkuList.params.search,
      isOwner: productSkuList.params.isOwner,
      loading: productSkuList.loading,
    };
  }
);

export const listUISelector = createSelector(
  productSkuListSelector,
  (productSkuList: IProductSkuListState): any => {
    return {
      count: productSkuList.count,
      params: productSkuList.params,
      loading: productSkuList.loading,
      data: productSkuList.data,
      firstLoad: productSkuList.firstLoad,
    };
  }
);
