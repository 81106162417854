import {
  Types,
  SetLoadingAction,
  LoadDataAction,
  SetDataAction,
  SetPopupAction,
  SetPathAction,
  UploadFilesAction,
  CancelUploadFilesAction,
  UpdateFilesAction,
  CreateFolderAction,
  SelectFilesAction,
  DeleteFilesAction,
  DownloadFilesAction,
  ShareFilesAction,
  GetShareFilesAction,
  StopShareFilesAction,
  GetFileExtraAction,
  SetFileExtraMapAction,
  OpenViewMoreAction,
  ResetStateAction,
} from './product-file.constant';

export function setLoading(data: any): SetLoadingAction {
  return { type: Types.SET_LOADING, payload: { data } };
}

export function loadData(data: any): LoadDataAction {
  return { type: Types.LOAD_DATA, payload: { data } };
}

export function setData(data: any): SetDataAction {
  return { type: Types.SET_DATA, payload: { data } };
}

export function setPath(data: any): SetPathAction {
  return { type: Types.SET_PATH, payload: { data } };
}

export function setPopup(data: any): SetPopupAction {
  return { type: Types.SET_POPUP, payload: { data } };
}

export function uploadFiles(data: any): UploadFilesAction {
  return { type: Types.UPLOAD_FILES, payload: { data } };
}

export function cancelUploadFiles(data: any): CancelUploadFilesAction {
  return { type: Types.CANCEL_UPLOAD_FILES, payload: { data } };
}

export function updateFiles(data: any): UpdateFilesAction {
  return { type: Types.UPDATE_FILES, payload: { data } };
}

export function createFolder(data: any): CreateFolderAction {
  return { type: Types.CREATE_FOLDER, payload: { data } };
}

export function selectFiles(data: any): SelectFilesAction {
  return { type: Types.SELECT_FILES, payload: { data } };
}

export function deleteFiles(data: any): DeleteFilesAction {
  return { type: Types.DELETE_FILES, payload: { data } };
}

export function downloadFiles(data: any): DownloadFilesAction {
  return { type: Types.DOWNLOAD_FILES, payload: { data } };
}

export function shareFiles(data: any): ShareFilesAction {
  return { type: Types.SHARE_FILES, payload: { data } };
}

export function getShareFiles(data: any): GetShareFilesAction {
  return { type: Types.GET_SHARE_FILES, payload: { data } };
}

export function stopShareFiles(data: any): StopShareFilesAction {
  return { type: Types.STOP_SHARE_FILES, payload: { data } };
}

export function getFileExtra(data: any): GetFileExtraAction {
  return { type: Types.GET_FILE_EXTRA, payload: { data } };
}

export function setFileExtraMap(data: any): SetFileExtraMapAction {
  return { type: Types.SET_FILE_EXTRA_MAP, payload: { data } };
}

export function openViewMore(data: any): OpenViewMoreAction {
  return { type: Types.OPEN_VIEW_MORE, payload: { data } };
}
export function resetState(): ResetStateAction {
  return { type: Types.RESET_STATE };
}
