import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';

import { Actions, IUserListState, Types } from './user-list.constant';

const initialState: IUserListState = {
  count: 0,
  data: [],
  openDeleteDialog: false,
  selectedData: null,
  deleting: false,
  firstLoad: true,
  updating: false,
  loading: true,
  params: {
    page: 0,
    limit: 10,
    search: '',
    status: [],
    sort: { firstName: 0 },
    order: { orderBy: 'firstName', order: 'asc' },
    headCells: [
      {
        id: 'firstName',
        label: 'name',
        width: '25%',
      },
      {
        id: 'email',
        label: 'email',
        width: '15%',
      },
      {
        id: 'roles',
        label: 'role',
        width: '20%',
      },
      {
        id: 'department',
        label: 'department',
        width: '15%',
        hideSortIcon: true,
      },

      {
        id: 'isActive',
        label: 'status',
        width: '20%',
      },
      {
        id: 'actions',
        label: 'actions',
        hideSortIcon: true,
        width: '5%',
      },
    ],
  },
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.CHANGE_PARAMS:
      draft.loading = true;
      draft.params = { ...draft.params, ...action.payload.params };
      break;
    case Types.UPDATE_DATA:
      draft.updating = true;
      break;
    case Types.LOAD_DATA:
      draft.loading = true;
      break;
    case Types.SET_LOADING:
      draft.loading = action.payload.loading;
      break;
    case Types.SET_DATA:
      draft.data = action.payload.data;
      draft.count = Math.ceil(action.payload.count / draft.params.limit);
      draft.loading = false;
      draft.updating = false;
      draft.selectedData = null;
      draft.deleting = false;
      draft.openDeleteDialog = false;
      draft.firstLoad = false;
      break;
    case Types.OPEN_DELETE_DIALOG:
      draft.selectedData = action.payload.data;
      draft.openDeleteDialog = true;
      break;

    case Types.BEGIN_DELETE_DATA:
      draft.deleting = true;
      break;
    case Types.HIDE_DELETE_DIALOG:
      draft.selectedData = null;
      draft.deleting = false;
      draft.updating = false;
      draft.openDeleteDialog = false;
      break;
    case Types.RESET_DATA:
      return cloneDeep(initialState);
  }
}, initialState);

export { initialState, reducer as default };
