import axios from 'axios';
import { ENV } from 'constants/common';
import _get from 'lodash/get';

import { callApi } from 'utils/service.util';

import { firebase, provider } from './firebase.service';
import { handleDataError } from '../utils/http-interceptors/request';
import { getToken } from '../utils/auth.util';

const LOGIN = '/auth/v1';
const REGISTER = '/auth/v1/register';
const UPDATE_PASSWORD = 'v1/auth/v1/update-password';
const CONFIRM_PASSWORD = '/auth/v1/confirm-reset';
const RESET_PASSWORD = '/auth/v1/reset-password';
const LOGIN_EMAIL = '/auth/v1/email';
const ME = 'v1/auth/v1/me';

const apiAxios = axios.create({
  baseURL: `${ENV.REACT_APP_BACKEND_URL}/v1`,
  timeout: 30000,
  maxRedirects: 5,
  headers: {
    // @ts-ignore
    common: {},
    'Content-Type': 'application/json',
  },
});

const formatUser = (data: any) => {
  try {
    if (!data.user.name) {
      data.user.name = `${_get(data, 'user.firstName', '')} ${_get(
        data,
        'user.lastName',
        ''
      )}`;
    }
  } catch (e) {
    console.error(e);
  }

  return data;
};

const logOut = () => {
  return new Promise<void>(function(resolve, reject) {
    try {
      firebase.auth().signOut();
    } catch (e) {
      console.error(e);
    }
    resolve();
  });
};

const login = (domains: string[]) => {
  return new Promise(function(resolve, reject) {
    try {
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(data => {
          // const user: any = data.additionalUserInfo?.profile as any;
          // const { hd } = user;
          // if (!domains.includes(hd)) {
          //   // eslint-disable-next-line no-throw-literal
          //   reject({ name: 'Unauthorized', message: 'Unauthorized' });
          // }

          const idToken = firebase.auth().currentUser?.getIdToken();

          resolve(idToken);
        })
        .catch(e => {
          resolve(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const loginGoogle = async (domains: string[]) => {
  await logOut();
  let resToken: any;
  try {
    resToken = await login(domains);
  } catch (e) {
    throw e;
  }

  if (resToken.code === 'auth/popup-closed-by-user') {
    let error = new Error();
    error.name = resToken.code;
    error.message = resToken.message;

    throw error;
  }

  let timezone = '';
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    console.error(e);
  }

  const res: any = await apiAxios
    .post(LOGIN, { idToken: resToken, timezone })
    .catch(e => {
      const data = handleDataError(e);

      return { error: true, data };
    });

  if (res.error) {
    let error = new Error();
    error.message = res.data.message;
    throw error;
  }

  res.data.user.name = `${_get(res.data.user, 'firstName', '')} ${_get(
    res.data.user,
    'lastName',
    ''
  )}`;

  return res.data;
};

export const refreshToken = async () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(idToken => apiAxios.post(LOGIN, { idToken }))
          .then(response => {
            // @ts-ignore
            apiAxios.defaults.headers.authorization = response.data.token;
            resolve(formatUser(response.data));
          });
      } else {
        resolve(null);
      }
    });
  });
};

export const register = async (data: any) => {
  return apiAxios.post(REGISTER, data).catch(e => {
    const data = handleDataError(e);

    throw data;
  });
};

export const loginEmail = async (data: any) => {
  return apiAxios.post(LOGIN_EMAIL, data).catch(e => {
    const data = handleDataError(e);

    throw data;
  });
};

export const updatePassword = async ({ new_password, old_password }: any) => {
  return callApi({
    method: 'PUT',
    url: UPDATE_PASSWORD,
    data: { new_password, old_password, token: getToken() },
  });
};

export const resetPassword = async (data: any) => {
  return apiAxios.put(RESET_PASSWORD, data).catch(e => {
    const data = handleDataError(e);

    throw data;
  });
};

export const confirmPassword = async (data: any) => {
  return apiAxios.put(CONFIRM_PASSWORD, data).catch(e => {
    const data = handleDataError(e);

    throw data;
  });
};

export const getMe = async () => {
  return callApi({
    method: 'GET',
    url: ME,
  });
};

export const updateMe = async (data: any) => {
  return callApi({
    method: 'PUT',
    url: ME,
    data,
  });
};
