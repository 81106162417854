export interface INotificationState {
  count: number;
  data: any[];
  loading: boolean;
  firstLoad: boolean;
  showData: boolean;
  params: {
    page: number;
    limit: number;
  };
}

export enum Types {
  SET_DATA = '[NOTIFICATION] SET_DATA',
  LOAD_MORE = '[NOTIFICATION] LOAD_MORE',
  LOAD_DATA = '[NOTIFICATION] LOAD_DATA',
  SHOW_DATA = '[NOTIFICATION] SHOW_DATA',
  HIDE_DATA = '[NOTIFICATION] HIDE_DATA',
  CHANGE_PARAMS = '[NOTIFICATION] CHANGE_PARAMS',
  READ_NOTIFICATION = '[NOTIFICATION] READ_NOTIFICATION',
  UPDATE_NOTIFICATION = '[NOTIFICATION] UPDATE_NOTIFICATION',
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: any[] };
}

export interface LoadMoreAction {
  type: typeof Types.LOAD_MORE;
  payload: { data: any[] };
}

export interface ShowDataAction {
  type: typeof Types.SHOW_DATA;
}

export interface HideDataAction {
  type: typeof Types.HIDE_DATA;
}

export interface ChangeParamsAction {
  type: typeof Types.CHANGE_PARAMS;
  payload: {
    params: any;
  };
}

export interface ReadNotificationAction {
  type: typeof Types.READ_NOTIFICATION;
  payload: {
    ids: string[];
    fnCallback: any;
  };
}

export interface UpdateNotificationAction {
  type: typeof Types.UPDATE_NOTIFICATION;
  payload: {
    ids: string[];
  };
}

export type Actions =
  | SetDataAction
  | LoadMoreAction
  | LoadDataAction
  | HideDataAction
  | ShowDataAction
  | UpdateNotificationAction
  | ReadNotificationAction
  | ChangeParamsAction;
