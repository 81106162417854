import produce from 'immer';

import { Actions, IAffiliateState, Types } from './affiliate.constant';

const initialState: IAffiliateState = {
  count: 0,
  data: [],
  loading: true,
  params: {
    page: 0,
    limit: 20,
    sort: { updatedAt: -1 },
  },
  email: window.localStorage.getItem('affiliate-email') || '',
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.SET_PARAMS:
      draft.params = { ...draft.params, ...action.payload };
      break;
    case Types.SET_LOADING:
      draft.loading = action.payload;
      break;
    case Types.SET_DATA:
      draft.data = action.payload.data;
      draft.count = Math.ceil(action.payload.count / draft.params.limit);
      break;
    case Types.SET_EMAIL:
      draft.email = action.payload;
      window.localStorage.setItem('affiliate-email', draft.email);
      break;
    case Types.CLEAR_STATE:
      draft = JSON.parse(JSON.stringify(initialState));
      break;
  }
}, initialState);

export { initialState, reducer as default };
