import produce from 'immer';

import {
  Actions,
  IProductSkuListState,
  Types,
} from './product-sku-list.constant';

const initialState: IProductSkuListState = {
  count: 0,
  data: [],
  firstLoad: true,
  loading: true,
  params: {
    page: 0,
    limit: 20,
    search: '',
    status: -1,
    isOwner: false,
    marketingName: null,
    sort: { updatedAt: -1 },
  },
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.LOAD_DATA:
    case Types.CHANGE_PARAMS:
      draft.loading = true;
      draft.params = { ...draft.params, ...action.payload.params };
      break;
    case Types.SET_LOADING:
      draft.loading = action.payload.loading;
      break;
    case Types.SET_DATA:
      draft.data = action.payload.data;
      draft.count = Math.ceil(action.payload.count / draft.params.limit);
      draft.loading = false;
      draft.firstLoad = false;
      break;
    case Types.CLEAR_STATE:
      draft.loading = true;
      draft.firstLoad = true;
      break;
    case Types.CLEAR_PARAMS:
      draft.params = {
        page: 0,
        limit: 20,
        search: '',
        status: -1,
        isOwner: false,
        marketingName: null,
        sort: { updatedAt: -1 },
      };
      break;
    // @ts-ignore
    case '[AUTH] SET_USER':
      return initialState;
  }
}, initialState);

export { initialState, reducer as default };
