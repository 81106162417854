import {
  BeginShareDataAction,
  HideSharePopupAction,
  ShowSharePopupAction,
  Types,
} from './product-share.constant';

export function showSharePopup(data: any): ShowSharePopupAction {
  return { type: Types.SHOW_SHARE_POPUP, payload: { data } };
}

export function hideSharePopup(): HideSharePopupAction {
  return { type: Types.HIDE_SHARE_POPUP };
}

export function shareData(data: any): BeginShareDataAction {
  return { type: Types.BEGIN_SHARE_DATA, payload: { data } };
}
