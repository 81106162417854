import { call, put, select, takeLatest } from 'redux-saga/effects';
import _size from 'lodash/size';

import {
  getCustomerService,
  addCustomerService,
  updateCustomerService,
} from 'services/customer-service.service';
import { pushErrorMessage } from 'store/modules/snackbar/snackbar.action';

import {
  LoadDataAction,
  SaveDataAction,
  Types,
} from './customer-service.constant';
import {
  initFormData,
  saveDone,
} from '../customer-service/customer-service.action';
import _get from 'lodash/get';
import { permissionsSelector } from '../auth/auth.selector';

function* loadProduct(_id: string) {
  if (!_id) {
    return null;
  }

  // @ts-ignore
  let { product_customer_service: data, product_marketing } = yield call(
    getCustomerService,
    _id
  );

  return {
    _id,
    generalName: _get(product_marketing, 'product_marketing_name', ''),
    consumer_feedback_v2: data.consumer_feedback_v2,
    result_consolidate_v2: data.result_consolidate_v2,
    review_source_v2: data.review_source_v2,
  };
}

function* watchLoadData(action: LoadDataAction) {
  const { _id } = action.payload;

  let data: any;
  try {
    // @ts-ignore
    data = yield call(loadProduct, _id);
  } catch (error) {
    yield put(pushErrorMessage(error));
  }
  yield put(initFormData(data));
}

export const formatBody = (data: any, fields: any) => {
  const formData: any = {};

  Object.keys(data).forEach(function (key) {
    if (
      key === '_id' ||
      key === 'generalName' ||
      (fields && fields.length > 0 && !fields.includes(key))
    ) {
      return;
    }

    const value = data[key];

    formData[key] = (value || []).filter((e: any) => _size(e) > 0);
  });

  return { formData };
};

function* watchSaveData(action: SaveDataAction) {
  let completed = true;
  try {
    // @ts-ignore
    const {
      productCustomerService: { fields },
    } = yield select(permissionsSelector);
    const { product, ...data } = action.payload.data;

    const { formData } = formatBody(data, fields);

    if (data._id) {
      yield call(updateCustomerService, data._id, formData);
    } else {
      yield call(addCustomerService, product._id, formData);
    }
  } catch (error) {
    yield put(pushErrorMessage(error));

    completed = false;
  }

  yield put(saveDone(completed));
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.SAVE_DATA, watchSaveData);
}
