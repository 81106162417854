import { BlobServiceClient } from '@azure/storage-blob';
import { ObjectID } from 'bson';
import mime from 'mime-types';

import { callApi } from '../utils/service.util';

const UPLOAD_API_ENDPOINT = '/v1/upload';

export const upload = async (containerName: string) => {
  //
  return callApi({
    method: 'post',
    url: UPLOAD_API_ENDPOINT,
    data: { containerName },
  });
};

export const updateImage = async (data: any, timeId: any, azure: any) => {
  if (!data || data.length < 1) {
    return [];
  }

  const arr = [];

  const promises = [];

  for (let i = 0; i < data.length; i++) {
    const file = data[i].file;

    const names = file.name.split('.');
    const name = names.join(`${timeId}_.`);

    const url = `${azure.url}/images-original/${name}`;

    const image = {
      _id: new ObjectID(),
      originalname: name,
      encoding: '',
      mimetype: mime.lookup(name),
      original: url,
      thumbnail: url,
      createdAt: new Date().toString(),
    };
    arr.push(image);

    // Create a new BlobServiceClient
    const blobServiceClient = new BlobServiceClient(
      `${azure.url}?${azure.sas}`
    );

    // Get a container client from the BlobServiceClient
    const containerClient =
      blobServiceClient.getContainerClient('images-original');

    const blockBlobClient = containerClient.getBlockBlobClient(name);
    promises.push(blockBlobClient.uploadBrowserData(file));
  }

  await Promise.all(promises);

  return arr;
};
