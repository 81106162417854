import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { ICategoryListState } from './category-list.constant';

export const categoryListSelector = (state: IState) => {
  return state.categoryList;
};

export const paramsSelector = createSelector(
  categoryListSelector,
  (categoryList: ICategoryListState): any => categoryList.params
);

export const firstLoadSelector = createSelector(
  categoryListSelector,
  (categoryList: ICategoryListState): any => categoryList.firstLoad
);

export const deletePopupSelector = createSelector(
  categoryListSelector,
  (categoryList: ICategoryListState): any => ({
    data: categoryList.selectedData,
    open: categoryList.openDeleteDialog,
    deleting: categoryList.deleting,
  })
);

export const editPopupSelector = createSelector(
  categoryListSelector,
  (categoryList: ICategoryListState): any => ({
    data: categoryList.selectedData || {},
    open: categoryList.openEditDialog,
    updating: categoryList.updating,
  })
);
