import _get from 'lodash/get';
import _size from 'lodash/size';
import _keyBy from 'lodash/keyBy';

import { IState } from 'store/constants';
import { IAuth } from 'store/modules/auth/auth.constant';

import { createSelector } from 'reselect';
import { abilityFactory } from 'casl/casl';
import { ENTITY, ACTION, ROLE_ENUM } from 'casl/common.casl';
import { PRODUCT_STATUS } from '../../../constants/common';

export const authSelector = (state: IState) => {
  return state.auth;
};

export const hasLoginSelector = createSelector(
  authSelector,
  (auth: IAuth) => auth.hasLogin
);

export const rolesSelector = createSelector(authSelector, (auth: IAuth) =>
  _get(auth, 'user.roles', [])
);

export const userSelector = createSelector(
  authSelector,
  (auth: IAuth): any => auth.user || {}
);

const findUpdateFields = (name: string, permissions: any = []) => {
  const item = permissions.find(
    (e: any) => _get(e, '[0]') === 'UPDATE' && _get(e, '[1]') === name
  );
  if (_size(item) < 3) {
    return [];
  }

  return _get(item, '[2]', []);
};

export const permissionsSelector = createSelector(
  authSelector,
  (auth: IAuth) => {
    const user: any = _get(auth, 'user', {});
    const permissions = _get(auth, 'user.permissions', []);
    const roles = _get(auth, 'user.roles', []);
    const isAdmin = roles.includes(ROLE_ENUM.ADMIN);
    const isSuperAdmin = roles.includes(ROLE_ENUM.SUPERADMIN);
    const isUser = roles.includes(ROLE_ENUM.USER);
    const isCLevel = roles.includes(ROLE_ENUM.C_LEVEL);
    const isUnassigned =
      isUser && (!user || !user.department || !user.department.length);

    let viewCost = isSuperAdmin;
    if (!viewCost) {
      viewCost =
        user &&
        user.department &&
        user.department.includes('MARKETING_OPERATIONS');
    }

    const criticalReport = {
      read: true,
      create: false,
      delete: false,
    };

    if (
      isSuperAdmin ||
      (user && user.department && user.department.includes('BER'))
    ) {
      criticalReport.create = true;
      criticalReport.delete = true;
    }

    const ability = abilityFactory(user, permissions);

    const manageProduct = ability.can(ACTION.MANAGE, ENTITY.Product);
    const createProduct = ability.can(ACTION.CREATE, ENTITY.Product);
    let productFields = findUpdateFields(
      ENTITY.Product,
      permissions.USER ||
        permissions.MARKETING_OPERATIONS ||
        permissions.ADMIN ||
        permissions.C_LEVEL
    );
    if (!productFields || productFields.length < 1) {
      productFields = [
        'type',
        'amazon_link',
        'amazon_link_v2',
        'registered_owner',
        'registration_no',
        'registered_date',
        'barcode',
        'amazon_brand_registry',
        'amazon_page_assets',
        'amazon_seo_research',
        'amazon_compliance',
        'brand_storefront_urls_v2',
        'customer_service_link',
        'customer_service_feedback',
        'customer_service_feedback_v2',
        'sku',
        'tracking_sample',
        'sale_region',
        'sale_region_remarks',
        'generalProductDescription',
        'productFeatures',
        'productUsage',
        'packaging_dimension',
        'class_and_goods',
        'tm_registered_eta',
        'tm_geos_registered',
        'category',
        'requestedFeatures',
        'jiraTicket',
        'jiraTicket_v2',
        'app_information',
        'app_information_v2',
        'isTMFiled',
        'trademark_status',
        'trademark_country',
        'design_patent',
        'sketching',
        'safety_link',
        'compliance_booklet',
        'compliance_slide',
        'disclaimer',
        'legal_notes',
        'compliance_notes',
        'safety_link_v2',
        'sample_delivered',
        'offer_link',
        'wantSamples',
        'samplesDestination',
        'mvp_upsell',
        'creative_brief',
        'creative_brief_v2',
        'packaging_graphic',
        'packaging_images',
        'packaging_languages',
        'quick_start_link',
        'quick_start_link_v2',
        'warranty_card_link',
        'warranty_card_link_v2',
        'warranty_domain_link',
        'warranty_domain_link_v2',
        'warranty_slip',
        'instruction_manual',
        'productBuildType',
        'lead_time',
        'daily_capacity',
        'moq',
        'order_date',
        'cargo_ready_date',
        'inventory',
        'tooling_sign_off',
        'cost_sign_off',
        'remarks',
        'creative_product_intro',
        'creative_market_position',
        'creative_sample_pitch',
        'creative_mus',
        'creative_product_design',
        'creative_top_key_selling',
        'creative_target_demographic',
        'creative_competitor_analysis',
        'creative_potential_presale_angle',
        'creative_market_trends',
        'creative_compliance_guidelines',
        'creative_notes',
        'creative_status',
        'creative_other_remarks',
        'competitorProductURLs',
        'competitorProductURLs_v2',
        'research_link',
        'research_link_v2',
        'research_competitors',
        'research_amazon',
        'generalName',
        'marketingName',
        'productImages',
        'mus',
        'mus_v2',
        'research_summary',
        'research_detail',
        'other_research_link_v2',
        'status_product_development',
        'status_legal_compliance',
        'status_amazon',
        'status_traffic_marketing',
        'product_length',
        'product_width',
        'product_height',
        'product_weight',
        'product_weight_v2',
        'packaging_length',
        'packaging_width',
        'packaging_height',
        'packaging_weight',
        'packaging_weight_v2',
        'packaging_link',
        'warranty_link',
        'im_link',
        'product_costing',
        'affiliate_mode',
        'affiliate_offer_name',
        'affiliate_offer_description',
        'product_3pl_land_cogs',
        'product_azm_fba_land_cogs',
      ];
    }

    const manageProductMarketing = ability.can(
      ACTION.MANAGE,
      ENTITY.Product_Marketing
    );
    const createProductMarketing = ability.can(
      ACTION.CREATE,
      ENTITY.Product_Marketing
    );
    const productMarketingFields = findUpdateFields(
      ENTITY.Product_Marketing,
      permissions.USER
    );

    const manageProductLegal = ability.can(ACTION.MANAGE, ENTITY.Product_Legal);
    const createProductLegal = ability.can(ACTION.CREATE, ENTITY.Product_Legal);
    const productLegalFields = findUpdateFields(
      ENTITY.Product_Legal,
      permissions.USER
    );

    const manageProductMarketResearch = ability.can(
      ACTION.MANAGE,
      ENTITY.Product_Market_Research
    );
    const createProductMarketResearch = ability.can(
      ACTION.CREATE,
      ENTITY.Product_Market_Research
    );
    const productMarketResearchFields = findUpdateFields(
      ENTITY.Product_Market_Research,
      permissions.USER
    );

    const manageProductCustomerService = ability.can(
      ACTION.MANAGE,
      ENTITY.Product_Customer_Service
    );
    const createProductCustomerService = ability.can(
      ACTION.CREATE,
      ENTITY.Product_Customer_Service
    );
    const productCustomerServiceFields = findUpdateFields(
      ENTITY.Product_Customer_Service,
      permissions.USER
    );

    const readFeedback = (
      _get(permissions, 'Feedback[read:any]', []) || []
    ).includes('*');
    const updateFeedback = (
      _get(permissions, 'Feedback[update:own]', []) || []
    ).includes('*');
    const deleteFeedback = (
      _get(permissions, 'Feedback[delete:own]', []) || []
    ).includes('*');

    const manageCategory = ability.can(ACTION.MANAGE, ENTITY.Category);
    const createCategory = ability.can(ACTION.CREATE, ENTITY.Category);
    const readCategory = ability.can(ACTION.READ, ENTITY.Category);
    const updateCategory = ability.can(ACTION.UPDATE, ENTITY.Category);
    const deleteCategory = ability.can(ACTION.DELETE, ENTITY.Category);

    const manageUser = ability.can(ACTION.MANAGE, ENTITY.User);
    const createUser = ability.can(ACTION.CREATE, ENTITY.User);
    const readUser = ability.can(ACTION.READ, ENTITY.User);
    const updateUser = ability.can(ACTION.UPDATE, ENTITY.User);
    const deleteUser = ability.can(ACTION.DELETE, ENTITY.User);

    const manageOffer = ability.can(ACTION.MANAGE, ENTITY.Offer);
    const createOffer = ability.can(ACTION.CREATE, ENTITY.Offer);
    const readOffer = ability.can(ACTION.READ, ENTITY.Offer);
    const updateOffer = ability.can(ACTION.UPDATE, ENTITY.Offer);
    const deleteOffer = ability.can(ACTION.DELETE, ENTITY.Offer);

    return {
      viewCost,
      ability,
      isUser,
      isAdmin,
      isSuperAdmin,
      isCLevel,
      isUnassigned,
      user,
      roleMap: _keyBy((user && user.roles) || [], i => i),
      departmentMap: _keyBy((user && user.department) || [], i => i),
      offer: {
        manage: manageOffer,
        read: readOffer,
        create: createOffer,
        update: updateOffer,
        delete: deleteOffer,
      },
      product: {
        fields: productFields,
        manage: manageProduct,
        create: createProduct,
        read: (data: any) => {
          return ability.can(ACTION.READ, {
            ...data,
            user: data.user && data.user._id,
            __typename: ENTITY.Product,
          });
        },
        update: (data: any) => {
          try {
            return ability.can(ACTION.UPDATE, {
              ...data,
              user: data.user && data.user._id,
              __typename: ENTITY.Product,
            });
          } catch (e) {
            return ability.can(ACTION.UPDATE, {
              ...data,
              user: data.user && data.user._id,
              __typename: ENTITY.Product,
            });
          }
        },
        approve: (data: any) => {
          return ability.can(ACTION.APPROVE, {
            ...data,
            user: data.user && data.user._id,
            __typename: ENTITY.Product,
          });
        },
        delete: (data: any) => {
          if (data.status !== PRODUCT_STATUS.Draft) {
            return false;
          }
          return ability.can(ACTION.DELETE, {
            ...data,
            user: data.user && data.user._id,
            __typename: ENTITY.Product,
          });
        },
      },
      criticalReport,
      productMarketing: {
        fields: productMarketingFields,
        manage: manageProductMarketing,
        create: createProductMarketing,
        read: (data: any) => {
          return ability.can(ACTION.READ, {
            ...data,
            __typename: ENTITY.Product_Marketing,
          });
        },
        update: (data: any) => {
          return ability.can(ACTION.UPDATE, {
            ...data,
            __typename: ENTITY.Product_Marketing,
          });
        },
        delete: (data: any) => {
          return ability.can(ACTION.DELETE, {
            ...data,
            __typename: ENTITY.Product_Marketing,
          });
        },
      },
      productLegal: {
        fields: productLegalFields,
        manage: manageProductLegal,
        create: createProductLegal,
        read: (data: any) => {
          return ability.can(ACTION.READ, {
            ...data,
            __typename: ENTITY.Product_Legal,
          });
        },
        update: (data: any) => {
          return ability.can(ACTION.UPDATE, {
            ...data,
            __typename: ENTITY.Product_Legal,
          });
        },
        delete: (data: any) => {
          return ability.can(ACTION.DELETE, {
            ...data,
            __typename: ENTITY.Product_Legal,
          });
        },
      },
      productMarketResearch: {
        fields: productMarketResearchFields,
        manage: manageProductMarketResearch,
        create: createProductMarketResearch,
        read: (data: any) => {
          return ability.can(ACTION.READ, {
            ...data,
            __typename: ENTITY.Product_Market_Research,
          });
        },
        update: (data: any) => {
          return ability.can(ACTION.UPDATE, {
            ...data,
            __typename: ENTITY.Product_Market_Research,
          });
        },
        delete: (data: any) => {
          return ability.can(ACTION.DELETE, {
            ...data,
            __typename: ENTITY.Product_Market_Research,
          });
        },
      },
      productCustomerService: {
        fields: productCustomerServiceFields,
        manage: manageProductCustomerService,
        create: createProductCustomerService,
        read: (data: any) => {
          return ability.can(ACTION.READ, {
            ...data,
            __typename: ENTITY.Product_Customer_Service,
          });
        },
        update: (data: any) => {
          return ability.can(ACTION.UPDATE, {
            ...data,
            __typename: ENTITY.Product_Customer_Service,
          });
        },
        delete: (data: any) => {
          return ability.can(ACTION.DELETE, {
            ...data,
            __typename: ENTITY.Product_Customer_Service,
          });
        },
      },
      feedback: {
        read: readFeedback,
        update: updateFeedback,
        delete: deleteFeedback,
      },
      category: {
        create: createCategory,
        manage: manageCategory,
        read: readCategory,
        update: updateCategory,
        delete: deleteCategory,
      },
      account: {
        create: createUser,
        manage: manageUser,
        read: readUser,
        update: updateUser,
        delete: deleteUser,
      },
    };
  }
);
