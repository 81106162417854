import { call, put, select, takeLatest } from 'redux-saga/effects';
import _size from 'lodash/size';

import {
  getNotifications,
  readNotification,
} from 'services/notification.service';

import { loadMore, setData, updateNotification } from './notification.action';
import { ReadNotificationAction, Types } from './notification.constant';
import { paramsSelector } from './notification.selector';

function* watchLoadData(action: any) {
  try {
    const { status, ...params } = yield select(paramsSelector);
    if (_size(status)) {
      params.where = { status: { $in: status } };
    }
    // @ts-ignore
    const res = yield call(getNotifications, params);

    if (action.type === Types.CHANGE_PARAMS) {
      yield put(loadMore(res));
    } else {
      yield put(setData(res));
    }
  } catch (e) {
    console.log(
      'Nguyen C: Notification.Saga.ts, F: watchLoadData, N: error ',
      e
    );
  }
}

function* watchReadNotification(action: ReadNotificationAction) {
  try {
    const { ids, fnCallback } = action.payload;

    yield put(updateNotification(ids));

    if (fnCallback) {
      fnCallback();
    }

    yield call(readNotification, { ids });
  } catch (e) {
    console.log(
      'Nguyen C: Notification.Saga.ts, F: watchLoadData, N: error ',
      e
    );
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.CHANGE_PARAMS, watchLoadData);
  yield takeLatest(Types.READ_NOTIFICATION, watchReadNotification);
}
