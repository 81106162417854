import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { IProductResearchListState } from './product-research-list.constant';

export const productResearchListSelector = (state: IState) => {
  return state.productResearchList;
};

export const paramsSelector = createSelector(
  productResearchListSelector,
  (productResearchList: IProductResearchListState): any =>
    productResearchList.params
);

export const loadingSelector = createSelector(
  productResearchListSelector,
  (productResearchList: IProductResearchListState): any =>
    productResearchList.loading
);

export const firstLoadSelector = createSelector(
  productResearchListSelector,
  (productResearchList: IProductResearchListState): any =>
    productResearchList.firstLoad
);

export const deletePopupSelector = createSelector(
  productResearchListSelector,
  (productResearchList: IProductResearchListState): any => ({
    deleting: productResearchList.deleting,
    data: productResearchList.selectedData,
    open: productResearchList.showDeletePopup,
  })
);
