import { IProductModel } from '../product-item/product-item.constant';

export interface IFeedbackModel {
  _id?: string;
  productFound?: boolean;
  competitorProductURLs?: any[];
  factories?: any[];
  status?: string;
  moq?: number;
  price?: number;
  currency?: string;
  amount?: number;
  unitPrice?: number;
  usdUnitPrice?: number;
  factoryWhereFound?: string;
  foundURLs?: string[];
  sampleRequest?: boolean;
  sampleAmount?: number;
  estimatedCourier?: number;
  sampleCost?: number;
  productSku?: string;
  pdFeedback?: string;
  drawings?: any[];
  estimateCost?: number;
  comment?: string;
  jiraTicket?: string;
  PODate?: any;
  companyOrdering?: string;
  businessSegment?: string;
  itemMaster?: string;
  vendorInfo?: string;
  paymentInfo?: string;
  paymentTerms?: string;
  deliveryTerms?: string;
  packagingPricing?: number;
}

export interface IFeedbackCreateState {
  productId: any;
  product?: any;
  data: IFeedbackModel;
  currencies: any;
  steps: any;
  activeStep: number;
  loading: boolean;
  saving: boolean;
  completed: boolean;
}

export enum Types {
  SAVE_DATA = '[FEEDBACK ITEM] SAVE DATA',
  SAVE_DONE = '[FEEDBACK ITEM] SAVE DONE',
  MAKE_A_NEW = '[FEEDBACK ITEM] MAKE A NEW',
  GO_NEXT_TAB = '[FEEDBACK ITEM] GO NEXT TAB',
  GO_BACK_TAB = '[FEEDBACK ITEM] BACK NEXT TAB',
  SET_DATA = '[FEEDBACK ITEM] SET DATA',
  LOAD_DATA = '[FEEDBACK ITEM] LOAD DATA',
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: { feedbackId: any; productId: any };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { feedback?: IFeedbackModel; product?: IProductModel };
}

export interface SaveDataAction {
  type: typeof Types.SAVE_DATA;
  payload: { data: any; productId: string };
}

export interface MakeNewRequestAction {
  type: typeof Types.MAKE_A_NEW;
}

export interface GoNextTabAction {
  type: typeof Types.GO_NEXT_TAB;
}

export interface GoBackTabAction {
  type: typeof Types.GO_BACK_TAB;
}

export interface SaveDoneAction {
  type: typeof Types.SAVE_DONE;
  payload: { completed: boolean };
}

export type Actions =
  | SetDataAction
  | LoadDataAction
  | SaveDataAction
  | SaveDoneAction
  | MakeNewRequestAction
  | GoNextTabAction
  | GoBackTabAction;
