import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { IAffiliateState } from './affiliate.constant';

export const affiliateSelector = (state: IState) => {
  return state.affiliate;
};

export const paramsSelector = createSelector(
  affiliateSelector,
  (productList: IAffiliateState): any => productList.params
);

export const loadingSelector = createSelector(
  affiliateSelector,
  (productList: IAffiliateState): any => productList.loading
);

export const dataSelector = createSelector(
  affiliateSelector,
  (productList: IAffiliateState): any => productList.data
);

export const emailSelector = createSelector(
  affiliateSelector,
  (productList: IAffiliateState): any => productList.email
);

export const countSelector = createSelector(
  affiliateSelector,
  (productList: IAffiliateState): any => productList.count
);
