import _get from 'lodash/get';

import { image_regex } from 'constants/common';

export function scanImages({ data, keys }: any) {
  keys.forEach((key: any) => {
    const v = _get(data, key);
    if (v) {
      v.forEach((f: any) => {
        f.photo = image_regex.test(f.originalname);
      });
    }
  });
}
