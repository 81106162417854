import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getReports,
  getStatisticSummary,
  getStatistic,
  deleteReports,
} from 'services/product.critical.report.service';
import { pushErrorMessage } from 'store/modules/snackbar/snackbar.action';

import {
  setLoading,
  setData,
  setPopup,
  setFilter,
} from './product-critical-report.action';
import {
  Types,
  GetCriticalReportAction,
  GetCriticalSummaryAction,
  GetCriticalStatisticAction,
  DeleteCriticalReportsAction,
  GetCriticalCountryStatisticAction,
  GetCriticalReasonStatisticAction,
  GetCriticalDateStatisticAction,
} from './product-critical-report.constant';

function* watchGetCriticalReports(action: GetCriticalReportAction) {
  const {
    summary,
    range,
    match,
    search,
    sort,
    skip,
    limit,
  } = action.payload.data;
  try {
    yield put(setLoading({ criticalReports: true }));
    // @ts-ignore
    const data = yield call(getReports, {
      summary,
      range,
      match,
      search,
      sort,
      skip,
      limit,
    });
    yield put(
      setData({
        criticalReports: data,
      })
    );
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ criticalReports: false }));
  }
}

function* watchGetCriticalSummary(action: GetCriticalSummaryAction) {
  const {
    summary,
    range,
    match,
    search,
    sort,
    skip,
    limit,
  } = action.payload.data;
  try {
    yield put(setLoading({ criticalSummary: true }));
    // @ts-ignore
    const data = yield call(getReports, {
      summary,
      range,
      match,
      search,
      sort,
      skip,
      limit,
    });
    yield put(
      setData({
        criticalSummary: data,
      })
    );
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ criticalSummary: false }));
  }
}

function* watchGetCriticalStatistic(action: GetCriticalStatisticAction) {
  const { range } = action.payload.data;
  try {
    yield put(setLoading({ criticalStatistic: true }));
    // @ts-ignore
    const criticalStatistic = yield call(getStatistic, {
      groupBy: 'sku',
      range,
    });
    // @ts-ignore
    const criticalStatisticSummary = yield call(getStatisticSummary, {
      range,
    });
    yield put(
      setData({
        criticalStatistic: criticalStatistic,
        criticalStatisticSummary: criticalStatisticSummary,
      })
    );
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ criticalStatistic: false }));
  }
}

function* watchGetCriticalReasonStatistic(
  action: GetCriticalReasonStatisticAction
) {
  const { range, match } = action.payload.data;
  try {
    yield put(setLoading({ criticalReasonStatistic: true }));
    // @ts-ignore
    const data = yield call(getStatistic, {
      groupBy: 'specificReason',
      range,
      match,
    });
    yield put(
      setData({
        criticalReasonStatistic: data,
      })
    );
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ criticalReasonStatistic: false }));
  }
}

function* watchGetCriticalCountryStatistic(
  action: GetCriticalCountryStatisticAction
) {
  const { range, match } = action.payload.data;
  try {
    yield put(setLoading({ criticalCountryStatistic: true }));
    // @ts-ignore
    const data = yield call(getStatistic, {
      groupBy: 'country',
      range,
      match,
    });
    yield put(
      setData({
        criticalCountryStatistic: data,
      })
    );
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ criticalCountryStatistic: false }));
  }
}

function* watchGetCriticalDateStatistic(
  action: GetCriticalDateStatisticAction
) {
  const { range, match } = action.payload.data;
  try {
    yield put(setLoading({ criticalDateStatistic: true }));
    // @ts-ignore
    const data = yield call(getStatistic, {
      groupBy: 'dateAdded',
      range,
      match,
    });
    yield put(
      setData({
        criticalDateStatistic: data,
      })
    );
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ criticalDateStatistic: false }));
  }
}

function* watchDeleteCriticalReports(action: DeleteCriticalReportsAction) {
  const { ids } = action.payload.data;
  try {
    yield put(setLoading({ confirm: true }));
    // @ts-ignore
    yield call(deleteReports, {
      ids,
    });
    yield put(setPopup({ confirmPopup: undefined }));
    yield put(setFilter({ ver: Math.random() }));
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ confirm: false }));
  }
}

export default function* root() {
  yield takeLatest(Types.GET_CRITICAL_REPORTS, watchGetCriticalReports);
  yield takeLatest(Types.GET_CRITICAL_SUMMARY, watchGetCriticalSummary);
  yield takeLatest(Types.GET_CRITICAL_STATISTIC, watchGetCriticalStatistic);
  yield takeLatest(
    Types.GET_CRITICAL_REASON_STATISTIC,
    watchGetCriticalReasonStatistic
  );
  yield takeLatest(
    Types.GET_CRITICAL_COUNTRY_STATISTIC,
    watchGetCriticalCountryStatistic
  );
  yield takeLatest(
    Types.GET_CRITICAL_DATE_STATISTIC,
    watchGetCriticalDateStatistic
  );
  yield takeLatest(Types.DELETE_CRITICAL_REPORTS, watchDeleteCriticalReports);
}
