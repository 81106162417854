import { select, call, put, takeLatest } from 'redux-saga/effects';

import {
  getFiles,
  uploadFiles,
  updateFiles,
  createFolders,
  deleteFiles,
  downloadFiles,
  getShareFiles,
  shareFiles,
  stopShareFiles,
  getFileAccess,
  getAffiliateFiles,
  downloadAffiliateFiles,
} from 'services/product-file.service';
import { pushErrorMessage } from 'store/modules/snackbar/snackbar.action';

import {
  setLoading,
  setData,
  setPopup,
  setFileExtraMap,
} from './product-file.action';
import {
  Types,
  LoadDataAction,
  UploadFilesAction,
  CancelUploadFilesAction,
  UpdateFilesAction,
  CreateFolderAction,
  DeleteFilesAction,
  DownloadFilesAction,
  GetShareFilesAction,
  ShareFilesAction,
  StopShareFilesAction,
  OpenViewMoreAction,
} from './product-file.constant';

import { affiliateSelector } from './product-file.selector';

function* watchLoadData(action: LoadDataAction) {
  const { productId, resolve, reject } = action.payload.data;
  try {
    yield put(setLoading({ data: true }));
    // @ts-ignore
    const { affiliate } = yield select(affiliateSelector);
    let data;
    if (affiliate) {
      // @ts-ignore
      data = yield call(getAffiliateFiles, productId);
    } else {
      // @ts-ignore
      data = yield call(getFiles, productId);
    }
    yield put(setData(data));
    resolve && resolve(data);
  } catch (e) {
    reject && reject(e);
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ data: false }));
  }
}

let controller: any;
function* watchUploadFiles(action: UploadFilesAction) {
  try {
    yield put(setLoading({ upload: true }));
    const formData: any = new FormData();
    const { productId, path, files, onUploadProgress } = action.payload.data;
    formData.append('productId', productId);
    formData.append('path', path);
    files.forEach((file: any) => {
      formData.append('files', file);
    });
    controller = new AbortController();
    // @ts-ignore
    const productFiles = yield call(
      uploadFiles,
      formData,
      controller,
      onUploadProgress
    );
    // @ts-ignore
    const data = yield call(getFiles, productId);
    yield put(setData(data));
    yield put(setPopup({ uploadPopup: undefined }));
    yield put(
      setPopup({
        editPopup: {
          open: true,
          path,
          files: productFiles.map((i: any) => i._id),
          folders: [],
        },
      })
    );
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ upload: false }));
    yield put(setLoading({ uploadPercent: 0 }));
  }
}

function* watchCancelUploadFiles(action: CancelUploadFilesAction) {
  try {
    if (controller) {
      console.log('cancel upload');
      controller.abort();
    }
  } catch (e) {
    console.log(e);
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ upload: false }));
  }
}

function* watchUpdateFiles(action: UpdateFilesAction) {
  try {
    yield put(setLoading({ update: true }));
    const { productId, path, files, folders } = action.payload.data;
    // @ts-ignore
    yield call(updateFiles, {
      productId,
      path,
      files,
      folders,
    });
    // @ts-ignore
    const data = yield call(getFiles, productId);
    yield put(setData(data));
    yield put(setPopup({ editPopup: undefined }));
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ update: false }));
  }
}

function* watchCreateFolder(action: CreateFolderAction) {
  try {
    yield put(setLoading({ folder: true }));
    const { productId, path, folders } = action.payload.data;
    // @ts-ignore
    yield call(createFolders, {
      productId,
      path,
      folders,
    });
    // @ts-ignore
    const data = yield call(getFiles, productId);
    yield put(setData(data));
    yield put(setPopup({ folderPopup: undefined }));
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ folder: false }));
  }
}

function* watchDeleteFiles(action: DeleteFilesAction) {
  try {
    yield put(setLoading({ confirm: true }));
    const { productId, path, files, folders } = action.payload.data;
    // @ts-ignore
    yield call(deleteFiles, {
      productId,
      path,
      files,
      folders,
    });
    // @ts-ignore
    const data = yield call(getFiles, productId);
    yield put(setData(data));
    yield put(setPopup({ confirmPopup: undefined }));
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ confirm: false }));
  }
}

function* watchDownloadFiles(action: DownloadFilesAction) {
  try {
    yield put(setLoading({ download: true }));
    // yield put(setPopup({ downloadPopup: { open: true } }));
    const { productId, path, files, folders } = action.payload.data;
    // @ts-ignore
    const { affiliate } = yield select(affiliateSelector);
    let data: any;
    if (affiliate) {
      // @ts-ignore
      data = yield call(downloadAffiliateFiles, {
        productId,
        path,
        files,
        folders,
      });
    } else {
      // @ts-ignore
      data = yield call(downloadFiles, {
        productId,
        path,
        files,
        folders,
      });
    }
    // yield put(setPopup({ downloadPopup: { open: true, files: data } }));
    const loop = () => {
      if (!data.length) {
        return;
      }
      const item = data.shift();
      const { downloadUrl, name } = item;
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = name;
      a.click();
      setTimeout(loop, 1000);
    };
    loop();
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ download: false }));
  }
}

function* watchGetShareFiles(action: GetShareFilesAction) {
  try {
    yield put(setLoading({ share: true }));
    yield put(setPopup({ sharePopup: { open: true } }));
    const { productId, files, folders } = action.payload.data;
    // @ts-ignore
    const data = yield call(getShareFiles, {
      productId,
      files,
      folders,
    });
    yield put(
      setPopup({
        sharePopup: { open: true, files, folders, sharedFiles: data },
      })
    );
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ share: false }));
  }
}

function* watchShareFiles(action: ShareFilesAction) {
  try {
    yield put(setLoading({ share: true }));
    const { productId, files, folders } = action.payload.data;
    yield call(shareFiles, {
      productId,
      files,
      folders,
    });
    // @ts-ignore
    const data = yield call(getShareFiles, {
      productId,
      files,
      folders,
    });
    yield put(
      setPopup({
        sharePopup: { open: true, files, folders, sharedFiles: data },
      })
    );
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ share: false }));
  }
}

function* watchStopShareFiles(action: StopShareFilesAction) {
  try {
    yield put(setLoading({ share: true }));
    const { productId, files, folders } = action.payload.data;
    yield call(stopShareFiles, {
      productId,
      files,
      folders,
    });
    // @ts-ignore
    const data = yield call(getShareFiles, {
      productId,
      files,
      folders,
    });
    yield put(
      setPopup({
        sharePopup: { open: true, files, folders, sharedFiles: data },
      })
    );
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ share: false }));
  }
}

function* watchGetFileExtra(action: GetShareFilesAction) {
  try {
    // @ts-ignore
    const { affiliate } = yield select(affiliateSelector);
    if (affiliate) {
      return;
    }
    yield put(setLoading({ fileExtra: true }));
    const { id } = action.payload.data;
    const getAll = async () => {
      return Promise.all([
        getFileAccess({
          id,
          type: 'access',
          skip: 0,
          limit: 5,
        }),
        getFileAccess({
          id,
          type: 'sharing',
          skip: 0,
          limit: 5,
        }),
      ]);
    };
    // @ts-ignore
    const data = yield call(getAll);
    yield put(
      setFileExtraMap({
        [id]: {
          access: data[0],
          sharing: data[1],
        },
      })
    );
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ fileExtra: false }));
  }
}

function* watchOpenViewMore(action: OpenViewMoreAction) {
  try {
    yield put(setLoading({ viewMore: true }));
    const { id, type, search, skip, limit } = action.payload.data;
    yield put(setPopup({ viewMorePopup: { open: true, id, type } }));
    // @ts-ignore
    const data = yield call(getFileAccess, {
      id,
      type,
      search,
      skip: skip || 0,
      limit: limit || 10,
    });
    data.type = type;
    yield put(setPopup({ viewMorePopup: { open: true, id, type, data } }));
  } catch (e) {
    yield put(pushErrorMessage(e));
  } finally {
    yield put(setLoading({ viewMore: false }));
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.UPLOAD_FILES, watchUploadFiles);
  yield takeLatest(Types.CANCEL_UPLOAD_FILES, watchCancelUploadFiles);
  yield takeLatest(Types.UPDATE_FILES, watchUpdateFiles);
  yield takeLatest(Types.CREATE_FOLDER, watchCreateFolder);
  yield takeLatest(Types.DELETE_FILES, watchDeleteFiles);
  yield takeLatest(Types.DOWNLOAD_FILES, watchDownloadFiles);
  yield takeLatest(Types.GET_SHARE_FILES, watchGetShareFiles);
  yield takeLatest(Types.SHARE_FILES, watchShareFiles);
  yield takeLatest(Types.STOP_SHARE_FILES, watchStopShareFiles);
  yield takeLatest(Types.GET_FILE_EXTRA, watchGetFileExtra);
  yield takeLatest(Types.OPEN_VIEW_MORE, watchOpenViewMore);
}
