import {
  Types,
  IProductModel,
  SetDataAction,
  UpdateDataAction,
  LoadDataAction,
  UpdateStatusAction,
  UpdateSectionStatusAction,
  BeginDeleteDataAction,
  EndDeleteDataAction,
  ShowDeletePopupAction,
  HideDeletePopupAction,
  ShowRejectPopupAction,
  HideRejectPopupAction,
  ShowApprovePopupAction,
  HideApprovePopupAction,
  CloseUpdatePopupAction,
  SetStatusAction,
  EndSellingDataAction,
  BeginSellingDataAction,
  HideSellingPopupAction,
  ShowSellingPopupAction,
  ShowApproveSectionPopupAction,
  HideApproveSectionPopupAction,
  ShowDisapproveSectionPopupAction,
  HideDisapproveSectionPopupAction,
  ShowGoLivePopupAction,
  HideGoLivePopupAction,
  BeginPendingDataAction,
  EndPendingDataAction,
  ShowPendingPopupAction,
  HidePendingPopupAction,
} from './product-item.constant';

export function setData(data: any, offer: any): SetDataAction {
  return { type: Types.SET_DATA, payload: { data, offer } };
}

export function updateData(data: any): UpdateDataAction {
  return { type: Types.UPDATE_DATA, payload: { data } };
}

export function loadData(productId: string, offerId?: string): LoadDataAction {
  return { type: Types.LOAD_DATA, payload: { productId, offerId } };
}

export function showDeletePopup(data?: IProductModel): ShowDeletePopupAction {
  return { type: Types.SHOW_DELETE_POPUP, payload: { data } };
}

export function hideDeletePopup(): HideDeletePopupAction {
  return { type: Types.HIDE_DELETE_POPUP };
}

export function deleteData(
  productId: string,
  fnCallback?: any
): BeginDeleteDataAction {
  return { type: Types.BEGIN_DELETE_DATA, payload: { productId, fnCallback } };
}

export function completeDelete(): EndDeleteDataAction {
  return { type: Types.END_DELETE_DATA };
}

export function showApprovePopup(): ShowApprovePopupAction {
  return { type: Types.SHOW_APPROVE_POPUP };
}

export function hideApprovePopup(): HideApprovePopupAction {
  return { type: Types.HIDE_APPROVE_POPUP };
}

export function showRejectPopup(): ShowRejectPopupAction {
  return { type: Types.SHOW_REJECT_POPUP };
}

export function hideRejectPopup(): HideRejectPopupAction {
  return { type: Types.HIDE_REJECT_POPUP };
}

export function updateStatus(
  status: number,
  reason?: string
): UpdateStatusAction {
  return { type: Types.UPDATE_STATUS, payload: { status, reason } };
}

export function updateSectionStatus(status: string): UpdateSectionStatusAction {
  return { type: Types.UPDATE_SECTION_STATUS, payload: { status } };
}

export function closeUpdatePopup(): CloseUpdatePopupAction {
  return { type: Types.CLOSE_UPDATE_POPUP };
}

export function setStatus(status: number, reason?: string): SetStatusAction {
  return { type: Types.SET_STATUS, payload: { status, reason } };
}

export function showSellingPopup(data?: IProductModel): ShowSellingPopupAction {
  return { type: Types.SHOW_SELLING_POPUP, payload: { data } };
}

export function hideSellingPopup(): HideSellingPopupAction {
  return { type: Types.HIDE_SELLING_POPUP };
}

export function showApproveSectionPopup(
  data: any
): ShowApproveSectionPopupAction {
  return { type: Types.SHOW_APPROVE_SECTION_POPUP, payload: { data } };
}

export function hideApproveSectionPopup(): HideApproveSectionPopupAction {
  return { type: Types.HIDE_APPROVE_SECTION_POPUP };
}

export function showDisapproveSectionPopup(
  data: any
): ShowDisapproveSectionPopupAction {
  return { type: Types.SHOW_DISAPPROVE_SECTION_POPUP, payload: { data } };
}

export function hideDisapproveSectionPopup(): HideDisapproveSectionPopupAction {
  return { type: Types.HIDE_DISAPPROVE_SECTION_POPUP };
}

export function showGoLivePopup(): ShowGoLivePopupAction {
  return { type: Types.SHOW_GO_LIVE_POPUP };
}

export function hideGoLivePopup(): HideGoLivePopupAction {
  return { type: Types.HIDE_GO_LIVE_POPUP };
}

export function changeSelling(
  productId: string,
  fnCallback?: any
): BeginSellingDataAction {
  return { type: Types.BEGIN_SELLING_DATA, payload: { productId, fnCallback } };
}

export function completeSelling(): EndSellingDataAction {
  return { type: Types.END_SELLING_DATA };
}

export function showPendingPopup(data?: IProductModel): ShowPendingPopupAction {
  return { type: Types.SHOW_PENDING_POPUP, payload: { data } };
}

export function hidePendingPopup(): HidePendingPopupAction {
  return { type: Types.HIDE_PENDING_POPUP };
}

export function changePending(
  productId: string,
  fnCallback?: any
): BeginPendingDataAction {
  return { type: Types.BEGIN_PENDING_DATA, payload: { productId, fnCallback } };
}

export function completePending(): EndPendingDataAction {
  return { type: Types.END_PENDING_DATA };
}
