import { call, put, takeLatest } from 'redux-saga/effects';
import {
  addOffer,
  getOffer,
  updateOffer,
} from 'services/product-offer.service';
import { pushErrorMessage } from 'store/modules/snackbar/snackbar.action';

import {
  LoadDataAction,
  SaveDataAction,
  Types,
} from './product-offer.constant';
import { initFormData, saveDone } from '../product-offer/product-offer.action';

function* loadProduct(_id: string) {
  let data;

  if (_id) {
    // @ts-ignore
    data = yield call(getOffer, _id);
  }

  return data;
}

function* watchLoadData(action: LoadDataAction) {
  const { _id } = action.payload;
  try {
    // @ts-ignore
    const data: any = yield call(loadProduct, _id);

    yield put(initFormData(data));
  } catch (error) {
    yield put(pushErrorMessage(error));
  }
}

function* watchSaveData(action: SaveDataAction) {
  let completed = true;
  try {
    const { product, ...data } = action.payload.data;

    if (data._id) {
      yield call(updateOffer, data._id, data);
    } else {
      yield call(addOffer, product._id, data);
    }
  } catch (error) {
    yield put(pushErrorMessage(error));

    completed = false;
  }

  yield put(saveDone(completed));
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.SAVE_DATA, watchSaveData);
}
