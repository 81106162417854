import * as _firebase from 'firebase/app';
import 'firebase/auth';
import { FIREBASE_CONFIG } from '../constants/firebase';

export const provider = new _firebase.auth.GoogleAuthProvider();
provider.addScope('profile');
provider.addScope('email');
provider.setCustomParameters({ prompt: 'select_account' });

export const firebase = _firebase.initializeApp(FIREBASE_CONFIG);
