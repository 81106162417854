export interface IAffiliateState {
  loading: boolean;
  count: number;
  data: any[];
  params: any;
  email: string;
}

export enum Types {
  SET_PARAMS = '[AFFILIATE] SET_PARAMS',
  SET_DATA = '[AFFILIATE] SET_DATA',
  LOAD_DATA = '[AFFILIATE] LOAD_DATA',
  SET_LOADING = '[AFFILIATE] SET_LOADING',
  SET_EMAIL = '[AFFILIATE] SET_EMAIL',
  CLEAR_STATE = '[AFFILIATE] CLEAR_STATE',
}

export interface SetParamsAction {
  type: typeof Types.SET_PARAMS;
  payload: any;
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: any;
}
export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: any;
}
export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: any;
}
export interface SetEmailAction {
  type: typeof Types.SET_EMAIL;
  payload: any;
}
export interface ClearStateAction {
  type: typeof Types.CLEAR_STATE;
  payload: any;
}

export type Actions =
  | SetParamsAction
  | SetDataAction
  | LoadDataAction
  | SetLoadingAction
  | SetEmailAction
  | ClearStateAction;
