import produce from 'immer';

import {
  Actions,
  IFeedbackDetailState,
  Types,
} from './feedback-detail.constant';

const initialState: IFeedbackDetailState = {
  data: {},
  loading: true,
  deleting: false,
  showDeletePopup: false,
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.LOAD_DATA:
      draft.loading = true;
      draft.deleting = false;
      break;
    case Types.SET_DATA:
      const { data } = action.payload;
      draft.data = data;
      draft.loading = false;
      break;
    case Types.BEGIN_DELETE_DATA:
      draft.deleting = true;
      break;
    case Types.END_DELETE_DATA:
      draft.showDeletePopup = false;
      draft.deleting = false;
      break;
    case Types.SHOW_DELETE_POPUP:
      draft.showDeletePopup = true;
      if (action.payload.data) {
        draft.data = action.payload.data;
      }
      break;
    case Types.HIDE_DELETE_POPUP:
      draft.showDeletePopup = false;
      break;
  }
}, initialState);

export { initialState, reducer as default };
