export const getDestinations = () => {
  return [
    'Vancouver',
    'New York',
    'Hongkong',
    'Vietnam',
    'San Diego',
    'Den Hague',
  ];
};
