import {
  Types,
  LoadDataAction,
  SetDataAction,
  MakeNewRequestAction,
  SaveDataAction,
  SaveDoneAction,
  GoBackTabAction,
  GoNextTabAction,
  InitFormAction,
  ClearStateAction,
  HideConfirmPopupAction,
  ShowConfirmPopupAction,
} from './product-create.constant';
import { IProductModel } from '../product-item/product-item.constant';

export function goNextTab(): GoNextTabAction {
  return { type: Types.GO_NEXT_TAB };
}

export function goBackTab(): GoBackTabAction {
  return { type: Types.GO_BACK_TAB };
}

export function saveData(data: any): SaveDataAction {
  return { type: Types.SAVE_DATA, payload: { data } };
}

export function saveDone(completed: boolean): SaveDoneAction {
  return { type: Types.SAVE_DONE, payload: { completed } };
}

export function makeNewRequest(): MakeNewRequestAction {
  return { type: Types.MAKE_A_NEW };
}

export function loadData(productId: string): LoadDataAction {
  return { type: Types.LOAD_DATA, payload: { _id: productId } };
}

export function setData(data?: IProductModel): SetDataAction {
  return { type: Types.SET_DATA, payload: { data } };
}

export function initFormData(data?: any, categories?: any): InitFormAction {
  return { type: Types.INIT_FORM, payload: { data, categories } };
}

export function clearState(): ClearStateAction {
  return { type: Types.CLEAR_STATE };
}

export function showConfirmPopup(data: any): ShowConfirmPopupAction {
  return { type: Types.SHOW_CONFIRM_POPUP, payload: { data } };
}

export function hideConfirmPopup(): HideConfirmPopupAction {
  return { type: Types.HIDE_CONFIRM_POPUP };
}
