import {
  Types,
  SetLoadingAction,
  GetCriticalReportAction,
  GetCriticalSummaryAction,
  GetCriticalStatisticAction,
  SetFilterAction,
  SetDataAction,
  SetPopupAction,
  DeleteCriticalReportsAction,
  ResetStateAction,
  GetCriticalReasonStatisticAction,
  GetCriticalCountryStatisticAction,
  GetCriticalDateStatisticAction,
} from './product-critical-report.constant';

export function setLoading(data: any): SetLoadingAction {
  return { type: Types.SET_LOADING, payload: { data } };
}

export function getCriticalReports(data: any): GetCriticalReportAction {
  return { type: Types.GET_CRITICAL_REPORTS, payload: { data } };
}

export function getCriticalSummary(data: any): GetCriticalSummaryAction {
  return { type: Types.GET_CRITICAL_SUMMARY, payload: { data } };
}

export function getCriticalStatistic(data: any): GetCriticalStatisticAction {
  return { type: Types.GET_CRITICAL_STATISTIC, payload: { data } };
}

export function getCriticalReasonStatistic(
  data: any
): GetCriticalReasonStatisticAction {
  return { type: Types.GET_CRITICAL_REASON_STATISTIC, payload: { data } };
}

export function getCriticalCountryStatistic(
  data: any
): GetCriticalCountryStatisticAction {
  return { type: Types.GET_CRITICAL_COUNTRY_STATISTIC, payload: { data } };
}

export function getCriticalDateStatistic(
  data: any
): GetCriticalDateStatisticAction {
  return { type: Types.GET_CRITICAL_DATE_STATISTIC, payload: { data } };
}

export function setFilter(data: any): SetFilterAction {
  return { type: Types.SET_FILTER, payload: { data } };
}

export function setData(data: any): SetDataAction {
  return { type: Types.SET_DATA, payload: { data } };
}

export function setPopup(data: any): SetPopupAction {
  return { type: Types.SET_POPUP, payload: { data } };
}

export function deleteCriticalReports(data: any): DeleteCriticalReportsAction {
  return { type: Types.DELETE_CRITICAL_REPORTS, payload: { data } };
}

export function resetState(): ResetStateAction {
  return { type: Types.RESET_STATE };
}
