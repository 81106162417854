import React from 'react';
import {
  DialogContent as DialogContentUI,
  DialogContentProps,
} from '@material-ui/core';

export const DialogContent = (props: DialogContentProps) => {
  return <DialogContentUI style={{ overflow: 'hidden' }} {...props} />;
};

DialogContent.defaultProps = {};
