import { defineAbility } from '@casl/ability';
import { DEPARTMENT_ENUM, IUser } from './common.casl';
import { ROLE_ENUM, PERMISSION_ENUM } from './common.casl';

export const abilityFactory = (user: IUser, role_mapper: any) =>
  defineAbility(
    (can: any) => {
      const _roles: Array<ROLE_ENUM | PERMISSION_ENUM | DEPARTMENT_ENUM> = [];
      ((user && user.roles) || []).forEach(role => {
        _roles.push(role);
      });
      ((user && user.department) || []).forEach(department => {
        _roles.push(department);
      });

      for (const role of _roles) {
        if (Array.isArray(role_mapper[role])) {
          for (const _p of role_mapper[role]) {
            can(...(_p as any));
          }
        }
      }
    },
    {
      detectSubjectType: (object: any) => object.__typename,
    }
  );

// Sample Usage
// abilityFactory(user, user.permissions) => ability
//
// Check if able to do operation
// ability.can(ACTION, ENTITY)
// See CASL for detail https://casl.js.org/v5/en/guide/intro
