export interface IProductShareState {
  data: any;
  sharing: boolean;
  showSharePopup: boolean;
}

export enum Types {
  SHOW_SHARE_POPUP = '[PRODUCT SHARE] SHOW_SHARE_POPUP',
  HIDE_SHARE_POPUP = '[PRODUCT SHARE] HIDE_SHARE_POPUP',
  BEGIN_SHARE_DATA = '[PRODUCT SHARE] BEGIN_SHARE_DATA',
  SHOW_APPROVE_POPUP = '[PRODUCT SHARE] SHOW_APPROVE_POPUP',
  HIDE_APPROVE_POPUP = '[PRODUCT SHARE] HIDE_APPROVE_POPUP',
}

export interface ShowApprovePopupAction {
  type: typeof Types.SHOW_APPROVE_POPUP;
}

export interface HideApprovePopupAction {
  type: typeof Types.HIDE_APPROVE_POPUP;
}

export interface ShowSharePopupAction {
  type: typeof Types.SHOW_SHARE_POPUP;
  payload: { data: any };
}

export interface HideSharePopupAction {
  type: typeof Types.HIDE_SHARE_POPUP;
}

export interface BeginShareDataAction {
  type: typeof Types.BEGIN_SHARE_DATA;
  payload: { data: any };
}

export type Actions =
  | ShowApprovePopupAction
  | HideApprovePopupAction
  | ShowSharePopupAction
  | HideSharePopupAction
  | BeginShareDataAction;
