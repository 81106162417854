import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { IProductMarketingListState } from './product-marketing-list.constant';

export const productMarketingListSelector = (state: IState) => {
  return state.productMarketingList;
};

export const paramsSelector = createSelector(
  productMarketingListSelector,
  (productMarketingList: IProductMarketingListState): any =>
    productMarketingList.params
);

export const loadingSelector = createSelector(
  productMarketingListSelector,
  (productMarketingList: IProductMarketingListState): any =>
    productMarketingList.loading
);

export const firstLoadSelector = createSelector(
  productMarketingListSelector,
  (productMarketingList: IProductMarketingListState): any =>
    productMarketingList.firstLoad
);

export const deletePopupSelector = createSelector(
  productMarketingListSelector,
  (productMarketingList: IProductMarketingListState): any => ({
    deleting: productMarketingList.deleting,
    data: productMarketingList.selectedData,
    open: productMarketingList.showDeletePopup,
  })
);
