import axios, { AxiosRequestConfig } from 'axios';
import { refreshToken } from 'services/authentication.service';
import { setUser } from 'store/modules/auth/auth.action';

import { removeData, setData, validCallRefreshToken } from './auth.util';
import store from 'store';
import _get from 'lodash/get';
export const callApi = async (options: AxiosRequestConfig) => {
  if (validCallRefreshToken()) {
    let user;
    try {
      const data: any = await refreshToken();

      // Store in localstorage
      setData(data);

      user = data.user;
      if (!user.name) {
        user.name = `${_get(user, 'firstName', '')} ${_get(
          user,
          'lastName',
          ''
        )}`;
      }
    } catch (e) {
      removeData();
    }

    store.dispatch(setUser(user));
  }

  return axios(options);
};
