import './antd.default.css';
import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/tag/style/index.css';
import 'antd/lib/button/style/index.css';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import './styles/index.scss';

import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';

import store from 'store';

import Loading from 'components/ui-own/progress/loader/loader.component';
import { setUpApi } from 'utils/http-interceptors/request';

import 'i18n';

const App = React.lazy(() => import('./router'));

function generateUI(): void {
  const root: HTMLElement = document.createElement('div');
  root.id = 'approot';

  document.body.append(root);

  render(
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
    </Provider>,
    root
  );
}

function createMagic() {
  setUpApi();

  generateUI();
}

// this is a magic
createMagic();
