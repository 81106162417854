import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { IProductRestrictionListState } from './product-restriction-list.constant';

export const productRestrictionListSelector = (state: IState) => {
  return state.productRestrictionList;
};

export const paramsSelector = createSelector(
  productRestrictionListSelector,
  (productRestrictionList: IProductRestrictionListState): any =>
    productRestrictionList.params
);

export const loadingSelector = createSelector(
  productRestrictionListSelector,
  (productRestrictionList: IProductRestrictionListState): any =>
    productRestrictionList.loading
);

export const firstLoadSelector = createSelector(
  productRestrictionListSelector,
  (productRestrictionList: IProductRestrictionListState): any =>
    productRestrictionList.firstLoad
);

export const deletePopupSelector = createSelector(
  productRestrictionListSelector,
  (productRestrictionList: IProductRestrictionListState): any => ({
    deleting: productRestrictionList.deleting,
    data: productRestrictionList.selectedData,
    open: productRestrictionList.showDeletePopup,
  })
);
