import produce from 'immer';
import moment from 'moment';

import {
  Actions,
  IProductCriticalReportState,
  Types,
} from './product-critical-report.constant';

const initialState: IProductCriticalReportState = {
  loading: {},
  filter: {
    from: moment()
      .add(-7, 'days')
      .startOf('day'),
    to: moment()
      .add(0, 'days')
      .startOf('day'),
  },
  data: {},
  popup: {},
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.SET_LOADING:
      Object.assign(draft.loading, action.payload.data);
      break;
    case Types.SET_FILTER:
      Object.assign(draft.filter, action.payload.data);
      break;
    case Types.SET_DATA:
      Object.assign(draft.data, action.payload.data);
      break;
    case Types.SET_POPUP:
      Object.assign(draft.popup, action.payload.data);
      break;
    case Types.RESET_STATE:
      Object.entries(JSON.parse(JSON.stringify(initialState))).forEach(
        ([k, v]) => {
          draft[k] = v;
        },
        []
      );
      break;
  }
}, initialState);

export { initialState, reducer as default };
