import produce from 'immer';

import cloneDeep from 'lodash/cloneDeep';

import {
  Actions,
  ICustomerServiceState,
  Types,
} from './customer-service.constant';

const dataEmpty: any = {
  consumer_feedback_v2: [{ title: '', link: '' }],
  result_consolidate_v2: [{ title: '', link: '' }],
  review_source_v2: [{ title: '', link: '' }],
  product: null,
};

const initState: ICustomerServiceState = {
  data: cloneDeep(dataEmpty),
  showConfirmPopup: false,
  loading: true,
  saving: false,
  completed: false,
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.LOAD_DATA:
      draft.completed = false;
      draft.loading = true;
      break;
    case Types.INIT_FORM:
      draft.loading = false;
      if (action.payload.data) {
        draft.data = action.payload.data;
      } else {
        draft.data = cloneDeep(dataEmpty);
      }
      break;
    case Types.SAVE_DATA:
      draft.saving = true;
      draft.data = action.payload.data;
      break;
    case Types.SAVE_DONE:
      draft.completed = action.payload.completed;
      draft.showConfirmPopup = false;
      draft.saving = false;
      break;
    case Types.SET_COMPLETED:
      draft.completed = action.payload.completed;
      break;
    case Types.SHOW_CONFIRM_POPUP:
      draft.data = action.payload.data;
      draft.showConfirmPopup = true;
      break;
    case Types.HIDE_CONFIRM_POPUP:
      draft.showConfirmPopup = false;
      break;
    case Types.CLEAR_STATE:
      return initState;
  }
}, initState);

export { initState, reducer as default };
