import { call, put, select, takeLatest } from 'redux-saga/effects';
import _size from 'lodash/size';
import _get from 'lodash/get';

import { pushErrorMessage } from 'store/modules/snackbar/snackbar.action';

import {
  LoadDataAction,
  SaveDataAction,
  Types,
} from './product-restriction.constant';
import {
  initFormData,
  saveDone,
} from '../product-restriction/product-restriction.action';
import {
  addProductRestriction,
  updateProductRestriction,
  getProductRestriction,
} from 'services/product-restriction.service';
import { permissionsSelector } from '../auth/auth.selector';

function* loadProduct(_id: string) {
  if (!_id) {
    return null;
  }

  // @ts-ignore
  let { product_legal: data, product_marketing } = yield call(
    getProductRestriction,
    _id
  );

  return {
    _id,
    generalName: _get(product_marketing, 'product_marketing_name', ''),
    amazon_restriction_v2: data.amazon_restriction_v2,
    traffic_source_v2: data.traffic_source_v2,
    social_platform_restriction_v2: data.social_platform_restriction_v2,
    bank_restriction_v2: data.bank_restriction_v2,
    charge_back_rate_v2: data.charge_back_rate_v2,
    advertising_v2: data.advertising_v2,
  };
}

function* watchLoadData(action: LoadDataAction) {
  const { _id } = action.payload;
  try {
    // @ts-ignore
    const data: any = yield call(loadProduct, _id);

    yield put(initFormData(data));
  } catch (error) {
    yield put(pushErrorMessage(error));
  }
}

export const formatBody = (data: any, fields: any) => {
  const formData: any = {};

  Object.keys(data).forEach(function (key) {
    if (
      key === '_id' ||
      key === 'generalName' ||
      (fields && fields.length > 0 && !fields.includes(key))
    ) {
      return;
    }

    const value = data[key];

    formData[key] = (value || []).filter((e: any) => _size(e) > 0);
  });

  return { formData };
};

function* watchSaveData(action: SaveDataAction) {
  let completed = true;
  try {
    // @ts-ignore
    const {
      productLegal: { fields },
    } = yield select(permissionsSelector);
    const { product, ...data } = action.payload.data;

    const { formData } = formatBody(data, fields);

    if (data._id) {
      yield call(updateProductRestriction, data._id, formData);
    } else {
      yield call(addProductRestriction, product._id, formData);
    }
  } catch (error) {
    yield put(pushErrorMessage(error));

    completed = false;
  }

  yield put(saveDone(completed));
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.SAVE_DATA, watchSaveData);
}
