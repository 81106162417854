import { callApi } from 'utils/service.util';

const API_ENDPOINT = '/v1/user/v1';

export const getUserList = async (params?: any) => {
  return callApi({
    method: 'get',
    url: `${API_ENDPOINT}`,
    params,
  });
};

export const updateUser = async (_id: string, data: any) => {
  return callApi({
    method: 'put',
    url: `${API_ENDPOINT}/${_id}`,
    data,
  });
};

export const deleteUser = async (_id: any) => {};
