export interface IProductCriticalReportState {
  loading: any;
  filter: any;
  data: any;
  popup: any;
}

export enum Types {
  SET_LOADING = '[PRODUCT CRITICAL REPORT] SET_LOADING',
  SET_FILTER = '[PRODUCT CRITICAL REPORT] SET_FILTER',
  GET_CRITICAL_REPORTS = '[PRODUCT CRITICAL REPORT] GET_CRITICAL_REPORTS',
  GET_CRITICAL_SUMMARY = '[PRODUCT CRITICAL REPORT] GET_CRITICAL_SUMMARY',
  GET_CRITICAL_STATISTIC = '[PRODUCT CRITICAL REPORT] GET_CRITICAL_STATISTIC',
  GET_CRITICAL_REASON_STATISTIC = '[PRODUCT CRITICAL REPORT] GET_CRITICAL_REASON_STATISTIC',
  GET_CRITICAL_COUNTRY_STATISTIC = '[PRODUCT CRITICAL REPORT] GET_CRITICAL_COUNTRY_STATISTIC',
  GET_CRITICAL_DATE_STATISTIC = '[PRODUCT CRITICAL REPORT] GET_CRITICAL_DATE_STATISTIC',
  SET_DATA = '[PRODUCT CRITICAL REPORT] SET_DATA',
  UPDATE_DATA = '[PRODUCT CRITICAL REPORT] UPDATE_DATA',
  SET_POPUP = '[PRODUCT CRITICAL REPORT] SET_POPUP',
  DELETE_CRITICAL_REPORTS = '[PRODUCT CRITICAL REPORT] DELETE_CRITICAL_REPORTS',
  RESET_STATE = '[PRODUCT CRITICAL REPORT] RESET_STATE',
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    data: any;
  };
}

export interface GetCriticalReportAction {
  type: typeof Types.GET_CRITICAL_REPORTS;
  payload: {
    data: any;
  };
}

export interface GetCriticalSummaryAction {
  type: typeof Types.GET_CRITICAL_SUMMARY;
  payload: {
    data: any;
  };
}

export interface GetCriticalStatisticAction {
  type: typeof Types.GET_CRITICAL_STATISTIC;
  payload: {
    data: any;
  };
}

export interface GetCriticalReasonStatisticAction {
  type: typeof Types.GET_CRITICAL_REASON_STATISTIC;
  payload: {
    data: any;
  };
}

export interface GetCriticalCountryStatisticAction {
  type: typeof Types.GET_CRITICAL_COUNTRY_STATISTIC;
  payload: {
    data: any;
  };
}

export interface GetCriticalDateStatisticAction {
  type: typeof Types.GET_CRITICAL_DATE_STATISTIC;
  payload: {
    data: any;
  };
}

export interface SetFilterAction {
  type: typeof Types.SET_FILTER;
  payload: { data: any };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: any };
}

export interface SetPopupAction {
  type: typeof Types.SET_POPUP;
  payload: { data: any };
}

export interface DeleteCriticalReportsAction {
  type: typeof Types.DELETE_CRITICAL_REPORTS;
  payload: { data: any };
}

export interface ResetStateAction {
  type: typeof Types.RESET_STATE;
}

export type Actions =
  | SetLoadingAction
  | GetCriticalReportAction
  | GetCriticalSummaryAction
  | GetCriticalStatisticAction
  | GetCriticalReasonStatisticAction
  | GetCriticalCountryStatisticAction
  | GetCriticalDateStatisticAction
  | SetFilterAction
  | SetDataAction
  | SetPopupAction
  | DeleteCriticalReportsAction
  | ResetStateAction;
