import { callApi } from 'utils/service.util';

const API_ENDPOINT = '/v1/category/v1';

export const getCategoryList = async (params?: any) => {
  return callApi({
    method: 'get',
    url: `${API_ENDPOINT}`,
    params,
  });
};

export const saveCategory = async (data: any) => {
  return callApi({
    method: 'post',
    url: `${API_ENDPOINT}`,
    data,
  });
};

export const updateCategory = async (_id: string, data: any) => {
  return callApi({
    method: 'put',
    url: `${API_ENDPOINT}/${_id}`,
    data,
  });
};

export const deleteCategory = async (_id: string) => {
  return callApi({
    method: 'delete',
    url: `${API_ENDPOINT}/${_id}`,
  });
};
