import { callApi } from 'utils/service.util';

const PRODUCT_API_ENDPOINT = 'v1/productMarketResearch/v2';

export const getProductResearchs = async (params?: any) => {
  return callApi({
    method: 'get',
    url: PRODUCT_API_ENDPOINT,
    params,
  });
};
export const deleteProductResearch = async (_id: any) => {
  return callApi({
    method: 'delete',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
  });
};

export const updateProductResearch = async (_id: string, data: any) => {
  return callApi({
    method: 'put',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
    data,
  });
};

export const getProductResearch = async (_id: any) => {
  return callApi({
    method: 'get',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
  });
};

export const addProductResearch = async (_id: string, data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
    data,
  });
};
