import {
  Types,
  OpenDeleteDialogAction,
  HideDeleteDialogAction,
  SetDataAction,
  LoadDataAction,
  ResetDataAction,
  SetLoadingAction,
  ChangeParamsAction,
  BeginDeleteDataAction,
  EndDeleteDataAction,
  UpdateRowAction,
  OpenEditDialogAction,
  HideEditDialogAction,
} from './category-list.constant';

export function setData(count: number, data: any): SetDataAction {
  return { type: Types.SET_DATA, payload: { count, data } };
}

export function loadData(): LoadDataAction {
  return { type: Types.LOAD_DATA };
}

export function resetData(): ResetDataAction {
  return { type: Types.RESET_DATA };
}

export function setLoading(loading: boolean): SetLoadingAction {
  return { type: Types.SET_LOADING, payload: { loading } };
}

export function changeParams(params: any): ChangeParamsAction {
  return { type: Types.CHANGE_PARAMS, payload: { params } };
}

export function openDeleteDialog(data: any): OpenDeleteDialogAction {
  return { type: Types.OPEN_DELETE_DIALOG, payload: { data } };
}

export function hideDeleteDialog(): HideDeleteDialogAction {
  return { type: Types.HIDE_DELETE_DIALOG };
}

export function completeDelete(): EndDeleteDataAction {
  return { type: Types.END_DELETE_DATA };
}

export function deleteData(data: any): BeginDeleteDataAction {
  return { type: Types.BEGIN_DELETE_DATA, payload: { data } };
}

export function openEditDialog(data?: any): OpenEditDialogAction {
  return { type: Types.OPEN_EDIT_DIALOG, payload: { data } };
}

export function hideEditDialog(): HideEditDialogAction {
  return { type: Types.HIDE_EDIT_DIALOG };
}

export function saveData(data: any): UpdateRowAction {
  return { type: Types.UPDATE_DATA, payload: { data } };
}
