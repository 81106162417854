import { all, fork } from 'redux-saga/effects';

import watchNotification from './modules/notification/notification.saga';
import watchProductList from './modules/product-list/product-list.saga';
import watchProductItem from './modules/product-item/product-item.saga';
import watchProductFile from './modules/product-file/product-file.saga';
import watchProductShare from './modules/product-share/product-share.saga';
import watchProductCreate from './modules/product-create/product-create.saga';
import watchMarketingCreate from './modules/product-marketing/product-marketing.saga';
import watchRestrictionCreate from './modules/product-restriction/product-restriction.saga';
import watchRestrictionList from './modules/product-restriction-list/product-restriction-list.saga';
import watchResearchCreate from './modules/product-research/product-research.saga';
import watchResearchList from './modules/product-research-list/product-research-list.saga';
import watchOfferCreate from './modules/product-offer/product-offer.saga';
import watchFeedbackCreate from './modules/feedback-create/feedback-create.saga';
import watchFeedbackList from './modules/feedback-list/feedback-list.saga';
import watchFeedbackDetail from './modules/feedback-detail/feedback-detail.saga';
import watchUserList from './modules/user-list/user-list.saga';
import watchCategoryList from './modules/category-list/category-list.saga';
import watchCustomerService from './modules/customer-service/customer-service.saga';
import watchCustomerServiceList from './modules/customer-service-list/customer-service-list.saga';
import watchProductOfferList from './modules/product-offer-list/product-offer-list.saga';
import watchProductMarketingList from './modules/product-marketing-list/product-marketing-list.saga';
import watchBranchList from './modules/branch-list/branch-list.saga';
import productSkuList from './modules/product-sku-list/product-sku-list.saga';
import productTypeList from './modules/product-type-list/product-type-list.saga';
import productCriticalReport from './modules/product-critical-report/product-critical-report.saga';
import affiliate from './modules/affiliate/affiliate.saga';

export default function* root() {
  yield all([
    fork(watchNotification),
    fork(watchProductItem),
    fork(watchProductFile),
    fork(watchProductList),
    fork(watchProductCreate),
    fork(watchMarketingCreate),
    fork(watchResearchCreate),
    fork(watchResearchList),
    fork(watchRestrictionCreate),
    fork(watchRestrictionList),
    fork(watchFeedbackCreate),
    fork(watchFeedbackList),
    fork(watchFeedbackDetail),
    fork(watchUserList),
    fork(watchCategoryList),
    fork(watchOfferCreate),
    fork(watchCustomerService),
    fork(watchCustomerServiceList),
    fork(watchProductOfferList),
    fork(watchProductMarketingList),
    fork(watchProductShare),
    fork(watchBranchList),
    fork(productSkuList),
    fork(productTypeList),
    fork(productCriticalReport),
    fork(affiliate),
  ]);
}
