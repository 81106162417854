import { callApi } from '../utils/service.util';

const NOTIFICATION_API_ENDPOINT = 'v1/notification/v1';

export const getNotifications = async (params?: any) => {
  return callApi({
    method: 'get',
    url: NOTIFICATION_API_ENDPOINT,
    params,
  });
};

export const readNotification = async (data?: any) => {
  return callApi({
    method: 'put',
    url: `${NOTIFICATION_API_ENDPOINT}/read`,
    data,
  });
};
