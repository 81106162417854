/*eslint no-useless-escape: "off"*/
export const http_regex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
export const number_regex = /^[0-9]*$/;
export const image_regex = new RegExp(
  `\\.(png|jpg|jpeg|bmp|gif|webp|ico|heic)$`
);

export const ENV = {
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
};

export enum KEYBOARD {
  Enter = 13,
}

export enum MY_PRODUCT {
  YES = 1,
  NO = 2,
}

export enum PRODUCT_STATUS {
  Draft = 1,
  Approve = 2,
  Reject = 3,
  Pending = 1,
  Selling = 4,
}

export const PRODUCT_SECTION_STATUS = {
  APPROVED: 'APPROVED',
  UNAPPROVED: 'UNAPPROVED',
};

export const AVAILABLE_REGIONS = [
  'US',
  'CA',
  'EU',
  'UK',
  'AU',
  'SG',
  'IN',
  'JP',
  'BR',
  'IL',
];

export const CREATIVE_CHECKLIST_FIELDS = [
  'creative_product_intro',
  'creative_market_position',
  'creative_sample_pitch',
  'creative_mus',
  'creative_product_design',
  'creative_top_key_selling',
  'creative_target_demographic',
  'creative_competitor_analysis',
  'creative_potential_presale_angle',
  'creative_market_trends',
  'creative_compliance_guidelines',
  'creative_notes',
];

export enum HTTP_CODE {
  InternalServerError = 500,
  Unauthorized = 401,
}

export enum SNACKBAR_TYPE {
  Error = 'error',
  Success = 'success',
}

export enum TIMEOUT {
  Default = 300,
  Search = 1000,
  IntervalToken = 15 * 60 * 1000, // second
  RefreshToken = 15, // minute
}

export interface IList {
  count: number;
  data: any[];
  firstLoad: boolean;
  loading: boolean;
}

export const ListState: IList = {
  count: 0,
  data: [],
  firstLoad: true,
  loading: true,
};

export const LIST_STATE = {
  count: 0,
  data: [],
  firstLoad: true,
  loading: true,
  params: {
    page: 0,
    limit: 20,
    search: '',
    isOwner: false,
    status: [],
    sort: { createdAt: -1 },
  },
};

export const PRODUCT_MARKETABLE = {
  YES: 1,
  NO: 2,
  MAYBE: 3,
};

export const SIZE_100MB = 104857600;

export const PRODUCT_CRITICAL_REPORT_STATUS = {
  CRITICAL: 'CRITICAL',
  NON_CRITICAL: 'NON_CRITICAL',
};
