import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { ICustomerServiceListState } from './customer-service-list.constant';

export const customerServiceListSelector = (state: IState) => {
  return state.customerServiceList;
};

export const paramsSelector = createSelector(
  customerServiceListSelector,
  (customerServiceList: ICustomerServiceListState): any =>
    customerServiceList.params
);

export const loadingSelector = createSelector(
  customerServiceListSelector,
  (customerServiceList: ICustomerServiceListState): any =>
    customerServiceList.loading
);

export const firstLoadSelector = createSelector(
  customerServiceListSelector,
  (customerServiceList: ICustomerServiceListState): any =>
    customerServiceList.firstLoad
);

export const deletePopupSelector = createSelector(
  customerServiceListSelector,
  (customerServiceList: ICustomerServiceListState): any => ({
    deleting: customerServiceList.deleting,
    data: customerServiceList.selectedData,
    open: customerServiceList.showDeletePopup,
  })
);
