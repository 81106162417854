import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getAffiliateProducts } from 'services/product.service';
import { setData, setLoading } from './affiliate.action';
import { Types } from './affiliate.constant';
import { paramsSelector } from './affiliate.selector';

import { pushErrorMessage } from '../snackbar/snackbar.action';

function* watchLoadData() {
  try {
    yield put(setLoading(true));

    // @ts-ignore
    const params = yield select(paramsSelector);

    const { count, data } = yield call(getAffiliateProducts, params);

    yield put(setData({ count, data }));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(
      setData({
        count: 0,
        data: [],
      })
    );
  } finally {
    yield put(setLoading(false));
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
}
