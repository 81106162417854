import produce from 'immer';

import { Types, Actions, ILayout } from './layout.constant';

const initialState: ILayout = {
  open: false,
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.OPEN_SIDEBAR:
      draft.open = true;
      break;
    case Types.HIDE_SIDEBAR:
      draft.open = false;
      break;
  }
}, initialState);

export { initialState, reducer as default };
