import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { IProductTypeListState } from './product-type-list.constant';

export const productTypeListSelector = (state: IState) => {
  return state.productTypeList;
};

export const paramsSelector = createSelector(
  productTypeListSelector,
  (productTypeList: IProductTypeListState): any => productTypeList.params
);

export const loadingSelector = createSelector(
  productTypeListSelector,
  (productTypeList: IProductTypeListState): any => productTypeList.loading
);

export const firstLoadSelector = createSelector(
  productTypeListSelector,
  (productTypeList: IProductTypeListState): any => productTypeList.firstLoad
);

export const searchUISelector = createSelector(
  productTypeListSelector,
  (productTypeList: IProductTypeListState): any => {
    return {
      count: productTypeList.count,
      value: productTypeList.params.search,
      isOwner: productTypeList.params.isOwner,
      loading: productTypeList.loading,
    };
  }
);

export const listUISelector = createSelector(
  productTypeListSelector,
  (productTypeList: IProductTypeListState): any => {
    return {
      count: productTypeList.count,
      params: productTypeList.params,
      loading: productTypeList.loading,
      data: productTypeList.data,
      firstLoad: productTypeList.firstLoad,
    };
  }
);
