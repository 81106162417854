import { callApi } from 'utils/service.util';

const PRODUCT_API_ENDPOINT = 'v1/productLegal/v2';

export const getProductRestrictions = async (params?: any) => {
  return callApi({
    method: 'get',
    url: PRODUCT_API_ENDPOINT,
    params,
  });
};
export const deleteProductRestriction = async (_id: any) => {
  return callApi({
    method: 'delete',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
  });
};

export const updateProductRestriction = async (_id: string, data: any) => {
  return callApi({
    method: 'put',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
    data,
  });
};

export const getProductRestriction = async (_id: any) => {
  return callApi({
    method: 'get',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
  });
};

export const addProductRestriction = async (_id: string, data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
    data,
  });
};
