import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import _size from 'lodash/size';

import {
  addProductResearch,
  getProductResearch,
  updateProductResearch,
} from 'services/product-research.service';
import { pushErrorMessage } from 'store/modules/snackbar/snackbar.action';

import {
  LoadDataAction,
  SaveDataAction,
  Types,
} from './product-research.constant';
import {
  initFormData,
  saveDone,
} from '../product-research/product-research.action';
import { getCategoryList } from '../../../services/category.service';
import { permissionsSelector } from '../auth/auth.selector';

function* loadProduct(_id: string) {
  if (!_id) {
    return null;
  }

  // @ts-ignore
  let { product_market_research: data, generalName } = yield call(
    getProductResearch,
    _id
  );

  return {
    _id,
    generalName,
    research: data.research,
  };
}

function* watchLoadData(action: LoadDataAction) {
  const { _id } = action.payload;
  try {
    // @ts-ignore
    const [data, { data: categories }]: any = yield all([
      call(loadProduct, _id),
      // @ts-ignore
      yield call(getCategoryList, { page: 0, limit: 100 }),
    ]);

    yield put(initFormData(data, categories));
  } catch (error) {
    yield put(pushErrorMessage(error));
  }
}

export const formatBody = (data: any, fields: any) => {
  const formData: any = {};

  Object.keys(data).forEach(function (key) {
    if (
      key === '_id' ||
      key === 'generalName' ||
      (fields && fields.length > 0 && !fields.includes(key))
    ) {
      return;
    }

    const value = data[key];

    if (Array.isArray(value)) {
      formData[key] = (value || []).filter((e: any) => _size(e) > 0);
    } else {
      formData[key] = value;
    }
  });

  return { formData };
};

function* watchSaveData(action: SaveDataAction) {
  let completed = true;
  try {
    // @ts-ignore
    const {
      productMarketResearch: { fields },
    } = yield select(permissionsSelector);

    const { product, ...data } = action.payload.data;

    const { formData } = formatBody(data, fields);

    if (data._id) {
      yield call(updateProductResearch, data._id, formData);
    } else {
      yield call(addProductResearch, product._id, formData);
    }
  } catch (error) {
    yield put(pushErrorMessage(error));

    completed = false;
  }

  yield put(saveDone(completed));
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.SAVE_DATA, watchSaveData);
}
