export interface IProductMarketingState {
  data: any;
  showConfirmPopup: boolean;
  loading: boolean;
  saving: boolean;
  completed: boolean;
}

export enum Types {
  SAVE_DATA = '[PRODUCT MARKETING ITEM] SAVE DATA',
  INIT_FORM = '[PRODUCT MARKETING ITEM] INIT FORM',
  SAVE_DONE = '[PRODUCT MARKETING ITEM] SAVE DONE',
  SET_COMPLETED = '[PRODUCT MARKETING ITEM] SET COMPLETED',
  LOAD_DATA = '[PRODUCT MARKETING ITEM] LOAD DATA',
  CLEAR_STATE = '[PRODUCT MARKETING ITEM] CLEAR STATE',
  SHOW_CONFIRM_POPUP = '[PRODUCT MARKETING ITEM] SHOW_CONFIRM_POPUP',
  HIDE_CONFIRM_POPUP = '[PRODUCT MARKETING ITEM] HIDE_CONFIRM_POPUP',
}

export interface InitFormAction {
  type: typeof Types.INIT_FORM;
  payload: { data?: any };
}

export interface SaveDataAction {
  type: typeof Types.SAVE_DATA;
  payload: { data: any };
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: { _id: any };
}

export interface SaveDoneAction {
  type: typeof Types.SAVE_DONE;
  payload: { completed: boolean };
}

export interface SetCompletedAction {
  type: typeof Types.SET_COMPLETED;
  payload: { completed: boolean };
}

export interface ShowConfirmPopupAction {
  type: typeof Types.SHOW_CONFIRM_POPUP;
  payload: { data?: any };
}

export interface HideConfirmPopupAction {
  type: typeof Types.HIDE_CONFIRM_POPUP;
}

export interface ClearStateAction {
  type: typeof Types.CLEAR_STATE;
}

export type Actions =
  | InitFormAction
  | SaveDataAction
  | SaveDoneAction
  | SetCompletedAction
  | LoadDataAction
  | ClearStateAction
  | ShowConfirmPopupAction
  | HideConfirmPopupAction;
