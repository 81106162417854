import { call, put, takeLatest } from 'redux-saga/effects';

import { deleteFeedback, getFeedback } from 'services/feedback.service';
import {
  pushErrorMessage,
  pushSuccessMessage,
} from 'store/modules/snackbar/snackbar.action';

import { completeDelete, setData } from './feedback-detail.action';
import {
  BeginDeleteDataAction,
  LoadDataAction,
  Types,
} from './feedback-detail.constant';
import { scanImages } from '../../../utils/form-data.util';

export function* watchGetFeedback(feedbackId: string) {
  try {
    // @ts-ignore
    return yield yield call(getFeedback, feedbackId);
  } catch (e) {
    return {};
  }
}

function* watchLoadData(action: LoadDataAction) {
  try {
    // @ts-ignore
    const data = yield call(watchGetFeedback, action.payload.feedbackId);

    Object.keys(data).forEach(function (key) {
      // eslint-disable-next-line
      if (data[key] == '0') {
        data[key] = '';
      }
    });

    scanImages({ data, keys: ['drawings'] });

    yield put(setData(data));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData({}));
  }
}

function* watchDeleteData(action: BeginDeleteDataAction) {
  try {
    yield call(deleteFeedback, action.payload.feedbackId);

    yield put(pushSuccessMessage('Success!'));
    yield put(completeDelete());
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(completeDelete());
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.BEGIN_DELETE_DATA, watchDeleteData);
}
