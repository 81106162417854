import { all, call, put, takeLatest } from 'redux-saga/effects';

import { SIZE_100MB } from 'constants/common';
import { size } from 'lodash';
import { updateImage, upload } from 'services/image.service';
import { addFeedback, updateFeedback } from 'services/feedback.service';
import { pushErrorMessage } from 'store/modules/snackbar/snackbar.action';
import { scanImages } from 'utils/form-data.util';

import {
  LoadDataAction,
  SaveDataAction,
  Types,
} from './feedback-create.constant';
import { saveDone, setData } from './feedback-create.action';
import { watchGetProduct } from '../product-item/product-item.saga';
import { watchGetFeedback } from '../feedback-detail/feedback-detail.saga';

function* watchLoadData(action: LoadDataAction) {
  const { feedbackId, productId } = action.payload;

  try {
    if (feedbackId) {
      // @ts-ignore
      const [feedback, product]: any = yield all([
        call(watchGetFeedback, feedbackId),
        call(watchGetProduct, productId),
      ]);

      scanImages({ data: feedback, keys: ['drawings'] });

      yield put(setData(feedback, product));
    } else {
      // @ts-ignore
      const product = yield call(watchGetProduct, productId);
      yield put(setData(null, product));
    }
  } catch (error) {
    yield put(pushErrorMessage(error));
    yield put(setData());
  }
}

const formatBody = (data: any, isUpdate: Boolean) => {
  const formData: any = new FormData();

  const fileSaveds: any = {
    drawings: [],
  };

  const fileLarges: any = {
    drawings: [],
  };

  Object.keys(data).forEach(function (key) {
    if (key === '_id') {
      return;
    }

    if (!['drawings'].includes(key)) {
      const value = data[key];
      if (Array.isArray(value)) {
        if (key === 'factories') {
          formData.append(key, JSON.stringify(value));
          return;
        }

        value.forEach((e: string) => {
          let v = e || '';

          if (v.length > 0) {
            formData.append(key, v);
          }
        });
      } else if (value || typeof value === 'boolean') {
        formData.append(key, value);
      }
    } else {
      data[key].forEach((e: any) => {
        if (e._id) {
          fileSaveds[key].push(e);
          return;
        }

        if (e.file.size > SIZE_100MB) {
          fileLarges[key].push(e);
        } else {
          if (isUpdate) {
            formData.append(`${key}[]`, e.file);
          } else {
            formData.append(key, e.file);
          }
        }
      });
    }
  });

  return { formData, fileSaveds, fileLarges };
};

function* saveImageToCloud(fileLarges: any) {
  const { drawings } = fileLarges;

  // Create a unique name for the container by
  // appending the current time to the file name
  const timeId = '_' + new Date().getTime();
  // @ts-ignore
  const azure = yield call(upload, 'images-original');
  // @ts-ignore
  const drawingUrls = yield call(updateImage, drawings, timeId, azure);

  return { drawingUrls };
}

function* watchSaveData(action: SaveDataAction) {
  let completed = true;
  try {
    const { productId, data } = action.payload;
    const isUpdate = data._id ? true : false;
    const { formData, fileSaveds, fileLarges } = formatBody(data, isUpdate);

    let drawings;
    if (size(fileLarges) > 0) {
      const { drawingUrls } = yield call(saveImageToCloud, fileLarges);

      drawings = [...fileSaveds.drawings, ...drawingUrls];
    } else {
      drawings = [...fileSaveds.drawings];
    }

    formData.append('drawings', JSON.stringify(drawings));

    if (isUpdate) {
      // @ts-ignore
      const newData = yield call(updateFeedback, data._id, formData);
      scanImages({ data: newData, keys: ['drawings'] });

      yield put(setData(newData));
    } else {
      yield call(addFeedback, productId, formData);
    }
  } catch (error) {
    yield put(pushErrorMessage(error));

    completed = false;
  }

  yield put(saveDone(completed));
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.SAVE_DATA, watchSaveData);
}
