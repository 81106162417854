import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  addMarketing,
  getMarketing,
  updateMarketing,
} from 'services/product-marketing.service';
import { pushErrorMessage } from 'store/modules/snackbar/snackbar.action';
import { scanImages } from 'utils/form-data.util';

import {
  LoadDataAction,
  SaveDataAction,
  Types,
} from './product-marketing.constant';
import {
  initFormData,
  saveDone,
} from '../product-marketing/product-marketing.action';
import { permissionsSelector } from '../auth/auth.selector';
import { size } from 'lodash';

function* loadProduct(_id: string) {
  if (!_id) {
    return null;
  }

  // @ts-ignore
  let { product_marketing: data, generalName } = yield call(getMarketing, _id);

  scanImages({
    data,
    keys: ['product_marketing_image', 'documents'],
  });

  return {
    _id,
    generalName,
    product_marketing_name: data.product_marketing_name,
    product_marketing_cost: data.product_marketing_cost,
    product_marketing_image: data.product_marketing_image,
    documents: data.documents,
    links_v2: data.links_v2,
  };
}

function* watchLoadData(action: LoadDataAction) {
  const { _id } = action.payload;
  let product;
  try {
    // @ts-ignore
    product = yield call(loadProduct, _id);
  } catch (error) {
    yield put(pushErrorMessage(error));
  }
  yield put(initFormData(product));
}

export const formatBody = (data: any, fields: any) => {
  const formData: any = new FormData();

  const savedFiles: any = {
    product_marketing_image: [],
    documents: [],
  };

  Object.keys(data).forEach(function (key) {
    if (
      key === '_id' ||
      key === 'generalName' ||
      (fields && fields.length > 0 && !fields.includes(key))
    ) {
      return;
    }

    if (!['product_marketing_image', 'documents'].includes(key)) {
      const value = data[key];

      if (Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    } else {
      data[key].forEach((e: any) => {
        if (e._id) {
          savedFiles[key].push(e);
          return;
        }

        formData.append(`${key}[]`, e.file);
      });
    }
  });

  return { formData, savedFiles };
};

function* watchSaveData(action: SaveDataAction) {
  let completed = true;
  try {
    // @ts-ignore
    const {
      productMarketing: { fields },
    } = yield select(permissionsSelector);

    const { product, ...data } = action.payload.data;

    const { formData, savedFiles } = formatBody(data, fields);

    let product_marketing_image = [...savedFiles.product_marketing_image];
    let documents = [...savedFiles.documents];

    if (size(fields) < 1 || fields.includes('product_marketing_image')) {
      formData.append(
        'product_marketing_image',
        JSON.stringify(product_marketing_image)
      );
    }
    if (size(fields) < 1 || fields.includes('documents')) {
      formData.append('documents', JSON.stringify(documents));
    }

    if (data._id) {
      yield call(updateMarketing, data._id, formData);
    } else {
      yield call(addMarketing, product._id, formData);
    }
  } catch (error) {
    yield put(pushErrorMessage(error));

    completed = false;
  }

  yield put(saveDone(completed));
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.SAVE_DATA, watchSaveData);
}
