import produce from 'immer';

import { Actions, IProductItemState, Types } from './product-item.constant';

const initialState: IProductItemState = {
  data: {},
  popupData: {},
  loading: true,
  deleting: false,
  updating: false,
  selling: false,
  pending: false,
  showRejectPopup: false,
  showApprovePopup: false,
  showDeletePopup: false,
  showSellingPopup: false,
  showPendingPopup: false,
  showApproveSectionPopup: false,
  showDisapproveSectionPopup: false,
  showGoLivePopup: false,
  offer: null,
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.LOAD_DATA:
      draft.loading = true;
      draft.deleting = false;
      break;
    case Types.BEGIN_DELETE_DATA:
      draft.deleting = true;
      break;
    case Types.END_DELETE_DATA:
      draft.data = {};
      draft.deleting = false;
      draft.showDeletePopup = false;
      break;
    case Types.SET_DATA:
      const { data, offer } = action.payload;
      draft.data = data;
      draft.offer = offer;
      draft.loading = false;
      break;
    case Types.UPDATE_DATA:
      draft.data = { ...draft.data, ...action.payload.data };
      break;
    case Types.SHOW_DELETE_POPUP:
      draft.showDeletePopup = true;
      if (action.payload.data) {
        draft.data = action.payload.data;
      }
      break;
    case Types.HIDE_DELETE_POPUP:
      draft.showDeletePopup = false;
      break;
    case Types.SHOW_APPROVE_POPUP:
      draft.showApprovePopup = true;
      break;
    case Types.HIDE_APPROVE_POPUP:
      draft.showApprovePopup = false;
      break;
    case Types.SHOW_REJECT_POPUP:
      draft.showRejectPopup = true;
      break;
    case Types.HIDE_REJECT_POPUP:
      draft.showRejectPopup = false;
      break;
    case Types.UPDATE_STATUS:
      draft.updating = true;
      break;
    case Types.UPDATE_SECTION_STATUS:
      draft.updating = true;
      break;
    case Types.SET_STATUS:
      draft.data.status = action.payload.status;
      draft.data.reason = action.payload.reason;
      break;
    case Types.SHOW_PENDING_POPUP:
      draft.showPendingPopup = true;
      break;
    case Types.HIDE_PENDING_POPUP:
      draft.showPendingPopup = false;
      break;
    case Types.SHOW_SELLING_POPUP:
      draft.showSellingPopup = true;
      break;
    case Types.HIDE_SELLING_POPUP:
      draft.showSellingPopup = false;
      break;
    case Types.SHOW_APPROVE_SECTION_POPUP:
      draft.showApproveSectionPopup = true;
      draft.popupData = action.payload.data;
      break;
    case Types.HIDE_APPROVE_SECTION_POPUP:
      draft.showApproveSectionPopup = false;
      break;
    case Types.SHOW_DISAPPROVE_SECTION_POPUP:
      draft.showDisapproveSectionPopup = true;
      draft.popupData = action.payload.data;
      break;
    case Types.HIDE_DISAPPROVE_SECTION_POPUP:
      draft.showDisapproveSectionPopup = false;
      break;
    case Types.SHOW_GO_LIVE_POPUP:
      draft.showGoLivePopup = true;
      break;
    case Types.HIDE_GO_LIVE_POPUP:
      draft.showGoLivePopup = false;
      break;
    case Types.CLOSE_UPDATE_POPUP:
      draft.updating = false;
      draft.showRejectPopup = false;
      draft.showApprovePopup = false;
      draft.showPendingPopup = false;
      draft.showSellingPopup = false;
      draft.showApproveSectionPopup = false;
      draft.showDisapproveSectionPopup = false;
      draft.showGoLivePopup = false;
      break;
  }
}, initialState);

export { initialState, reducer as default };
