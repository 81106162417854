import { call, put, select, takeLatest } from 'redux-saga/effects';
import _size from 'lodash/size';

import {
  getCategoryList,
  saveCategory,
  updateCategory,
  deleteCategory,
} from 'services/category.service';

import {
  changeParams,
  hideEditDialog,
  loadData,
  setData,
} from './category-list.action';
import {
  BeginDeleteDataAction,
  Types,
  UpdateRowAction,
} from './category-list.constant';
import { paramsSelector } from './category-list.selector';

import {
  pushErrorMessage,
  pushSuccessMessage,
} from '../snackbar/snackbar.action';

function* watchLoadData() {
  try {
    const { headCells, order, status, ...params } = yield select(
      paramsSelector
    );
    if (_size(status)) {
      params.where = { status: { $in: status } };
    }

    const { count, data } = yield call(getCategoryList, params);

    yield put(setData(count, data));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData(0, []));
  }
}

function* watchUpdateData(action: UpdateRowAction | BeginDeleteDataAction) {
  try {
    const { _id, ...data } = action.payload.data;

    if (_id) {
      yield call(updateCategory, _id, data);
    } else {
      yield call(saveCategory, data);
    }

    yield put(pushSuccessMessage('Success!'));

    yield put(hideEditDialog());

    yield put(loadData());
  } catch (e) {
    yield put(hideEditDialog());
    yield put(pushErrorMessage(e));
  }
}

function* watchDeleteData(action: UpdateRowAction | BeginDeleteDataAction) {
  try {
    const { _id } = action.payload.data;

    yield call(deleteCategory, _id);
    yield put(pushSuccessMessage('Success!'));

    yield put(hideEditDialog());

    yield put(changeParams({ page: 0 }));
  } catch (e) {
    yield put(hideEditDialog());
    yield put(pushErrorMessage(e));
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.CHANGE_PARAMS, watchLoadData);
  yield takeLatest(Types.UPDATE_DATA, watchUpdateData);
  yield takeLatest(Types.BEGIN_DELETE_DATA, watchDeleteData);
}
