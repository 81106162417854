import React, { useMemo } from 'react';

import {
  Grid,
  MenuItem,
  Select,
  Typography,
  FormControl,
} from '@material-ui/core';

import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { useTranslation } from 'react-i18next';
import { Root } from './pagination.styled';

const Comp = (props: any) => {
  const { t } = useTranslation();

  const menus = useMemo(() => {
    const menus = [];

    if (props.count > 1) {
      for (let i = 0; i < props.count; i++) {
        menus.push(
          <MenuItem key={i} value={i + 1}>
            {i + 1}
          </MenuItem>
        );
      }
    }

    return menus;
  }, [props.count]);

  if (props.count < 2) {
    return null;
  }

  return (
    <Root>
      <Grid
        container
        spacing={1}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid item>
          <Typography className={'page-text'}>{t('page')}</Typography>
        </Grid>
        <Grid item>
          <FormControl variant="outlined" className={'page-select'}>
            <Select
              value={props.page}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                props.onChange(event, parseInt(event.target.value as string));
              }}
            >
              {menus}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={'pagination'}>
          <Pagination
            renderItem={(item: any) => <PaginationItem {...item} />}
            {...props}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

export { Comp as Pagination };
