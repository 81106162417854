import { call, put, takeLatest } from 'redux-saga/effects';

import { shareLink } from 'services/share.service';
import {
  pushErrorMessage,
  pushSuccessMessage,
} from 'store/modules/snackbar/snackbar.action';

import { hideSharePopup } from './product-share.action';
import { BeginShareDataAction, Types } from './product-share.constant';

function* watchShareData(action: BeginShareDataAction) {
  try {
    yield call(shareLink, action.payload.data);

    yield put(pushSuccessMessage('Success!'));
  } catch (e) {
    yield put(pushErrorMessage(e));
  }
  yield put(hideSharePopup());
}

export default function* root() {
  yield takeLatest(Types.BEGIN_SHARE_DATA, watchShareData);
}
