export interface IProductOfferListState {
  count: number;
  data: any[];
  firstLoad: boolean;
  loading: boolean;
  selectedData: any;
  showDeletePopup: boolean;
  deleting: boolean;
  params: {
    page: number;
    limit: number;
    search: string;
    status: any;
    isOwner: boolean;
  };
}

export enum Types {
  SHOW_DELETE_POPUP = '[PRODUCT OFFER LIST] SHOW_DELETE_POPUP',
  HIDE_DELETE_POPUP = '[PRODUCT OFFER LIST] HIDE_DELETE_POPUP',
  BEGIN_DELETE_DATA = '[PRODUCT OFFER LIST] BEGIN_DELETE_DATA',
  END_DELETE_DATA = '[PRODUCT OFFER LIST] END_DELETE_DATA',
  CHANGE_PARAMS = '[PRODUCT OFFER LIST] CHANGE PARAMS',
  SET_DATA = '[PRODUCT OFFER LIST] SET_DATA',
  LOAD_DATA = '[PRODUCT OFFER LIST] LOAD_DATA',
  SET_LOADING = '[PRODUCT OFFER LIST] SET_LOADING',
  CLEAR_STATE = '[PRODUCT OFFER LIST] CLEAR_STATE',
}

export interface ClearStateAction {
  type: typeof Types.CLEAR_STATE;
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    loading: boolean;
  };
}

export interface ChangeParamsAction {
  type: typeof Types.CHANGE_PARAMS;
  payload: {
    params: any;
  };
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: {
    params: any;
  };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: any[]; count: number };
}

export interface ShowDeletePopupAction {
  type: typeof Types.SHOW_DELETE_POPUP;
  payload: { data: any };
}

export interface HideDeletePopupAction {
  type: typeof Types.HIDE_DELETE_POPUP;
}

export interface BeginDeleteDataAction {
  type: typeof Types.BEGIN_DELETE_DATA;
  payload: { fnCallback?: any; offerId: string };
}

export interface EndDeleteDataAction {
  type: typeof Types.END_DELETE_DATA;
}

export type Actions =
  | ClearStateAction
  | SetDataAction
  | LoadDataAction
  | ChangeParamsAction
  | SetLoadingAction
  | ShowDeletePopupAction
  | HideDeletePopupAction
  | EndDeleteDataAction
  | BeginDeleteDataAction;
