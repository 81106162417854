import { callApi } from 'utils/service.util';

const PRODUCT_API_ENDPOINT = 'v1/productMarketing/v2';
const headers = {
  'Content-Type': 'multipart/form-data',
};

export const getMarketings = async (params?: any) => {
  return callApi({
    method: 'get',
    url: PRODUCT_API_ENDPOINT,
    params,
  });
};
export const deleteMarketing = async (_id: any) => {
  return callApi({
    method: 'delete',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
  });
};

export const updateMarketing = async (_id: string, data: any) => {
  return callApi({
    method: 'put',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
    data,
    headers,
  });
};

export const getMarketing = async (_id: any) => {
  return callApi({
    method: 'get',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
  });
};

export const addMarketing = async (_id: string, data: any) => {
  return callApi({
    method: 'post',
    url: `${PRODUCT_API_ENDPOINT}/${_id}`,
    data,
  });
};
