import { callApi } from 'utils/service.util';

const FEEDBACK_API_ENDPOINT = '/v1/feedback/v1';
const headers = {
  'Content-Type': 'multipart/form-data',
};

export const addFeedback = async (_id: any, data: any) => {
  return callApi({
    method: 'post',
    url: `${FEEDBACK_API_ENDPOINT}/productId/${_id}`,
    data,
    headers,
  });
};

export const updateFeedback = async (_id: string, data: any) => {
  return callApi({
    method: 'put',
    url: `${FEEDBACK_API_ENDPOINT}/${_id}`,
    data,
    headers,
  });
};

export const getFeedback = async (_id: string) => {
  return callApi({
    method: 'get',
    url: `${FEEDBACK_API_ENDPOINT}/${_id}`,
  });
};

export const getFeedbackList = async (params?: any) => {
  return callApi({
    method: 'get',
    url: `${FEEDBACK_API_ENDPOINT}`,
    params,
  });
};

export const deleteFeedback = async (_id: string) => {
  return callApi({
    method: 'delete',
    url: `${FEEDBACK_API_ENDPOINT}/${_id}`,
  });
};
