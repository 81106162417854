export enum ENTITY {
  User = 'User',
  Category = 'Category',
  Product = 'Product',
  Product_Customer_Service = 'Product_Customer_Service',
  Product_Legal = 'Product_Legal',
  Product_Market_Research = 'Product_Market_Research',
  Product_Marketing = 'Product_Marketing',
  Offer = 'Offer',
}

export enum ACTION {
  MANAGE = 'MANAGE',
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  APPROVE = 'APPROVE',
}

export enum ROLE_ENUM {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  C_LEVEL = 'C_LEVEL',
  USER = 'USER',
}

export const ROLE_LIST = Object.keys(ROLE_ENUM);

export enum PERMISSION_ENUM {
  EXECUTIVE = 'EXECUTIVE',
  EMPLOYEE = 'EMPLOYEE',
}

export const PERMISSION_LIST = Object.keys(PERMISSION_ENUM);

export enum DEPARTMENT_ENUM {
  ADMINISTRATION = 'ADMINISTRATION',
  BER = 'BER',
  BUSINESS_DEVELOPMENT = 'BUSINESS_DEVELOPMENT',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  FINANCE_ACCOUNTING = 'FINANCE_ACCOUNTING',
  FRONT_END_AND_WEB_DEVELOPMENT = 'FRONT_END_AND_WEB_DEVELOPMENT',
  HUMAN_RESOURCES = 'HUMAN_RESOURCES',
  INFORMATION_TECHNOLOGY = 'INFORMATION_TECHNOLOGY',
  INTERNAL_COMPLIANCE = 'INTERNAL_COMPLIANCE',
  LEGAL = 'LEGAL',
  LOGISTICS = 'LOGISTICS',
  MARKETING_OPERATIONS = 'MARKETING_OPERATIONS',
  MEDIA_OPERATIONS = 'MEDIA_OPERATIONS',
  PROCUREMENT = 'PROCUREMENT',
  PRODUCT_DEVELOPMENT = 'PRODUCT_DEVELOPMENT',
  SALES_OPERATIONS = 'SALES_OPERATIONS',
  SOFTWARE_PRODUCT_DEVELOPMENT = 'SOFTWARE_PRODUCT_DEVELOPMENT',
  SPECIAL_TACTICS = 'SPECIAL_TACTICS',
  STAFF = 'STAFF',
  AFFILIATES = 'AFFILIATES',
  AMAZON = 'AMAZON',
  EXECUTIVES = 'EXECUTIVES',
}

export const DEPARTMENT_LIST = Object.keys(PERMISSION_ENUM);

export interface IUser {
  _id: string;
  roles: ROLE_ENUM[];
  permissions: PERMISSION_ENUM[];
  department: DEPARTMENT_ENUM[];
}
