import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { IBranchListState } from './branch-list.constant';

export const branchListSelector = (state: IState) => {
  return state.branchList;
};

export const paramsSelector = createSelector(
  branchListSelector,
  (branchList: IBranchListState): any => branchList.params
);

export const loadingSelector = createSelector(
  branchListSelector,
  (branchList: IBranchListState): any => branchList.loading
);

export const firstLoadSelector = createSelector(
  branchListSelector,
  (branchList: IBranchListState): any => branchList.firstLoad
);

export const searchUISelector = createSelector(
  branchListSelector,
  (branchList: IBranchListState): any => {
    return {
      count: branchList.count,
      value: branchList.params.search,
      isOwner: branchList.params.isOwner,
      loading: branchList.loading,
    };
  }
);

export const listUISelector = createSelector(
  branchListSelector,
  (branchList: IBranchListState): any => {
    return {
      count: branchList.count,
      params: branchList.params,
      loading: branchList.loading,
      data: branchList.data,
      firstLoad: branchList.firstLoad,
    };
  }
);
