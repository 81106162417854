import { IState } from 'store/constants';
import { createSelector } from 'reselect';
import { IProductOfferListState } from './product-offer-list.constant';

export const productOfferListSelector = (state: IState) => {
  return state.productOfferList;
};

export const paramsSelector = createSelector(
  productOfferListSelector,
  (productOfferList: IProductOfferListState): any => productOfferList.params
);

export const loadingSelector = createSelector(
  productOfferListSelector,
  (productOfferList: IProductOfferListState): any => productOfferList.loading
);

export const firstLoadSelector = createSelector(
  productOfferListSelector,
  (productOfferList: IProductOfferListState): any => productOfferList.firstLoad
);

export const deletePopupSelector = createSelector(
  productOfferListSelector,
  (productOfferList: IProductOfferListState): any => ({
    deleting: productOfferList.deleting,
    data: productOfferList.selectedData,
    open: productOfferList.showDeletePopup,
  })
);
