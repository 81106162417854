import produce from 'immer';

import { Actions, IProductShareState, Types } from './product-share.constant';

const initialState: IProductShareState = {
  data: {},
  sharing: false,
  showSharePopup: false,
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.BEGIN_SHARE_DATA:
      draft.sharing = true;
      break;
    case Types.SHOW_SHARE_POPUP:
      draft.sharing = false;
      draft.showSharePopup = true;
      draft.data = action.payload.data;
      break;
    case Types.HIDE_SHARE_POPUP:
      draft.showSharePopup = false;
      break;
  }
}, initialState);

export { initialState, reducer as default };
