import {
  GoBackTabAction,
  GoNextTabAction,
  LoadDataAction,
  MakeNewRequestAction,
  SaveDataAction,
  SaveDoneAction,
  SetDataAction,
  Types,
} from './feedback-create.constant';

export function goNextTab(): GoNextTabAction {
  return { type: Types.GO_NEXT_TAB };
}

export function goBackTab(): GoBackTabAction {
  return { type: Types.GO_BACK_TAB };
}

export function saveData(productId: string, data: any): SaveDataAction {
  return { type: Types.SAVE_DATA, payload: { productId, data } };
}

export function saveDone(completed: boolean): SaveDoneAction {
  return { type: Types.SAVE_DONE, payload: { completed } };
}

export function makeNewFeedback(): MakeNewRequestAction {
  return { type: Types.MAKE_A_NEW };
}

export function loadData(feedbackId: string, productId: any): LoadDataAction {
  return { type: Types.LOAD_DATA, payload: { feedbackId, productId } };
}

export function setData(feedback?: any, product?: any): SetDataAction {
  return { type: Types.SET_DATA, payload: { feedback, product } };
}
