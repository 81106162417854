import { call, put, select, takeLatest } from 'redux-saga/effects';

import { deleteFeedback, getFeedbackList } from 'services/feedback.service';

import { BeginDeleteDataAction, Types } from './feedback-list.constant';
import { paramsSelector } from './feedback-list.selector';

import { setData, completeDelete, setLoading } from './feedback-list.action';

import {
  pushErrorMessage,
  pushSuccessMessage,
} from '../snackbar/snackbar.action';

function* watchLoadData() {
  try {
    yield put(setLoading(true));

    // @ts-ignore
    const params = yield select(paramsSelector);

    const { count, data } = yield call(getFeedbackList, params);

    yield put(setData(count, data));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData(0, []));
  }
}

function* watchDeleteData(action: BeginDeleteDataAction) {
  try {
    yield call(deleteFeedback, action.payload.feedbackId);

    yield put(pushSuccessMessage('Success!'));
    yield put(completeDelete());
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(completeDelete());
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.CHANGE_PARAMS, watchLoadData);
  yield takeLatest(Types.BEGIN_DELETE_DATA, watchDeleteData);
  yield takeLatest(Types.END_DELETE_DATA, watchLoadData);
}
