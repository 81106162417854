import { call, put, select, takeLatest } from 'redux-saga/effects';
import _get from 'lodash/get';

import {
  deleteProductRestriction,
  getProductRestrictions,
} from 'services/product-restriction.service';

import {
  completeDelete,
  setData,
  setLoading,
} from './product-restriction-list.action';
import {
  BeginDeleteDataAction,
  Types,
} from './product-restriction-list.constant';
import { paramsSelector } from './product-restriction-list.selector';

import {
  pushErrorMessage,
  pushSuccessMessage,
} from '../snackbar/snackbar.action';

function* watchLoadData() {
  try {
    yield put(setLoading(true));

    // @ts-ignore
    const params = yield select(paramsSelector);

    const { count, data } = yield call(getProductRestrictions, params);

    if (data) {
      data.forEach((d: any) => {
        d.owner =
          _get(d, 'product_legal.user.firstName', '') +
          ' ' +
          _get(d, 'product_legal.user.lastName', '');
      });
    }

    yield put(setData(count, data));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData(0, []));
  }
}

function* watchDeleteData(action: BeginDeleteDataAction) {
  try {
    yield call(deleteProductRestriction, action.payload.itemId);

    yield put(pushSuccessMessage('Success!'));
    yield put(completeDelete());
    if (action.payload.fnCallback) {
      action.payload.fnCallback();
    }
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(completeDelete());
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.CHANGE_PARAMS, watchLoadData);
  yield takeLatest(Types.BEGIN_DELETE_DATA, watchDeleteData);
  yield takeLatest(Types.END_DELETE_DATA, watchLoadData);
}
