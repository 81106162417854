import {
  ChangeParamsAction,
  ClearStateAction,
  LoadBranchListAction,
  LoadDataAction,
  SetBranchListAction,
  SetDataAction,
  SetLoadingAction,
  Types,
  ClearBranchParamAction,
  ClearParamsAction,
} from './product-list.constant';

export function setData(count: number, data: any): SetDataAction {
  return { type: Types.SET_DATA, payload: { count, data } };
}

export function loadData(params: any = {}): LoadDataAction {
  return { type: Types.LOAD_DATA, payload: { params } };
}

export function changeParams(params: any): ChangeParamsAction {
  return { type: Types.CHANGE_PARAMS, payload: { params } };
}

export function setLoading(loading: boolean): SetLoadingAction {
  return { type: Types.SET_LOADING, payload: { loading } };
}

export function loadBranchList(): LoadBranchListAction {
  return { type: Types.LOAD_BRANCH_LIST };
}

export function setBranchList(data: any): SetBranchListAction {
  return { type: Types.SET_BRANCH_LIST, payload: { data } };
}

export function clearState(): ClearStateAction {
  return { type: Types.CLEAR_STATE };
}

export function clearBranch(): ClearBranchParamAction {
  return { type: Types.CLEAR_BRANCH_PARAM };
}

export function clearParams(): ClearParamsAction {
  return { type: Types.CLEAR_PARAMS };
}
