import { call, put, select, takeLatest } from 'redux-saga/effects';
import _size from 'lodash/size';
import _get from 'lodash/get';

import { getUserList, updateUser } from 'services/user.service';

import { loadData, setData, hideDeleteDialog } from './user-list.action';
import {
  Types,
  UpdateRowAction,
  BeginDeleteDataAction,
} from './user-list.constant';
import { paramsSelector } from './user-list.selector';

import {
  pushErrorMessage,
  pushSuccessMessage,
} from '../snackbar/snackbar.action';

function* watchLoadData() {
  try {
    const { headCells, order, status, ...params } = yield select(
      paramsSelector
    );
    if (_size(status)) {
      params.where = { status: { $in: status } };
    }

    const { count, data } = yield call(getUserList, params);
    if (data) {
      data.forEach((d: any) => {
        d.name = _get(d, 'firstName', '') + ' ' + _get(d, 'lastName', '');
        d.role = _get(d, 'roles[0]', '');
        d.department = _get(d, 'department[0]', '');
      });
    }

    yield put(setData(count, data));
  } catch (e) {
    yield put(pushErrorMessage(e));
    yield put(setData(0, []));
  }
}

function* watchUpdateData(action: UpdateRowAction | BeginDeleteDataAction) {
  try {
    const { _id, roles, department, isActive, isDeleted } = action.payload.data;

    yield call(updateUser, _id, { roles, isActive, department, isDeleted });

    yield put(pushSuccessMessage('Success!'));

    yield put(hideDeleteDialog());

    yield put(loadData());
  } catch (e) {
    yield put(hideDeleteDialog());
    yield put(pushErrorMessage(e));
  }
}

export default function* root() {
  yield takeLatest(Types.LOAD_DATA, watchLoadData);
  yield takeLatest(Types.CHANGE_PARAMS, watchLoadData);
  yield takeLatest(Types.END_DELETE_DATA, watchLoadData);
  yield takeLatest(Types.UPDATE_DATA, watchUpdateData);
  yield takeLatest(Types.BEGIN_DELETE_DATA, watchUpdateData);
}
