export interface IProductFileState {
  affiliate: boolean;
  loading: any;
  path: string;
  data: any;
  popup: any;
  selectedFiles: any;
  fileExtraMap: any;
}

export enum Types {
  SET_LOADING = '[PRODUCT FILE] SET_LOADING',
  LOAD_DATA = '[PRODUCT FILE] LOAD_DATA',
  SET_DATA = '[PRODUCT FILE] SET_DATA',
  UPDATE_DATA = '[PRODUCT FILE] UPDATE_DATA',
  SET_PATH = '[PRODUCT FILE] SET_PATH',
  SET_POPUP = '[PRODUCT FILE] SET_POPUP',
  UPLOAD_FILES = '[PRODUCT FILE] UPLOAD_FILES',
  CANCEL_UPLOAD_FILES = '[PRODUCT FILE] CANCEL_UPLOAD_FILES',
  UPDATE_FILES = '[PRODUCT FILE] UPDATE_FILES',
  CREATE_FOLDER = '[PRODUCT FILE] CREATE_FOLDER',
  SELECT_FILES = '[PRODUCT FILE] SELECT_FILES',
  DELETE_FILES = '[PRODUCT FILE] DELETE_FILES',
  DOWNLOAD_FILES = '[PRODUCT FILE] DOWNLOAD_FILES',
  SHARE_FILES = '[PRODUCT FILE] SHARE_FILES',
  GET_SHARE_FILES = '[PRODUCT FILE] GET_SHARE_FILES',
  STOP_SHARE_FILES = '[PRODUCT FILE] STOP_SHARE_FILES',
  GET_FILE_EXTRA = '[PRODUCT FILE] GET_FILE_EXTRA',
  SET_FILE_EXTRA_MAP = '[PRODUCT FILE] SET_FILE_EXTRA_MAP',
  OPEN_VIEW_MORE = '[PRODUCT FILE] OPEN_VIEW_MORE',
  RESET_STATE = '[PRODUCT FILE] RESET_STATE',
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    data: any;
  };
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: {
    data: any;
  };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: any };
}

export interface SetPopupAction {
  type: typeof Types.SET_POPUP;
  payload: { data: any };
}

export interface SetPathAction {
  type: typeof Types.SET_PATH;
  payload: { data: any };
}

export interface UploadFilesAction {
  type: typeof Types.UPLOAD_FILES;
  payload: {
    data: any;
  };
}

export interface CancelUploadFilesAction {
  type: typeof Types.CANCEL_UPLOAD_FILES;
  payload: {
    data: any;
  };
}

export interface UpdateFilesAction {
  type: typeof Types.UPDATE_FILES;
  payload: {
    data: any;
  };
}

export interface CreateFolderAction {
  type: typeof Types.CREATE_FOLDER;
  payload: {
    data: any;
  };
}

export interface SelectFilesAction {
  type: typeof Types.SELECT_FILES;
  payload: {
    data: any;
  };
}

export interface DeleteFilesAction {
  type: typeof Types.DELETE_FILES;
  payload: {
    data: any;
  };
}

export interface DownloadFilesAction {
  type: typeof Types.DOWNLOAD_FILES;
  payload: {
    data: any;
  };
}

export interface ShareFilesAction {
  type: typeof Types.SHARE_FILES;
  payload: {
    data: any;
  };
}

export interface GetShareFilesAction {
  type: typeof Types.GET_SHARE_FILES;
  payload: {
    data: any;
  };
}

export interface StopShareFilesAction {
  type: typeof Types.STOP_SHARE_FILES;
  payload: {
    data: any;
  };
}

export interface GetFileExtraAction {
  type: typeof Types.GET_FILE_EXTRA;
  payload: {
    data: any;
  };
}

export interface SetFileExtraMapAction {
  type: typeof Types.SET_FILE_EXTRA_MAP;
  payload: {
    data: any;
  };
}

export interface OpenViewMoreAction {
  type: typeof Types.OPEN_VIEW_MORE;
  payload: {
    data: any;
  };
}
export interface ResetStateAction {
  type: typeof Types.RESET_STATE;
}

export type Actions =
  | SetLoadingAction
  | LoadDataAction
  | SetDataAction
  | SetPathAction
  | SetPopupAction
  | UploadFilesAction
  | CancelUploadFilesAction
  | UpdateFilesAction
  | CreateFolderAction
  | SelectFilesAction
  | DeleteFilesAction
  | DownloadFilesAction
  | ShareFilesAction
  | GetShareFilesAction
  | StopShareFilesAction
  | GetFileExtraAction
  | SetFileExtraMapAction
  | OpenViewMoreAction
  | ResetStateAction;
