import produce from 'immer';

import cloneDeep from 'lodash/cloneDeep';

import { getCurrencies } from 'services/currency.service';

import {
  Actions,
  IFeedbackCreateState,
  IFeedbackModel,
  Types,
} from './feedback-create.constant';

const dataEmpty: IFeedbackModel = {
  productFound: true,
  status: 'Pending',
  competitorProductURLs: ['', '', ''],
  factories: [],
  moq: 0,
  price: 0,
  currency: 'USD',
  amount: 0,
  unitPrice: 0,
  usdUnitPrice: 0,
  factoryWhereFound: '',
  foundURLs: ['', '', ''],
  sampleRequest: true,
  sampleAmount: 0,
  estimatedCourier: 0,
  sampleCost: 0,
  productSku: '',
  pdFeedback: '',
  drawings: [],
  estimateCost: 0,
  jiraTicket: '',
  PODate: null,
  companyOrdering: '',
  businessSegment: '',
  comment: '',
  itemMaster: '',
  vendorInfo: '',
  paymentInfo: '',
  paymentTerms: '',
  deliveryTerms: '',
  packagingPricing: 0,
};

const initState: IFeedbackCreateState = {
  productId: null,
  product: null,
  data: cloneDeep(dataEmpty),
  currencies: getCurrencies(),
  steps: [1, 2, 3, 4],
  activeStep: 0,
  loading: true,
  saving: false,
  completed: false,
};

const reducer = produce((draft, action: Actions) => {
  switch (action.type) {
    case Types.MAKE_A_NEW:
      draft.completed = false;
      break;
    case Types.LOAD_DATA:
      draft.loading = true;
      draft.completed = false;
      draft.activeStep = 0;
      break;
    case Types.SET_DATA:
      draft.loading = false;
      if (action.payload.feedback) {
        draft.data = action.payload.feedback;
      } else {
        draft.data = cloneDeep(dataEmpty);
      }

      draft.product = action.payload.product;
      break;
    case Types.SAVE_DATA:
      draft.saving = true;
      draft.productId = action.payload.productId;
      draft.data = action.payload.data;
      break;
    case Types.SAVE_DONE:
      draft.completed = action.payload.completed;
      draft.saving = false;
      if (!draft.data._id && action.payload.completed) {
        draft.data = cloneDeep(dataEmpty);
      }
      draft.activeStep = 0;

      break;
    case Types.GO_NEXT_TAB:
      draft.activeStep += 1;
      break;
    case Types.GO_BACK_TAB:
      draft.activeStep -= 1;
      break;
  }
}, initState);

export { initState, reducer as default };
