export interface IProductModel {
  _id?: string;
  productBuildType?: string;
  generalName?: string;
  marketingName?: string;
  isTMFiled?: boolean;
  generalProductDescription?: string;
  productUsage?: string;
  productRequestDescription?: string;
  test_offer_links?: any;
  live_urls?: any;
  category?: string;
  productKeyWords?: string;
  packaging_weight?: string;
  packaging_length?: number;
  packaging_width?: number;
  packaging_height?: number;
  product_weight?: string;
  product_length?: number;
  product_width?: number;
  product_height?: number;
  tm_status?: string;
  productFeatures?: string;
  offer_links?: any;
  sample_delivered?: any;
  mvp_upsell?: any;
  creative_brief?: any;
  packaging_graphic?: any;
  packaging_link?: string;
  packaging_images?: any;
  packaging_languages?: any;
  quick_start_link?: any;
  warranty_link?: string;
  warranty_card_link?: any;
  warranty_domain_link?: any;
  warranty_slip?: any;
  instruction_manual?: any;
  im_link?: string;
  research_link?: any;
  research_competitors?: string;
  research_amazon?: string;
  customer_service_link?: any;
  customer_service_feedback?: any;
  trademark_country?: any;
  amazon_link?: any;
  barcode?: any;
  amazon_brand_registry?: any;
  brand_storefront_urls_v2?: any;
  tm_geos_registered?: any;
  safety_link?: any;
  moq?: any;
  cost_sign_off?: any;
  remarks?: any;
  tooling_sign_off?: any;
  inventory?: any;
  order_date?: any;
  trademark_status?: any;
  requestedFeatures?: any;
  mus?: any[];
  productImages?: any[];
  productSocialMedia?: string[];
  wantSamples?: boolean;
  samplesDestination?: any[];
  sketching?: any[];
  potentialPatents?: string;
  competitorProductURLs?: string[];
  jiraTicket?: string;
  reason?: string;
  status?: any;
  sku?: string;
  app_information?: any[];
  language?: string;
  design_patent?: boolean;
  utility_patent?: boolean;
  trademark?: boolean;
  product_costing?: string;
  lead_time?: string;
  cargo_ready_date?: any;
  specification?: string;
  daily_capacity?: string;
  product_marketing_name?: string;
  product_marketing_cost?: any;
  product_marketing_image?: any;
  documents?: any;
  links?: any;
  amazon_restriction?: any;
  traffic_source?: any;
  social_platform_restriction?: any;
  bank_restriction?: any;
  charge_back_rate?: any;
  consumer_study?: any;
  analytic_data?: any;

  links_v2?: any;
  amazon_restriction_v2?: any;
  traffic_source_v2?: any;
  social_platform_restriction_v2?: any;
  bank_restriction_v2?: any;
  charge_back_rate_v2?: any;
  research?: any;

  type_of_plug?: string[];
  product_version?: string;
  product_certification?: any[];
  packaging_version?: string;
  packaging_dimension?: string;
  cogs?: string;
  amazon?: boolean;
  funnel?: boolean;
  product_3pl_land_cogs?: string;
  product_azm_fba_land_cogs?: string;
}

export interface IProductItemState {
  data: IProductModel;
  popupData: any;
  offer: any;
  loading: boolean;
  updating: boolean;
  deleting: boolean;
  selling: boolean;
  pending: boolean;
  showApprovePopup: boolean;
  showRejectPopup: boolean;
  showSellingPopup: boolean;
  showDeletePopup: boolean;
  showPendingPopup: boolean;
  showApproveSectionPopup: boolean;
  showDisapproveSectionPopup: boolean;
  showGoLivePopup: boolean;
}

export enum Types {
  SET_DATA = '[PRODUCT ITEM] SET_DATA',
  UPDATE_DATA = '[PRODUCT ITEM] UPDATE_DATA',
  LOAD_DATA = '[PRODUCT ITEM] LOAD_DATA',
  SHOW_DELETE_POPUP = '[PRODUCT ITEM] SHOW_DELETE_POPUP',
  HIDE_DELETE_POPUP = '[PRODUCT ITEM] HIDE_DELETE_POPUP',
  BEGIN_DELETE_DATA = '[PRODUCT ITEM] BEGIN_DELETE_DATA',
  END_DELETE_DATA = '[PRODUCT ITEM] END_DELETE_DATA',
  BEGIN_SELLING_DATA = '[PRODUCT ITEM] BEGIN_SELLING_DATA',
  END_SELLING_DATA = '[PRODUCT ITEM] END_SELLING_DATA',
  SHOW_APPROVE_POPUP = '[PRODUCT ITEM] SHOW_APPROVE_POPUP',
  HIDE_APPROVE_POPUP = '[PRODUCT ITEM] HIDE_APPROVE_POPUP',
  SHOW_REJECT_POPUP = '[PRODUCT ITEM] SHOW_REJECT_POPUP',
  HIDE_REJECT_POPUP = '[PRODUCT ITEM] HIDE_REJECT_POPUP',
  SHOW_SELLING_POPUP = '[PRODUCT ITEM] SHOW_SELLING_POPUP',
  HIDE_SELLING_POPUP = '[PRODUCT ITEM] HIDE_SELLING_POPUP',
  SHOW_APPROVE_SECTION_POPUP = '[PRODUCT ITEM] SHOW_APPROVE_SECTION_POPUP',
  HIDE_APPROVE_SECTION_POPUP = '[PRODUCT ITEM] HIDE_APPROVE_SECTION_POPUP',
  SHOW_DISAPPROVE_SECTION_POPUP = '[PRODUCT ITEM] SHOW_DISAPPROVE_SECTION_POPUP',
  HIDE_DISAPPROVE_SECTION_POPUP = '[PRODUCT ITEM] HIDE_DISAPPROVE_SECTION_POPUP',
  SHOW_GO_LIVE_POPUP = '[PRODUCT ITEM] SHOW_GO_LIVE_POPUP',
  HIDE_GO_LIVE_POPUP = '[PRODUCT ITEM] HIDE_GO_LIVE_POPUP',
  SHOW_PENDING_POPUP = '[PRODUCT ITEM] SHOW_PENDING_POPUP',
  HIDE_PENDING_POPUP = '[PRODUCT ITEM] HIDE_PENDING_POPUP',
  BEGIN_PENDING_DATA = '[PRODUCT ITEM] BEGIN_PENDING_DATA',
  END_PENDING_DATA = '[PRODUCT ITEM] END_PENDING_DATA',
  UPDATE_STATUS = '[PRODUCT ITEM] UPDATE_STATUS',
  UPDATE_SECTION_STATUS = '[PRODUCT ITEM] UPDATE_SECTION_STATUS',
  SET_STATUS = '[PRODUCT ITEM] SET_STATUS',
  CLOSE_UPDATE_POPUP = '[PRODUCT ITEM] CLOSE_UPDATE_POPUP',
}

export interface CloseUpdatePopupAction {
  type: typeof Types.CLOSE_UPDATE_POPUP;
}

export interface ShowApprovePopupAction {
  type: typeof Types.SHOW_APPROVE_POPUP;
}

export interface HideApprovePopupAction {
  type: typeof Types.HIDE_APPROVE_POPUP;
}

export interface ShowRejectPopupAction {
  type: typeof Types.SHOW_REJECT_POPUP;
}

export interface HideRejectPopupAction {
  type: typeof Types.HIDE_REJECT_POPUP;
}

export interface ShowDeletePopupAction {
  type: typeof Types.SHOW_DELETE_POPUP;
  payload: { data?: IProductModel };
}

export interface HideDeletePopupAction {
  type: typeof Types.HIDE_DELETE_POPUP;
}

export interface BeginDeleteDataAction {
  type: typeof Types.BEGIN_DELETE_DATA;
  payload: { fnCallback?: any; productId: string };
}

export interface EndDeleteDataAction {
  type: typeof Types.END_DELETE_DATA;
}

export interface ShowSellingPopupAction {
  type: typeof Types.SHOW_SELLING_POPUP;
  payload: { data?: IProductModel };
}

export interface HideSellingPopupAction {
  type: typeof Types.HIDE_SELLING_POPUP;
}

export interface ShowApproveSectionPopupAction {
  type: typeof Types.SHOW_APPROVE_SECTION_POPUP;
  payload: { data?: any };
}

export interface HideApproveSectionPopupAction {
  type: typeof Types.HIDE_APPROVE_SECTION_POPUP;
}

export interface ShowDisapproveSectionPopupAction {
  type: typeof Types.SHOW_DISAPPROVE_SECTION_POPUP;
  payload: { data?: any };
}

export interface HideDisapproveSectionPopupAction {
  type: typeof Types.HIDE_DISAPPROVE_SECTION_POPUP;
}

export interface ShowGoLivePopupAction {
  type: typeof Types.SHOW_GO_LIVE_POPUP;
}

export interface HideGoLivePopupAction {
  type: typeof Types.HIDE_GO_LIVE_POPUP;
}

export interface BeginSellingDataAction {
  type: typeof Types.BEGIN_SELLING_DATA;
  payload: { fnCallback?: any; productId: string };
}

export interface EndSellingDataAction {
  type: typeof Types.END_SELLING_DATA;
}

export interface ShowPendingPopupAction {
  type: typeof Types.SHOW_PENDING_POPUP;
  payload: { data?: IProductModel };
}

export interface HidePendingPopupAction {
  type: typeof Types.HIDE_PENDING_POPUP;
}

export interface BeginPendingDataAction {
  type: typeof Types.BEGIN_PENDING_DATA;
  payload: { fnCallback?: any; productId: string };
}

export interface EndPendingDataAction {
  type: typeof Types.END_PENDING_DATA;
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: {
    productId: string;
    offerId?: string;
  };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: any; offer: any };
}

export interface UpdateDataAction {
  type: typeof Types.UPDATE_DATA;
  payload: { data: any };
}

export interface UpdateStatusAction {
  type: typeof Types.UPDATE_STATUS;
  payload: { status: number; reason?: string };
}

export interface UpdateSectionStatusAction {
  type: typeof Types.UPDATE_SECTION_STATUS;
  payload: { status: string };
}

export interface SetStatusAction {
  type: typeof Types.SET_STATUS;
  payload: { status: number; reason?: string };
}

export type Actions =
  | CloseUpdatePopupAction
  | UpdateStatusAction
  | UpdateSectionStatusAction
  | SetDataAction
  | UpdateDataAction
  | LoadDataAction
  | ShowRejectPopupAction
  | HideRejectPopupAction
  | ShowApprovePopupAction
  | HideApprovePopupAction
  | ShowDeletePopupAction
  | HideDeletePopupAction
  | EndDeleteDataAction
  | BeginDeleteDataAction
  | ShowSellingPopupAction
  | HideSellingPopupAction
  | ShowApproveSectionPopupAction
  | HideApproveSectionPopupAction
  | ShowDisapproveSectionPopupAction
  | HideDisapproveSectionPopupAction
  | ShowGoLivePopupAction
  | HideGoLivePopupAction
  | EndSellingDataAction
  | BeginSellingDataAction
  | ShowPendingPopupAction
  | HidePendingPopupAction
  | EndPendingDataAction
  | BeginPendingDataAction
  | SetStatusAction;
