import { IList } from 'constants/common';

import { IProductModel } from 'store/modules/product-item/product-item.constant';
import { IFeedbackModel } from '../feedback-create/feedback-create.constant';

export interface IFeedbackListState extends IList {
  params: {
    page: number;
    limit: number;
    search: string;
    status: any;
    isOwner: boolean;
  };
  deleting: boolean;
  showDeletePopup: boolean;
  selectedData: null;
}

export enum Types {
  CHANGE_PARAMS = '[FEEDBACK LIST] CHANGE PARAMS',
  SET_DATA = '[FEEDBACK LIST] SET_DATA',
  LOAD_DATA = '[FEEDBACK LIST] LOAD_DATA',
  SET_LOADING = '[FEEDBACK LIST] SET_LOADING',
  CLEAR_STATE = '[FEEDBACK LIST] CLEAR_STATE',
  SHOW_DELETE_POPUP = '[FEEDBACK LIST] SHOW_DELETE_POPUP',
  HIDE_DELETE_POPUP = '[FEEDBACK LIST] HIDE_DELETE_POPUP',
  BEGIN_DELETE_DATA = '[FEEDBACK LIST] BEGIN_DELETE_DATA',
  END_DELETE_DATA = '[FEEDBACK LIST] END_DELETE_DATA',
}

export interface ChangeParamsAction {
  type: typeof Types.CHANGE_PARAMS;
  payload: {
    params: any;
  };
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: {
    params: any;
  };
}

export interface ClearStateAction {
  type: typeof Types.CLEAR_STATE;
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    loading: boolean;
  };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: IProductModel[]; count: number };
}

export interface ShowDeletePopupAction {
  type: typeof Types.SHOW_DELETE_POPUP;
  payload: { data?: IFeedbackModel };
}

export interface HideDeletePopupAction {
  type: typeof Types.HIDE_DELETE_POPUP;
}

export interface BeginDeleteDataAction {
  type: typeof Types.BEGIN_DELETE_DATA;
  payload: { feedbackId: string };
}

export interface EndDeleteDataAction {
  type: typeof Types.END_DELETE_DATA;
}

export type Actions =
  | ClearStateAction
  | SetDataAction
  | LoadDataAction
  | ChangeParamsAction
  | SetLoadingAction
  | EndDeleteDataAction
  | BeginDeleteDataAction
  | ShowDeletePopupAction
  | HideDeletePopupAction;
