export interface IProductTypeListState {
  count: number;
  data: any[];
  firstLoad: boolean;
  loading: boolean;
  params: {
    page: number;
    limit: number;
    search: string;
    marketingName: any;
    status: any;
    isOwner: boolean;
    sort: any;
  };
}

export enum Types {
  CHANGE_PARAMS = '[PRODUCT TYPE LIST] CHANGE PARAMS',
  SET_DATA = '[PRODUCT TYPE LIST] SET_DATA',
  LOAD_DATA = '[PRODUCT TYPE LIST] LOAD_DATA',
  SET_LOADING = '[PRODUCT TYPE LIST] SET_LOADING',
  CLEAR_STATE = '[PRODUCT TYPE LIST] CLEAR_STATE',
  CLEAR_PARAMS = '[PRODUCT TYPE LIST] CLEAR_PARAMS',
}

export interface ClearStateAction {
  type: typeof Types.CLEAR_STATE;
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    loading: boolean;
  };
}

export interface ChangeParamsAction {
  type: typeof Types.CHANGE_PARAMS;
  payload: {
    params: any;
  };
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: {
    params: any;
  };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: any[]; count: number };
}

export interface ClearParamsAction {
  type: typeof Types.CLEAR_PARAMS;
}

export type Actions =
  | ClearStateAction
  | SetDataAction
  | LoadDataAction
  | ChangeParamsAction
  | ClearParamsAction
  | SetLoadingAction;
