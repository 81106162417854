import { IFeedbackModel } from '../feedback-create/feedback-create.constant';

export interface IFeedbackDetailState {
  data: IFeedbackModel;
  loading: boolean;
  deleting: boolean;
  showDeletePopup: boolean;
}

export enum Types {
  SET_DATA = '[FEEDBACK DETAIL] SET_DATA',
  LOAD_DATA = '[FEEDBACK DETAIL] LOAD_DATA',
  SHOW_DELETE_POPUP = '[FEEDBACK DETAIL] SHOW_DELETE_POPUP',
  HIDE_DELETE_POPUP = '[FEEDBACK DETAIL] HIDE_DELETE_POPUP',
  BEGIN_DELETE_DATA = '[FEEDBACK DETAIL] BEGIN_DELETE_DATA',
  END_DELETE_DATA = '[FEEDBACK DETAIL] END_DELETE_DATA',
  SET_LOADING = '[FEEDBACK DETAIL] SET_LOADING',
}

export interface ShowDeletePopupAction {
  type: typeof Types.SHOW_DELETE_POPUP;
  payload: { data?: IFeedbackModel };
}

export interface HideDeletePopupAction {
  type: typeof Types.HIDE_DELETE_POPUP;
}

export interface BeginDeleteDataAction {
  type: typeof Types.BEGIN_DELETE_DATA;
  payload: { feedbackId: string };
}

export interface EndDeleteDataAction {
  type: typeof Types.END_DELETE_DATA;
}

export interface SetLoadingAction {
  type: typeof Types.SET_LOADING;
  payload: {
    loading: boolean;
  };
}

export interface BeginDeleteDataAction {
  type: typeof Types.BEGIN_DELETE_DATA;
  payload: { feedbackId: string };
}

export interface EndDeleteDataAction {
  type: typeof Types.END_DELETE_DATA;
}

export interface LoadDataAction {
  type: typeof Types.LOAD_DATA;
  payload: {
    feedbackId: string;
  };
}

export interface SetDataAction {
  type: typeof Types.SET_DATA;
  payload: { data: any };
}

export type Actions =
  | SetDataAction
  | LoadDataAction
  | EndDeleteDataAction
  | BeginDeleteDataAction
  | ShowDeletePopupAction
  | HideDeletePopupAction;
