import { createSelector } from 'reselect';
import { IState } from 'store/constants';
import { IProductFileState } from './product-file.constant';

export const productFileSelector = (state: IState) => {
  return state.productFile;
};

export const affiliateSelector = createSelector(
  productFileSelector,
  (productFile: IProductFileState): any => ({
    affiliate: productFile.affiliate,
  })
);

export const loadingSelector = createSelector(
  productFileSelector,
  (productFile: IProductFileState): any => ({
    loading: productFile.loading,
  })
);

export const pathSelector = createSelector(
  productFileSelector,
  (productFile: IProductFileState): any => ({
    path: productFile.path,
  })
);

export const dataSelector = createSelector(
  productFileSelector,
  (productFile: IProductFileState): any => ({
    data: productFile.data,
  })
);

export const popupSelector = createSelector(
  productFileSelector,
  (productFile: IProductFileState): any => ({
    popup: productFile.popup,
  })
);

export const selectedFilesSelector = createSelector(
  productFileSelector,
  (productFile: IProductFileState): any => ({
    selectedFiles: productFile.selectedFiles,
  })
);

export const fileExtraMapSelector = createSelector(
  productFileSelector,
  (productFile: IProductFileState): any => ({
    fileExtraMap: productFile.fileExtraMap,
  })
);
